import { sfsService } from './../../services/sfs.service';
import { Component, OnInit, Injector, Input, ViewChild, TemplateRef, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, AfterContentInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService, UserService, ApiResponse } from 'sfscommon';
import { AppFormBasePage, contextShowing, onShowingArgs } from '../../common/app-form-base/app-form-base.page';
import { BackToListSettings } from '../../models/common/page.model';
import { resolve } from 'path';
import { GenericComponentComponent } from '../../generic/generic-component/generic-component.component';
import { ActionModel } from '../app-list-base/app-list-base.page';
import { DynamicItem } from '../../components/common/dynamic-component-directive';
import { MyAccountComponent } from 'src/app/custom/components/my-account/my-account.component';
import { Plugins } from '@capacitor/core';
import { App } from '@capacitor/app';
const { Geolocation } = Plugins;
@Component({
  selector: 'app-generic-form-base',
  templateUrl: '../../generic/generic-form/generic-form.page.html',
  styleUrls: ['../../generic/generic-form/generic-form.page.scss']
})
export abstract class GenericFormBasePage extends AppFormBasePage implements OnInit {
  // @Input() entityName: string;
  canGoBack: boolean = false;
  @Input() filterProperties: Array<string>;
  @Input() item: any = null;
  public isEmbedded:boolean=false;
  @Input() extraData:any;
  @Input() formModeForced: boolean = false;
  @Input() fkValue: string = null;
  @Input() guidItem: string = null;
  fields: Array<FormlyFieldConfig> = [];
  @Input() formMode: string = "read";
  childrenRelations: Array<any> = []; // relaciones muchos
  CustomFormFile: any = null;
  public externalCustomFileChecked:boolean = false;
  customClass = 'KstEmailTemplate-form.custom';
  @Input() entityName: string;
  entityModel: any = null;
  textSave: string = "Guardar";
  showButtons:boolean=true;
  startContent:string;
  endComponent:any;
  startComponent:any;
  endContent:string;
  actions:Array<ActionModel>=[];
  canEdit:boolean=true;
  textReady:boolean=false;
  disableShowForm:boolean=false;
  public async  getCurrentPosition() : Promise<any>{
    let result = await  Geolocation.getCurrentPosition();
    
    return result.coords;
   }
  constructor(
    public injector: Injector,
    public activatedRoute: ActivatedRoute,
    public storage: StorageService,
    //public userService: UserService,
    public sfsService: sfsService

  ) {

    super(injector);


    if (this.activatedRoute != null && this.activatedRoute.snapshot.paramMap.get('catalog') != null) {
      this.entityName = this.activatedRoute.snapshot.paramMap.get('catalog');
    }

    console.log("constructor GenericFormBasePage");

    if (this.route != null &&  this.route.snapshot.paramMap.get("id") != null) {
      this.guidItem = this.route.snapshot.paramMap.get("id");
    }

    this.actions.push({ Text: "Guardar", ActionKey:"save" });
    this.actions.push({ Icon: "trash", Text: "Eliminar", ActionKey:"delete" });

    //this.actions.push({ Icon: "eye", Text:"Detalles", ActionKey: "edit"});
   

  }
  setAction(actionKey, enabled){
    
    this.actions = this.actions.filter(p=> p.ActionKey != actionKey);
    if (actionKey == "edit"){
      this.canEdit = enabled;
    }
  }
  actionsForSheet(action:ActionModel, item:any): any {
    let role = action.ActionKey;

    if (action.ActionKey == "delete") {
      role = "destructive";

    }
    let _action: any = {
      text: action.Text,
      role: role,

    };
    // if (item.ActionKey == "delete") {
    //   action.handler = () => {
    //     this.delete(null);
    //   }
    // } else {
      _action.handler = async () => {
        let result =  await this.showConfirm(
          {  text: `Confirme que desea ejecutar la acción ${_action.text}`,
             onOk: async ()=>{
             
               this.action(item, _action.role);
             }
           });
        
      }
    // }

    return _action;
  }
  action(item: any, actionKey: string) {
    //console.log("action", row, actionKey);

      if (this.CustomFormFile != null && this.CustomFormFile["OnCustomActionExecute"] != null) {
        this.CustomFormFile["OnCustomActionExecute"](this, item, actionKey);
      }
      if (this.CustomFormFile != null && this.CustomFormFile["OnCustomActionExecuteAsync"] != null) {
        this.CustomFormFile["OnCustomActionExecuteAsync"](this, item, actionKey);
      }
 
      
  }
  public async openMassiveActions(item?: any) {
    let actionsList = [];
   
    this.actions.forEach(action=> {
      if (action.ActionKey != "delete" && action.ActionKey != "save"){
        actionsList.push(this.actionsForSheet(action, item));

      }
    });
    let actions = await this.actionSheetCtrl.create(
      {
        header: 'Selecciona la acción',
        buttons: actionsList
      }

    );
    actions.onDidDismiss().then((data) => {
      console.log("as", data);
    });
    return await actions.present();

  }
  get canDelete(){
    //  console.log("canAdd");
      //this.canAddChecked = true;
      return this.actions.find(p=>  p.ActionKey == "delete") != null;
    }
    get existsCustomAction(){
      
        return this.actions.filter(p=>  p.ActionKey != "delete" && p.ActionKey != "save").length > 0;
    }
  private _componentFactoryResolver: ComponentFactoryResolver;

  public get componentFactoryResolver(): ComponentFactoryResolver {
    if (!this._componentFactoryResolver) {

      this._componentFactoryResolver = this.injector.get(ComponentFactoryResolver);
    }

    return this._componentFactoryResolver;
  }
  
  
//  async ngAfterContentInit() {
//     if (this.CustomFormFile != null ){
//       await (<Promise<void>>(this.CustomFormFile["ngAfterContentInitAsync"](this, {})));
//     }
//     //throw new Error('Method not implemented.');
//   }
  public fillType:string = "clear";
  async  switchFilterRange(event: any) {
    
    console.log(event);

   // this.isFilterRange = event.detail.checked;
    if (this.isFilterRange == true){
      this.isFilterRange = false;
    }else{
      this.isFilterRange = true;
    }
   
    if (this.isFilterRange == true){
      this.fillType = "outline";
    }else{
      this.fillType = "clear";
    }

    this.pageService.isFilterRange = this.isFilterRange;
    this.pageService.fieldsBack = this.entityModel.GetFields();
    this.fields = [];
    this.pageService.temp = null;
   // this.showForm();

    //  }, 500);

     // import(
      /* webpackMode: "eager" */
      /* webpackPrefetch: true */
      /* webpackInclude: /\.ts$/ */
      /* webpackPreload: true */

      // `../../../pages/catalogs/${this.entityName}Form.custom`).then(async (_import) => {
      //   this.CustomFormFile = _import[this.entityName + "FormCustom"];
        if (this.CustomFormFile != null) {
          // if (this.item == null){
          //   this.item = new this.entityModel();
          // }
          let args: onShowingArgs = new onShowingArgs();
          if (this.isListDetails == true) {
            args.context = contextShowing.OnListDetails;
          }
          if (this.CustomFormFile["OnShowing"] != null) {

            this.CustomFormFile["OnShowing"](this, args);
          }
          if (this.CustomFormFile["OnShowingAsync"] != null) {
            await (<Promise<void>>(this.CustomFormFile["OnShowingAsync"](this, args)));
          }
        }
        if (this.disableShowForm == false){

           this.showForm();
        }
        if (this.CustomFormFile["OnShowed"] != null) {

          this.CustomFormFile["OnShowed"](this, null);
        }
      
        if (this.getDataExecuted == false){
          this.getData();
        }
      // }).catch((error) => {
      //   console.log("error load partial File", error);
      //   this.showForm();

      //   this.getData();
      // });


    
  }
  async TestX(){
    console.log("TestX", this.entityName);
  }
  public getItemsActions(row:any, contextType?:any ):Array<any>{
    let itemActions = this.getDefaultItemActions(null, null);
    return itemActions;
  }
  public getDefaultItemActions(row:any, contextType?:any ){
    if (this.localActions == null ){
      this.localActions = [];
      this.actions.forEach(item=> {
        if (item.ActionKey != "add"){
          this.localActions.push(item);
        }
      });
    }
    
    return this.localActions;
  }
  addItemActions(actions:Array<ActionModel>, item:any){
    if (this.localActions == null ){
      this.localActions = this.getDefaultItemActions(item, null);
    }
    item["__actions"] = this.localActions.concat(actions);
    
  }
  @ViewChild('startComponentx',{ read: ViewContainerRef, static: true }) startComponentx:GenericComponentComponent;
  
  async ngOnInit() {
    
    this.canGoBack = true;
    this.pageService.isFilter = this.isFilter;
    if (this.entityName == null) {
      let data = await await new Promise<any>(resolve => this.route.data.subscribe((data: any) => { resolve(data) }));
      if (data != null) {
        this.entityName = data.catalog;
        if (data.formMode != null){
          this.formModeForced = true;
          this.formMode = data.formMode;
        }
      }
    }
  
    this.defaultHref = 'catalog/' + this.entityName;
    console.log("---entityName----", this.entityName);

    if (this.isFilter == true) {
      // this.isX = true;
      this.title = "Filtro";
      this.textSave = "Aplicar";
      if (this.item != null) {
        let props = Object.getOwnPropertyNames(this.item);
        if (props.find(p => p.startsWith("__")) != null) {
          this.isFilterRange = true;
          this.pageService.isFilterRange = this.isFilterRange;
        }
      }
    }
    await import(
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackInclude: /\.ts$/ */
      /* webpackPreload: true */
      `../../models/codegen/${this.entityName}.model`).then(async(_model) => {
        this.entityModel = _model[this.entityName + "Model"];
        this.pageService.fieldsBack = this.entityModel.GetFields();

        //TODO
        //try{
        if (this.isModal == false && this.isEmbedded == false) { // No es modal
          this.sfsService.SetNavigationData(true, "first-principal-form");
          if (this.isListDetails == false) {
            this.sfsService.SetNavigationData({ children: this.entityModel.GetChildren() }, "relations");
          }
        }
        //}catch(ex){
        //  console.log("ex", ex);
        //}
        this.pageService.temp = null;
        if (this.isFilter == true && this.item != null) {
          console.log("data open filter", this.item);
        } else {
          //console.log("new item", this.item);
          //if (this.isFilter == true ){
          if (this.guidItem == null) {
            if (this.formModeForced == false){
              this.formMode = "edit";
            }
            if (this.item == null )
              this.item = new this.entityModel();
            console.log("fk", this.fk, this.fkValue);
            this.item[this.fk] = this.fkValue;
            console.log("fields", this.fields);

          }
          //}
        }
        await import(
          /* webpackMode: "lazy" */
          /* webpackPrefetch: true */
          /* webpackInclude: /\.ts$/ */
          /* webpackPreload: true */
          `../../../pages/catalogs/${this.entityName}Form.custom`).then(async (_import) => {
            this.externalCustomFileChecked = true;
            this.CustomFormFile = _import[this.entityName + "FormCustom"];
            if (this.CustomFormFile != null) {
            
              // if (this.item == null){
              //   this.item = new this.entityModel();
              // }
              let args: onShowingArgs = new onShowingArgs();
              if (this.isListDetails == true) {
                args.context = contextShowing.OnListDetails;
              }
              if (this.CustomFormFile["OnShowing"] != null) {

                this.CustomFormFile["OnShowing"](this, args);
              }
              if (this.CustomFormFile["OnShowingAsync"] != null) {
                await (<Promise<void>>(this.CustomFormFile["OnShowingAsync"](this, args)));
              }
            }
            if (this.disableShowForm == false){
              this.showForm();
            }

            if (this.CustomFormFile != null && this.CustomFormFile["OnShowed"] != null) {

              this.CustomFormFile["OnShowed"](this, null);
            }
          
            if (this.getDataExecuted == false){
              await this.getData();
            }

            console.log("external file loaded");
            
           
          //  this.startComponentx.loadComponent();

          }).catch(async (error) => {
            this.title = this.entityName;
            this.externalCustomFileChecked = true;
            console.log("error load partial File", error);
            if (this.CustomFormFile == null ){
              this.showForm();              
            }

            if (this.getDataExecuted == false){
             await  this.getData();
            }
          });

      }).catch((error) => {
       
         this.externalCustomFileChecked = true;
        console.log("error ", error);
      });
  }

  getDataExecuted:boolean=false;
  async getData() {
    console.log("get data " + this.entityName);
    if (this.guidItem != null) {
      let result = await this.bizAppService.GetItem(this.guidItem, this.entityModel._EntitySetName, Object.getOwnPropertyNames(this.entityModel.PropertyNames).filter(p => !p.startsWith("Fk")).join(","));
      if (result.status == "success") {
        this.item = result.data;
        this.events.publish('item:updated', { itemUpdated: result.data, defaultProperty: this.entityModel._DefaultProperty });
        if (this.item != null) {
          this.guidItem = this.item.Id;
        }
      }
    }
    this.getDataExecuted = true;
  }
  async goBack(settings?: BackToListSettings) {
    if (this.isFilter == true) {
      this.close();

    } else {
      if (settings == null) {
        settings = new BackToListSettings();
        settings.RefreshData = false;
        settings.RestartPaging = false;
        settings.Route = this.defaultHref;
      }
      this.sfsService.SetNavigationData(settings);
      if (this.isModal == true) {
        this.modalCtrl.dismiss(settings);
      } else {
        this.navCtrl.navigateBack(settings.Route, { animated: true });
      }
    }
  }
  async close() {
    this.modalCtrl.dismiss();
  }
  async delete() {
    if (this.isFilter == true) {
      this.modalCtrl.dismiss({ delete: true });
    } else {

      let modalResponse = await this.showDeleteConfirm({

        onOk: async () => {
          let response = await this.bizAppService.Delete([this.guidItem], this.entityModel._EntitySetName);

        }
      });

    }
  }

  getPropertiesForUpdate(){
    let result = this.visibleFields;
    if (this.excludeFieldsForSave.length > 0){
     for (let index = 0; index < this.excludeFieldsForSave.length; index++) {
      result = result.filter(p=> p != this.excludeFieldsForSave[index]);
     }
    }
    return result;
  }
  @Input() usemode:string;
  async updateOrCreate(args?:any){
    this.savingStart();
      if (this.form.valid == true) {

        // this.item = this.form.value;
        if (this.item == null) {
          this.item = new this.entityModel();
        }
        Object.assign(this.item, this.form.value);
        //this.item.Id = this.guidItem;

        let apiResponse: ApiResponse<any> = null;
        console.log("after create or save", this.guidItem);
        if (this.guidItem != null) {
          apiResponse = await this.bizAppService.Update(this.item, this.entityModel._EntitySetName, this.getPropertiesForUpdate().join(","), this.usemode);
        } else {
          if (args?.returnKey == true ){
            apiResponse = await this.bizAppService.CreateAndReturnKey(this.item, this.entityModel._EntitySetName, this.usemode);

          }else{
            apiResponse = await this.bizAppService.Create(this.item, this.entityModel._EntitySetName, this.usemode);

          }
        }
        this.savingEnd();
        if (apiResponse.status == 'success') {
          if (args?.returnKey == true ){
            args.returnedKey = apiResponse.data;
          }
          // let result = await this.showOk();
          let prevent = false;
          if (args?.preventBack == true){
            prevent =  true;
          }
          // if (result == true) {
            if (prevent == false){
          if (this.guidItem == null) {
           
              this.goBack({ RestartPaging: true, RefreshData: true, Route: this.defaultHref });
            
          } else {
            if (this.CustomFormFile != null && this.CustomFormFile["OnSavedAsync"] != null){
              await this.CustomFormFile["OnSavedAsync"](this, {});
            }
            this.goBack({ RestartPaging: false, RefreshData: false, ItemUpdated: this.item, Route: this.defaultHref });
          }
        }
          // }
        }else{

           await  this.showToastMessage("Ocurrió un error, intente más tarde");
        }
      }
  }
  async saveData() {

    if (this.isFilter) {
      if (this.item == null) {
        this.item = new this.entityModel();
      }
      Object.assign(this.item, this.form.value);
      await this.modalCtrl.dismiss({ query: this.pageService.getQueryFilter(this.item, this.entityModel.GetFields(), this.isFilterRange), itemFilter: this.item });

    } else {
      await  this.updateOrCreate();
      
    }
  }
}
