<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      Seleccionar empresa
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content no-padding>

  <ion-list>
    <ion-radio-group [(ngModel)]="idCompanySelected">
      <ion-item *ngFor="let company of companies">
        <ion-label>{{ company.Name }}</ion-label>
        <ion-radio value="{{ company.IdCompany }}"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button (click)="selectCompany()">
        Seleccionar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>