import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbProxyRoleModel } from './hbProxyRole.model';
import { hbFileModel } from './hbFile.model';
	



 class hbProxyUserPropertyNames{
	constructor(){}
	//axd
	GuidUser:string = "GuidUser";	

				 
				 
	Username:string = "Username";	

				 
				 
	FirstName:string = "FirstName";	

				 
				 
	LastName:string = "LastName";	

				 
				 
	Email:string = "Email";	

				 
				 
	DisplayName:string = "DisplayName";	

				 
				 
	GuidRole:string = "GuidRole";	

				 
				 
	Password:string = "Password";	

				 
				 
	FilesIDs:string = "FilesIDs";	

				 
				 
	UrlPhoto:string = "UrlPhoto";	

				 
				 
	UrlPhotoThumb50:string = "UrlPhotoThumb50";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
	UrlPhotoThumb100:string = "UrlPhotoThumb100";	

				 
				 
	UrlPhotoThumb200:string = "UrlPhotoThumb200";	

				 
				 
	GuidFile:string = "GuidFile";	

				 
				 
	Completed:string = "Completed";	

				 
				 
		FkhbProxyRole:string = "FkhbProxyRole";
		hbProxyRole:string = "hbProxyRole"; //test
				 
		FkhbFile:string = "FkhbFile";
		hbFile:string = "hbFile"; //test
				 
	EmailRepeated:string = "EmailRepeated";	

				 
				 
	FullName:string = "FullName";	

				 
				 
	PasswordRepeated:string = "PasswordRepeated";	

				 
				 
	UrlFile:string = "UrlFile";	

				 
				 
	UrlThumbFile:string = "UrlThumbFile";	

				 
				 
}

//test
export  class hbProxyUserModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbExperts",
            FkPropertyName: "GuidUser",
            Label: "hbExperts",
            EntityName: "hbExpert"
        });
        results.push({
            Name: "hbProxyUserFile",
            FkPropertyName: "GuidUser",
            Label: "hbProxyUserFile",
            EntityName: "hbProxyUserFile"
        });
        results.push({
            Name: "hbComment",
            FkPropertyName: "CreatedBy",
            Label: "hbComment",
            EntityName: "hbComment"
        });
        results.push({
            Name: "hbExpertCategory",
            FkPropertyName: "GuidUser",
            Label: "hbExpertCategory",
            EntityName: "hbExpertCategory"
        });
        results.push({
            Name: "hbProject",
            FkPropertyName: "CreatedBy",
            Label: "hbProject",
            EntityName: "hbProject"
        });
        results.push({
            Name: "hbProposal",
            FkPropertyName: "CreatedBy",
            Label: "hbProposal",
            EntityName: "hbProposal"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProxyUserModel.PropertyNames.Username,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbProxyUserModel.PropertyNames.Username,
        placeholder: '',
        required: true,
		
		maxLength: 100,
    }
},
{
    key: hbProxyUserModel.PropertyNames.FirstName,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.FirstName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.LastName,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.LastName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.Email,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.Email,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.DisplayName,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.DisplayName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.GuidRole,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbProxyRole",
			EntityModel: hbProxyRoleModel,  
			DataValue: hbProxyRoleModel.PropertyNames.GuidRole,
            DataText: hbProxyRoleModel.PropertyNames.RoleName 
			},

        label: hbProxyUserModel.PropertyNames.GuidRole,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserModel.PropertyNames.Password,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.Password,
        placeholder: '',
        required: false,
		
		maxLength: 300,
    }
},
{
    key: hbProxyUserModel.PropertyNames.FilesIDs,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.FilesIDs,
        placeholder: '',
        required: false,
		
		maxLength: 1000,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlPhoto,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlPhoto,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlPhotoThumb50,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlPhotoThumb50,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlPhotoThumb100,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlPhotoThumb100,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlPhotoThumb200,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlPhotoThumb200,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: hbProxyUserModel.PropertyNames.GuidFile,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbFile",
			EntityModel: hbFileModel,  
			DataValue: hbFileModel.PropertyNames.GuidFile,
            DataText: hbFileModel.PropertyNames.FileName 
			},

        label: hbProxyUserModel.PropertyNames.GuidFile,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserModel.PropertyNames.Completed,
    type: 'checkbox',

    templateOptions: {
	 
	indeterminate: false,

	


        label: hbProxyUserModel.PropertyNames.Completed,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserModel.PropertyNames.EmailRepeated,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.EmailRepeated,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.FullName,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserModel.PropertyNames.FullName,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserModel.PropertyNames.PasswordRepeated,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.PasswordRepeated,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserModel.PropertyNames.UrlThumbFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserModel.PropertyNames.UrlThumbFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
	];
    }
	Id?:string;

		GuidUser:string;	

		 
	Username:string;	

		 
	FirstName?:string;	

		 
	LastName?:string;	

		 
	Email?:string;	

		 
	DisplayName?:string;	

		 
	GuidRole?:string;	

		 
	Password?:string;	

		 
	FilesIDs?:string;	

		 
	UrlPhoto?:string;	

		 
	UrlPhotoThumb50?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 
	UrlPhotoThumb100?:string;	

		 
	UrlPhotoThumb200?:string;	

		 
	GuidFile?:string;	

		 
	Completed?:boolean = false;	

		 
		FkhbProxyRole:string ;

		FkhbProxyRoleText:any;

		hbProxyRole:string;
		
			 
		 
		FkhbFile:string ;

		FkhbFileText:any;

		hbFile:string;
		
			 
		 
	EmailRepeated?:string;	

		 
	FullName?:string;	

		 
	PasswordRepeated?:string;	

		 
	UrlFile?:string;	

		 
	UrlThumbFile?:string;	

		 

	public static _EntitySetName:string =  "hbProxyUserSet";
	public static _EntityName:string =  "hbProxyUser";
	public static PropertyNames: hbProxyUserPropertyNames = new hbProxyUserPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidUser,Username,FirstName,LastName,Email,DisplayName,GuidRole,Password,FilesIDs,UrlPhoto,UrlPhotoThumb50,CreatedDate,UpdatedDate,UrlPhotoThumb100,UrlPhotoThumb200,GuidFile,Completed,EmailRepeated,FullName,PasswordRepeated,UrlFile,UrlThumbFile";

	public static _DefaultProperty: string= "Username";

	public get getSetName() : string {
		return hbProxyUserModel._EntitySetName;
	}
	public get getPropertyNames() : hbProxyUserPropertyNames {
		return hbProxyUserModel.PropertyNames;
	}
}


	
