// Angular Imports
import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// SFSCommon Imports
import { BasePage } from '../../core/base-page';
import { ApiResponse } from '../../core/http/models/ApiResponse';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { EVENT_USER_LOGGED_IN } from '../../configs/system.variables';

@Component({
  selector: 'sfs-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePage {

  //
  public urlLogo: string;
  public allowSocialLogin: boolean;

  public submitButtonText: string = 'Iniciar sesión';
  public submitButtonDisabled: boolean = false;

  public dataRoute$: any;

  constructor(public injector: Injector, public authService: AuthenticationService) {

    super(injector);
  }

  public loginResponse = (response) => {

    // Existe respuesta.
    if (response) {

      // La respuesta fue exitosa.
      if (response.status == 'success') {

        this.userLoged(response.data);

      } else {

        // Error en la petición.
        this.handleLoginError(response.reason);
      }
    }
  }

  public userLoged = (userData) => {
    this.events.publish(EVENT_USER_LOGGED_IN, { userData: userData, url: '' });
  }

  public handleLoginError  = async (error) => {
    this.showAlertMessage(error);
  }

  public httpErrorResponse = async (error) => {
    this.showAlertMessage('No fue posible establecer la conexión');
  }

  public async handelLoginToSFS(formValue) {

    try {

      this.submitButtonText = 'Iniciando sesión...';
      this.submitButtonDisabled = true;

      // Se hace la peticion al servidor.
      const response = <ApiResponse>await this.authService.loginToSFSApp(formValue);

      this.loginResponse(response);

    } catch (error) { this.httpErrorResponse(error); console.error('handelLoginToSFS', error); }
    finally {
      this.submitButtonText = 'Iniciar sesión';
      this.submitButtonDisabled = false;
    }
  }

  // public async handleLoginGoogle(event) {

  //   try {

  //     const response = await this.authService.loginToGoogle();

  //     this.loginResponse(response);

  //   } catch (error) { this.showAlertMessage('No fue posible establecer la conexión'); console.error('handleLoginGoogle', error); }
  // }

  // public async handleLoginFacebook(event) {

  //   try {

  //     const response = await this.authService.loginToFacebook();

  //     this.loginResponse(response);

  //   } catch (error) { this.showAlertMessage('No fue posible establecer la conexión'); console.error('handleLoginGoogle', error); }
  // }
}
