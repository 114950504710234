export class ServiceData {
  UIEntity?:string;
  AppNameKey?: string;
  RestServiceAppKey?: string;
  ExtraData?: string;
  Query?: string;
  ReturnType?: string;
  Page?: number;
  PageSize?: number;
  SortBy?: string;
  SortDirection?: string;
  EntitySet?: string;
  Fields?: string;
  ExtraParams?: string;
  Params?:any;
  ExtraUrlQuery?: string;
  ItemKey?:string;
  ItemKeys?:Array<any>;
  Usemode?: string;
  FreeText?: string;
  Item?: any;
  Items?: Array<any> = [];
  MethodName?: string;
  ContentText?: string;
  OverrideAppNameKey?:string;
  ServerVersion?:number;
  ContentObject?: any;
  AllFields?: boolean = false;
}
