import { NgModule, ModuleWithProviders, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { SFSCommonModule } from './sfscommon.module';

@NgModule({
  imports: [SFSCommonModule],
  exports: [SFSCommonModule]
})
export class SFSComponentModule {

  static forChild(page: any): ModuleWithProviders<SFSCommonModule> {
    return {
      ngModule: SFSCommonModule,
      providers: [
        { provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: page, multi: true },
      ]
    };
  }
}
