// Angular Imports
import { Injectable } from '@angular/core';

// SFSCommon Imports
import { SystemCore } from '../system.service';

@Injectable()
export class LoggingService {

  constructor(public systemCore: SystemCore) {
  }

  // -------------- LOCAL LOGS --------------  

  public eventLog(message: any, ...optionParams: any[]) {

    if (this.systemCore.isDebug) {

      console.log(message, optionParams);
    }
  }

  public eventLogError(message: any, ...optionParams: any[]) {

    if (this.systemCore.isDebug) {

      console.error(message, optionParams);
    }
  }
}
