// Angular Imports
import { Injectable } from '@angular/core';

// SFSCommon Imports
import { ServiceData } from '../data/models/ServiceData';
import { DataService } from '../data/data.service';
import { uiSettings } from '../../models/IUISetting';

@Injectable()
export class CompanyService {

  constructor(public dataService: DataService) {
  }

  /**
   * Retrieves UISettings from server.
   */
  public async getUISettings(idCompany: string) {

    let uiSetting: uiSettings = null;

    let serviceData: ServiceData = {
      AllFields: true,
      AppNameKey: 'SFSdotNetFrameworkSecurity',
      EntitySet: 'secCompUISettings',
      Fields: 'PrimaryButtonsBG,PrimaryButtonsTextColor,TopMenuBG,AppMenuBG,BGColor,FormBG,TextLinkColor,ListHeaderBG',
      Query: 'it.secCompany.GuidCompany = Guid("' + idCompany + '")'
    };

    const result = await this.dataService.get(serviceData);

    if (result.status == 'success') {

      if (result.data.length > 1) {

        serviceData = {
          AllFields: true,
          AppNameKey: 'SFSdotNetFrameworkSecurity',
          EntitySet: 'secCompanies',
          Fields: 'Name,GuidCompUISetting',
          Query: 'GuidCompany = Guid("' + idCompany + '")',
        };

        const resultCompany = await this.dataService.get(serviceData);

        if (resultCompany.status == 'success' && resultCompany.data && resultCompany.data['length'] >= 1 && resultCompany.data[0]['GuidCompUISetting'] != null) {

          uiSetting = (result.data as Array<any>).find(p => p['GuidCompUISetting'] == resultCompany.data[0]['GuidCompUISetting']);
        }
      } else if (result.data.length == 1) {

        uiSetting = result.data[0];
      }

    } else { throw new Error(result.message); }

    return uiSetting;
  }
}
