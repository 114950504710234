import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { UserService } from 'sfscommon';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

  constructor(public router: Router, public userService: UserService) {}
 
  
 async  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    const response = await Promise.all([this.userService.getUserData(), this.userService.getUserToken()]);

    if (response[0] && response[1]) {
      const isCiudadano = this.userService.isInRole("ciudadano");
      if (isCiudadano){
        // es ciudadano
        console.log("AuthGuardService", this.router);
      }else{
        // es autentificado pero no es ciudadano
      
      }
      
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          
         // return: state.url
        }
      });
      return false;
    }
  }
}