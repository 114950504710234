import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class hbFilePropertyNames{
	constructor(){}
	//axd
	GuidFile:string = "GuidFile";	

				 
				 
	FileName:string = "FileName";	

				 
				 
	FileType:string = "FileType";	

				 
				 
	FileData:string = "FileData";	

				 
				 
	FileStorage:string = "FileStorage";	

				 
				 
	ContainerStorage:string = "ContainerStorage";	

				 
				 
	ThumbSizes:string = "ThumbSizes";	

				 
				 
	FileSize:string = "FileSize";	

				 
				 
	GuidCompany:string = "GuidCompany";	

				 
				 
	CreatedBy:string = "CreatedBy";	

				 
				 
	UpdatedBy:string = "UpdatedBy";	

				 
				 
	IsDeleted:string = "IsDeleted";	

				 
				 
	BizKeyEngine:string = "BizKeyEngine";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
	UrlFile:string = "UrlFile";	

				 
				 
	UrlThumbFile:string = "UrlThumbFile";	

				 
				 
	FileDataProxy:string = "FileDataProxy";	

				 
				 
	BlobSAS:string = "BlobSAS";	

				 
				 
	BlobSharedKey:string = "BlobSharedKey";	

				 
				 
	BlobDate:string = "BlobDate";	

				 
				 
}

//test
export  class hbFileModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbProjectFiles",
            FkPropertyName: "GuidFile",
            Label: "hbProjectFiles",
            EntityName: "hbProjectFile"
        });
        results.push({
            Name: "hbProposalFiles",
            FkPropertyName: "GuidFile",
            Label: "hbProposalFiles",
            EntityName: "hbProposalFile"
        });
        results.push({
            Name: "hbServiceFiles",
            FkPropertyName: "GuidFile",
            Label: "hbServiceFiles",
            EntityName: "hbServiceFile"
        });
        results.push({
            Name: "hbProxyUserFile",
            FkPropertyName: "GuidFile",
            Label: "hbProxyUserFile",
            EntityName: "hbProxyUserFile"
        });
        results.push({
            Name: "hbProject",
            FkPropertyName: "GuidFile",
            Label: "hbProject",
            EntityName: "hbProject"
        });
        results.push({
            Name: "hbProxyUser",
            FkPropertyName: "GuidFile",
            Label: "hbProxyUser",
            EntityName: "hbProxyUser"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbFileModel.PropertyNames.FileName,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbFileModel.PropertyNames.FileName,
        placeholder: '',
        required: false,
		
		maxLength: 200,
    }
},
{
    key: hbFileModel.PropertyNames.FileType,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.FileType,
        placeholder: '',
        required: false,
		
		maxLength: 50,
    }
},
{
    key: hbFileModel.PropertyNames.FileData,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.FileData,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbFileModel.PropertyNames.FileStorage,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.FileStorage,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: hbFileModel.PropertyNames.ContainerStorage,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.ContainerStorage,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbFileModel.PropertyNames.ThumbSizes,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.ThumbSizes,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbFileModel.PropertyNames.FileSize,
    type: 'input',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.FileSize,
        placeholder: '',
        required: false,
		
        type: "number",
    }
},
{
    key: hbFileModel.PropertyNames.UrlFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.UrlFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbFileModel.PropertyNames.UrlThumbFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.UrlThumbFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbFileModel.PropertyNames.FileDataProxy,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbFileModel.PropertyNames.FileDataProxy,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbFileModel.PropertyNames.BlobSAS,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.BlobSAS,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbFileModel.PropertyNames.BlobSharedKey,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.BlobSharedKey,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbFileModel.PropertyNames.BlobDate,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbFileModel.PropertyNames.BlobDate,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
	];
    }
	Id?:string;

		GuidFile:string;	

		 
	FileName?:string;	

		 
	FileType?:string;	

		 
	FileData?:string;	

		 
	FileStorage?:string;	

		 
	ContainerStorage?:string;	

		 
	ThumbSizes?:string;	

		 
	FileSize?:number;	

		 
	GuidCompany?:string;	

		 
	CreatedBy?:string;	

		 
	UpdatedBy?:string;	

		 
	IsDeleted?:boolean = false;	

		 
	BizKeyEngine?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 
	UrlFile?:string;	

		 
	UrlThumbFile?:string;	

		 
	FileDataProxy?:string;	

		 
	BlobSAS?:string;	

		 
	BlobSharedKey?:string;	

		 
	BlobDate?:string;	

		 

	public static _EntitySetName:string =  "hbFileSet";
	public static _EntityName:string =  "hbFile";
	public static PropertyNames: hbFilePropertyNames = new hbFilePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidFile,FileName,FileType,FileData,FileStorage,ContainerStorage,ThumbSizes,FileSize,GuidCompany,CreatedBy,UpdatedBy,IsDeleted,BizKeyEngine,CreatedDate,UpdatedDate,Bytes,UrlFile,UrlThumbFile,FileDataProxy,BlobSAS,BlobSharedKey,BlobDate";

	public static _DefaultProperty: string= "FileName";

	public get getSetName() : string {
		return hbFileModel._EntitySetName;
	}
	public get getPropertyNames() : hbFilePropertyNames {
		return hbFileModel.PropertyNames;
	}
}


	
