// Angular Imports
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Flex Layout Imports
import { FlexLayoutModule } from '@angular/flex-layout';

// Angular Material Imports
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Ionic Imports
import { IonicModule, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

// Ionic Native Imports
//import { Http, HttpPlugin } from '@capacitor-community/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';

// Third Library Imports
//import { AuthService, AuthServiceConfig } from 'angularx-social-login';

// SFSCommon Imports

// --------- Models
import { ISystemSettings } from './core/models/ISystemSettings';

// --------- Services
import { HttpService } from './core/http/http.service';
import { SystemSettings, SystemCore, systemCoreFactory } from './core/services/system.service';
import { GeolocationService } from './core/services/location/geolocation.service';
import { DataService } from './core/services/data/data.service';
import { AuthenticationService  } from './core/authentication/authentication.service';
import { FilesService } from './core/services/files/files.service';
import { StorageService } from './core/services/storage/storage.service';
import { UserService } from './core/services/user/user.service';
import { ThemingService } from './core/services/theming/theming.service';
import { LoggingService } from './core/services/logging/logging.service';
import { CompanyService } from './core/services/company/company.service';
import { EntityService } from './core/services/entity/entity.service';
import { CodePushService } from './core/services/code-push.service';

// --------- Guards
import { AuthGuardService } from './core/authentication/guard.service';

// --------- Components
import { SFSLoginComponent } from './common/components/login/login.component';
import { SFSUserInfoMenuComponent } from './common/components/user-info-menu/user-info-menu.component';
import { SFSTableComponent } from './common/components/table/table.component';

// import { DynamicFormComponent } from './core/forms/dynamic-form/dynamic-forms.component';
// import { InputComponent } from './core/forms/controls/input/input.component';
// import { ButtonComponent } from './core/forms/controls/button/button.component';
// import { DateTimeComponent } from './core/forms/controls/datetime/datetime.component';
// import { NavigationComponent } from './core/forms/controls/navigation/navigation.component';
// import { CheckBoxComponent } from './core/forms/controls/checkbox/checkbox.component';

// ----------- Pipes
import { DynamicPipe } from './common/pipes/dynamic.pipe';

// ----------- Directives
//import { DynamicFieldDirective } from './core/forms/dynamic-field.directive';

const DECLARATIONS = [
  SFSLoginComponent,
  SFSUserInfoMenuComponent,
  SFSTableComponent,

  DynamicPipe,
  // DynamicFormComponent,
  // DynamicFieldDirective,
  // NavigationComponent,
  // InputComponent,
  // ButtonComponent,
  // DateTimeComponent,
  // CheckBoxComponent
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  providers: [
    
    HttpService,
    DataService,
    GooglePlus,
    Facebook,
    //AuthService,
    AuthenticationService,
    AuthGuardService,
    Geolocation,
    GeolocationService,
    File,
    Camera,
    LoggingService,
    FilesService,
    StorageService,
    UserService,
    CompanyService,
    ThemingService,
    EntityService,
    CodePush,
    CodePushService
  ],
  imports: [
    
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    HttpClientModule,
    CdkTableModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    // SocialLoginModule,
    IonicStorageModule.forRoot(),
  ]
})
export class SFSCommonModule {
  static forRoot(systemSettings?: ISystemSettings): ModuleWithProviders<SFSCommonModule> {
    return {
      ngModule: SFSCommonModule,
      providers: [
        {
          provide: SystemSettings,
          useValue: systemSettings
        },
        {
          provide: SystemCore,
          useFactory: systemCoreFactory,
          deps: [
            SystemSettings,
            Platform
          ]
        }
       
        
      ]
    };
  }
}
