// Angular Imports
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

// SFSCommon Imports
import { UserService } from './../services/user/user.service';

/**
 * A basic AuthGuard; verifies if UserData and UserToken exists.
 */
@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public router: Router, public userService: UserService) { }

  async canActivate(route: ActivatedRouteSnapshot) {

    const response = await Promise.all([this.userService.getUserData(), this.userService.getUserToken()]);

    if (response[0] && response[1]) {
      
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }
}