import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import * as signalR from "@aspnet/signalr";
import { DataService, HttpService, UserService } from "sfscommon";
//import { SignalViewModel } from "../models/signal-view-model";

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<any>();

  constructor(public http: HttpService, public dataService: DataService,
    public router: Router, public userService: UserService
    ) {
    // this.buildConnection();
    // this.startConnection();
  }

  private buildConnection = () => {
    let path = this.http.system.generalUrl;
    if (window.location.host.indexOf("localhost") != -1){
      path = this.http.system.localUrl;
    }
    
  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${path}/signalhub`) //use your api adress here and make sure you use right hub name.
  //     .build();
  //   // this.hubConnection = new signalR.HubConnectionBuilder()
  //   //   .withUrl("http://localhost:63291/signalHub") //use your api adress here and make sure you use right hub name.
  //   //   .build();
  // };

  // private startConnection = () => {
  //   this.hubConnection
  //     .start()
  //     .then(() => {
  //       console.log("Connection Started...");
  //       this.registerSignalEvents();
  //     })
  //     .then(()=> this.getConnectionId())
  //     .catch(err => {
  //       console.log("Error while starting connection: " + err);

  //       //if you get error try to start connection again after 3 seconds.
  //       setTimeout(function() {
  //         this.startConnection();
  //       }, 3000);
  //     });
  };
  public connectionId : string;
  public getConnectionId = () => {
    // this.hubConnection.invoke('getconnectionid').then(
    //   (data) => {
    //       console.log(data);
    //       this.connectionId = data;
    //     }
    // ); 
  }
  private registerSignalEvents() {
    this.hubConnection.on("ReceiveMessage", (data: any) => {
      this.signalReceived.emit(data);
    });
  }
}