import { sfsService } from './sfs/services/sfs.service';
import { SignalRService } from './sfs/services/signalr.service';
import { Component, OnInit, Injector, AfterViewInit, ViewChild, ElementRef, TemplateRef, NgZone } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StorageService, LoggingService, AuthenticationService, BasePage, EVENT_USER_LOGGED_IN, ICompany, IUserDataModel, UserDataModel, SelectCompanyPage, EVENT_USER_LOGGED_OUT } from 'sfscommon';
import { Router } from '@angular/router';
import { MainMenuService } from './sfs/services/main-menu.service';
import { secMessageToUserModel } from './sfs/models/common/system-models';
import { SwUpdate } from '@angular/service-worker';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthFBService } from './sfs/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { App } from '@capacitor/app';

import { codePush } from 'capacitor-codepush';
import { GenericModalComponent } from './sfs/generic/generic-modal/generic-modal.component';
import { bizAppService } from './sfs/services/business.service';
import { hbProxyUserModel } from './sfs/models/codegen/hbProxyUser.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends BasePage implements OnInit {
  public selectedIndex = 0;




  @ViewChild('menu') menu: ElementRef;

  public myTemplateRef: TemplateRef<any>;
  @ViewChild('routertemplate') routertemplate: TemplateRef<any>;

  public appPages = [];

  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  visible = false;
  open = false;
  componentName: any;
  public minimized: boolean = false;




  public disabledMenu: boolean = true;
  public hiddenToolbar: boolean = true;
  public currentUser;

  public rootPage: any = null;
  public IsBrowser: boolean;
  public company: ICompany = null;
  public appLogo: string = 'https://url.blob.core.windows.net/public/scmovil/SC-MOVIL-LOGO.png';

  public currentFirstRole: string;
  public currentFirstRoleKey: string;

  constructor(
    firestore: AngularFirestore,
    private ionMenu: MenuController,
    public injector: Injector,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: StorageService,
    private logging: LoggingService,
    private auth: AuthenticationService,
    private authFBService: AuthFBService,
    private angularAuth: AngularFireAuth,
    router: Router,
    private zone: NgZone,
    private sfsService: sfsService,
    private bizService: bizAppService,
    private signalService: SignalRService,
    private mainMenuService: MainMenuService, private update: SwUpdate) {
    super(injector);
    console.log("component constructor", this);
    this.initializeApp();
    this.mainMenuService.controller.subscribe(status => this.changeMenuVisibility(status));
    this.mainMenuService.toggleController.subscribe(() => this.toggle());

    this.events.subscribe(EVENT_USER_LOGGED_IN, (data: any) => {
      this.logIn(data, false);
    });
    this.events.subscribe("checkProfileData", async (data: any) => {
      if (this.checkProfileChecked == false) {
        await this.checkProfileData();
      }
    });
    this.events.subscribe(EVENT_USER_LOGGED_OUT, this.logOut);

    this.events.subscribe('company:changed', () => {
      this.checkSelectCompany(this.currentUser);
      this.navCtrl.navigateRoot('reports/list');
    });


    this.events.subscribe('menu:minimized', () => {
      this.minimized = true;
    });
    this.events.subscribe('menu:hide', () => {
      this.disabledMenu = true;
    });
    this.authFBService.angularAuth.onAuthStateChanged((credential) => {
      console.log("authState subscribe", credential);
      this.authFBService.configureAuthState(credential);
    });

    this.updateClient();
  }

  updateClient() {
    if (!this.update.isEnabled) {
      console.log("Update Not enabled");
      return;
    } else {
      console.log("Update Is enabled");
    }
    this.update.available.subscribe((event) => {
      console.log("current", event.current, "available", event.available);
      if (confirm("Hay una actualización de la aplicación, por favor confirme para actualizar")) {
        this.update.activateUpdate().then(() => location.reload());
      }
    });
    this.update.activated.subscribe((event) => {
      console.log("current", event.previous, "available", event.current);
    });

  }

  public get isMobile(): boolean {

    let result: boolean = false;
    result = this.currentMediaQuery == 'xs' || this.currentMediaQuery == 'sm';
    //console.log("isMobile", result);
    return result;
  }
  public get isDesktop(): boolean {
    let result: boolean = false;
    result = this.currentMediaQuery == 'md' || this.currentMediaQuery == 'lg' || this.currentMediaQuery == 'xl';
    //console.log("isDesktop", result);
    return result;
  }
  public get isIdentified() {
    return this.currentUser != null;
  }
  // onMediaQueryChange = (currentMediaQuery: any, newActiveMediaQuery: any) => {
  //   console.log("onMediaQueryChange", currentMediaQuery,newActiveMediaQuery );
  // }
  navigateToMenuOption(urlOptionSelected: any) {
    //console.log("navigateToMenuOption 1");
    this.appPages.forEach(page => {
      if (page.title == urlOptionSelected.title) {
        page.active = true;
      }
      else {
        page.active = false;
      }
    });

    if (urlOptionSelected.url.endsWith("logoff")) {
      this.logOff();
    } else if (this.systemService.isMobile() || this.currentMediaQuery == 'xs' || this.currentMediaQuery == 'sm') {
      console.log("navigateToMenuOption 2");
      this.navCtrl.navigateRoot(urlOptionSelected.url);
      this.mainMenuService.toggle();
    }
    else {
      this.navCtrl.navigateRoot(urlOptionSelected.url);
    }

  }
  public async checkProfileData() {
    //if (userData.)
    let userData = await this.userService.getUserData();
    if (userData != null) {
      let userProfileCompleted = await this.userService.getParamValue("completed");
      if (userProfileCompleted == null || userProfileCompleted == false) {
        let userRefeshed = await this.bizService.GetItem(userData.IdUser, hbProxyUserModel, null, "my-account");
        if (userRefeshed.isSuccess()) {
          if (userRefeshed.data != null && userRefeshed.data.Completed != true) {
            this.checkProfileChecked = true;
            const modal = await this.modalCtrl.create({
              component: GenericModalComponent,

              componentProps: {
                entityName: "hbProxyUser",
                isFilter: false,
                isModal: true,
                completeData: true,
                usemode: "my-account",
                // item: item,
                formMode: "edit",
                formModeForced: true,
                guidItem: userData.IdUser,

              }

            });

            modal.onDidDismiss()
              .then((data: any) => {
                console.log("data", data);
                if (data != null && data.data != null) {
                  this.userService.setParamValue("completed", true);
                }
              });
            await modal.present();
          }
        }

      }
    }

  }
  public async checkSelectCompany(userData: IUserDataModel) {
    let idCompany = await this.userService.getIdCompanyFromUserData();

    this.logging.eventLog("company id: ", idCompany);
    this.logging.eventLog("Check select company", userData);
    if (idCompany == null && userData.Companies != null && userData.Companies.length > 1) {
      this.logging.eventLog("many companies, companyid:" + this.userService.getIdCompanyFromUserData());
      this.rootPage = SelectCompanyPage;

    } else {
      this.company = await this.userService.getCompanyFromUserData();
      // si hay empresa seleccionada
      this.logging.eventLog("company:", this.company);

      // buscar nombre de la empresa (objeto completo) seleccionada
      if (this.navCtrl) {

        this.rootPage = 'inicio';
      }
    }
  }

  async checkTest() {
    this.storage.get("introShown").then(async result => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (result) {
        this.userService.getUserData()
          .then(result => {
            this.loggingService.eventLog("getUserData", result);
            if (result != null) {
              // se especifica el usuario en la variable local
              this.currentUser = result;
              this.checkSelectCompany(result);
            } else {
              if (this.navCtrl) {
                this.rootPage = 'login';
              }
            }
          }).catch(error => {
            this.loggingService.eventLogError(error);
          });
      } else {
        // no se ha mostrado el Intro 
        if (this.navCtrl) {
          this.rootPage = 'IntroPage';
        }
      }
    });
  }

  checkProfileChecked: boolean = false;

  async initializeApp() {
    console.log("initializeApp");
    
    this.platform.ready().then(() => {
     
     
      
      //codePush.sync();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.userService.getUserData()
        .then(async result => {
          //this.loggingService.eventLog("263 getUserData", result);
          if (result != null) {
            console.log("initializeApp getUserData", result);
            // se especifica el usuario en la variable local
            this.currentUser = result;
            //await this.checkProfileData();

            this.checkSelectCompany(result);

            this.rootPage = 'login';
          } else {
            if (this.navCtrl) {
              //this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
            }
          }
        });
    });

    // Se recuperan las ceredencuales del usuario.
    const userCredentials = await this.userService.getUserData();

    if (userCredentials) {
      await this.logIn({ userData: userCredentials }, true);
    }
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        console.log("initializeApp data", data);
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = data.url.split("#").pop();
        console.log("initializeApp slug", slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  logIn = async (data, preventRedirect: boolean) => {

    console.log("data loginIn", data);
    this.currentUser = data.userData;
    this.disabledMenu = false;
    this.hiddenToolbar = false;
    await this.checkProfileData();
    await this.rolValidate();
    if (data.url != null) {
      await this.navCtrl.navigateRoot(data.url);
    } else {
      if (!preventRedirect) {
      await   this.navCtrl.navigateRoot("/");
      }
    }
  }

  logOut = async () => {
    try {

      await this.auth.logout();
      this.currentUser = null;
      this.disabledMenu = true;
      this.hiddenToolbar = true;
      await this.navCtrl.navigateRoot('/');

      // this.navCtrl.navigateRoot('/login?return=' + this.router.url, { animated: true, animationDirection: 'forward' });
      window.location.reload();
    } catch (error) {
      /** this.showAlertMessage('No fue posible cerrar sesión'); */
    }
  }

  routerActive() {

  }

  logOff() {

    this.events.publish("user:loggedOut");
    this.authFBService.logout();
  }

  newNotifications: number = 0;
  /* async getNewNotifications() {
     let result = await this.sfsService.GetCount({ EntityModel: secMessageToUserModel, Query: `it.GuidUser = "${this.currentUser.IdUser}" AND it.Readed = null` });
     if (result.isSuccess()) {
       this.newNotifications = result.data;
     }
   }*/
  async rolValidate() {
    this.primaryRole = await this.userService.getParamValue("defaultRole");
    this.appPages = [];
    this.appPages.push({
      title: 'Inicio',
      url: '/all-posts',
      icon: 'home',
      visible: true,
      active: true
    });
    //-start-custom-code

    let isSysAdmin = await this.userService.isInRole("sys-admin");

    this.appPages.push({ title: 'Mi cuenta', url: '/my-account', icon: 'account_box', visible: true, active: true });

    if (isSysAdmin) {
      this.appPages.push({ title: 'Catálogos', url: '/catalog/unProxyBusinessObject', icon: 'list', visible: true, active: true });
      this.appPages.push({ title: 'Configuración', url: '/catalog/unProxyBusinessObject', icon: 'list', visible: true, active: true });
    } else {
      if (this.primaryRole != null) {
        if (this.primaryRole.RoleKey.indexOf("customer") != -1) {
          this.appPages.push({ title: 'Mis publicaciones', url: '/my-posts', icon: 'travel_explore', visible: true, active: true });
          this.appPages.push({ title: 'Servicios cercanos', url: '/services', icon: 'home_repair_service', visible: true, active: true });

        } else if (this.primaryRole.RoleKey.indexOf("expert") != -1) {
          this.appPages.push({ title: 'Proyectos publicados', url: '/all-posts', icon: 'travel_explore', visible: true, active: true });
          this.appPages.push({ title: 'Mis servicios', url: '/my-services', icon: 'home_repair_service', visible: true, active: true });
          this.appPages.push({ title: 'Servicios', url: '/services', icon: 'home_repair_service', visible: true, active: true });

        } else if (this.primaryRole.RoleKey.indexOf("broker") != -1) {
          this.appPages.push({ title: 'Mis expertos', url: '/my-experts', icon: 'travel_explore', visible: true, active: true });
          this.appPages.push({ title: 'Proyectos publicados', url: '/all-posts', icon: 'travel_explore', visible: true, active: true });
          this.appPages.push({ title: 'Mis servicios', url: '/my-services', icon: 'home_repair_service', visible: true, active: true });
          this.appPages.push({ title: 'Servicios', url: '/services', icon: 'home_repair_service', visible: true, active: true });


        }
      }

    }
    this.appPages.push({ title: 'Cerrar sesión', url: '/logoff', icon: 'logout', visible: true, active: true });
    //-end-custom-code
    this.checActivePage();

    this.signalService.signalReceived.subscribe((signal: any) => {
      console.log("push ", signal);
    });

    //this.getNewNotifications();
  }
  menuSwitch() {
    this.minimized = !this.minimized;
    console.log(this.minimized);
    console.log(this.menu);
  }

  checActivePage() {
    this.appPages.forEach(page => {
      if (window.location.href.toString().includes(page.url)) {
        page.active = true;
      }
      else {
        page.active = false;
      }
    });

    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
  primaryRole: any;
  async ngOnInit() {
    this.myTemplateRef = this.routertemplate;
    let param = await this.getUserParam("_menu_visible");
    if (param != null) {
      this.open = param.Value;
    }

    this.events.subscribe('user:role-updated', async (data) => {
      this.primaryRole = data;
      this.rolValidate();
    });


  }

  async toggle() {


    if (!this.visible)
      this.visible = true;
    this.open = !this.open;
    console.log("component toggle", this.open);
    this.setUserParam("_menu_visible", this.open);
  }

  changeMenuVisibility(status: boolean) {
    console.log("changeMenuVisibility", status);
    this.visible = status;
  }
}
