// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { addButtonPlace, addButtonFloatType } from 'sfscommon';


export const environment = {
  
  production: false,
  firebase: {
    apiKey: "AIzaSyB2iX2_RAM0u_nrbQy81ok6gjNXeRmjpXg",
    authDomain: "handybroker-3117e.firebaseapp.com",
    databaseURL: "https://handybroker-3117e-default-rtdb.firebaseio.com/",
    projectId: "handybroker-3117e",
    storageBucket: "handybroker-3117e.appspot.com",
    messagingSenderId: "1079201720406",
    appId: "1:1079201720406:web:6d9268309491c8c95b73f7"
  },
  sfscommon: {
    useFirebaseAuth: true,
    AppNameKey: 'PSHandyBroker',
    GuidCompanyId: '1A2B961F-30E2-41D5-B256-9BC88819A3F2',
    GeneralUrl: 'https://hbservices.utilapp.com',
    // GeneralUrl: 'http://10.37.129.3/HBPoc',
    LocalUrl: '/Shell',
    BlobStorageURL: 'https://*.blob.core.windows.net/kstime',
    IsDebug: true,
    BuildVersion: '1.0.1',
    ServerVersion: 3,
    GoogleLocationAPIKey: '***',
    FacebookConfigAuth: { permissions: ['public_profile', 'email', 'user_birthday'], clientId: '1333094310151842' },
    GoogleConfigAuth: { offline: true, webClientId: '241810682914-n2rflefn6mqfunf7arotpq5tdrkmgr3o.apps.googleusercontent.com' },
    uiSettingsMobile: {
      addButtonPlace:addButtonPlace.float,
      addButtonFloatType:addButtonFloatType.BR,
      useMaterialExpansionPanel: true
   },
   uiSettingsDesktop:{
    addButtonPlace:addButtonPlace.header,
    addButtonFloatType:addButtonFloatType.BR
   }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
