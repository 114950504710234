// Angular Imports
import { Component, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

// Angular Flex Layout Imports
import { MediaObserver, MediaChange } from '@angular/flex-layout';

// Ionic Imports
import {
  MenuController,
  PopoverController,
  LoadingController,
  AlertController,
  ActionSheetController,
  ToastController,
  ModalController,
  NavController,
  NavParams
} from '@ionic/angular';

import { ComponentRef } from '@ionic/core';

// Third Library Imports
import { Observable, Subscription } from 'rxjs';

// SFSCommon Imports
import { LoggingService } from './services/logging/logging.service';
import { StorageService } from './services/storage/storage.service';
import { UserService } from './services/user/user.service';
import { SystemCore } from './services/system.service';
import { INavigationParams } from './models/INavigationParams';
import { UserDataModel } from './models/UserDataModel';
import { Events } from './services/events.service';
@Component({
  template: ''
})
export abstract class BasePage implements OnDestroy {

  public title: string;

  public currentMediaQuery: string;
  
  mediaQueryContants = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
  }

  // ----------------------- ANGULAR SERVICES -----------------------

  private _route: ActivatedRoute;
  private _router: Router;

  private _observableMedia: MediaObserver;
  private _mediaQuery$: Observable<MediaChange>;
  private _mediaQuerySubscription: Subscription;

  // ----------------------- IONIC SERVICES -----------------------

  private _navParams: NavParams;
  private _navCtrl: NavController;
  private _modalCtrl: ModalController;
  private _popoverCtrl: PopoverController;

  private _menuCtrl: MenuController;
  private _actionsCtrl: ActionSheetController;

  private _alertCtrl: AlertController;
  private _loadingCtrl: LoadingController;
  private _toastCtrl: ToastController;

  // ----------------------- SFS SERVICES -----------------------
  
  private _events: Events;
  private _loggingService: LoggingService;
  private _storageService: StorageService;
  private _userService: UserService;
  private _systemService: SystemCore;

  public onMediaQueryChange: (currentMediaQuery, newActiveMediaQuery) => void | Promise<any>;

  constructor(public injector: Injector) {

    this.title = '';

    this._mediaQuery$ = this.observableMedia.media$;

    this._mediaQuerySubscription = this._mediaQuery$.subscribe((mediaChange: MediaChange) => {

      // Se recupera el nuevo MediaQuery.
      
      const newActiveMediaQuery = this.mediaQueryContants[mediaChange.mqAlias];

      if (this.onMediaQueryChange != null) {
        this.onMediaQueryChange(this.currentMediaQuery, newActiveMediaQuery);
      }

      // No hay valor.
      if (this.currentMediaQuery === null) {

        // Valor inicial.
        this.currentMediaQuery = newActiveMediaQuery;

      } else if ((newActiveMediaQuery === 'xs' && this.currentMediaQuery !== 'xs')) {

        // Se actualizó a un Rango xs.
        this.currentMediaQuery = newActiveMediaQuery;

      } else if ((newActiveMediaQuery === 'sm' && this.currentMediaQuery !== 'sm')) {

        // Se actualizó a un Rango sm.
        this.currentMediaQuery = newActiveMediaQuery;

      } else if ((newActiveMediaQuery === 'md' && this.currentMediaQuery !== 'md')) {

        // Se actualizó a un Rango md.
        this.currentMediaQuery = newActiveMediaQuery;

      } else if ((newActiveMediaQuery === 'lg' && this.currentMediaQuery !== 'lg')) {

        // Se actualizó a un Rango lg.
        this.currentMediaQuery = newActiveMediaQuery;

      } else if ((newActiveMediaQuery === 'xl' && this.currentMediaQuery !== 'xl')) {

        // Se actualizó a un Rango xl.
        this.currentMediaQuery = newActiveMediaQuery;
      }
    });
  }

  ngOnDestroy(): void {
    if (this._mediaQuerySubscription != null) { this._mediaQuerySubscription.unsubscribe(); }
  }

  // ----------------------- GETTERS -----------------------------    

  public get observableMedia(): MediaObserver {

    if (!this._observableMedia) {

      this._observableMedia = this.injector.get(MediaObserver);
    }

    return this._observableMedia;
  }

  public get route(): ActivatedRoute {

    if (!this._route) {

      this._route = this.injector.get(ActivatedRoute);
    }

    return this._route;
  }

  public get router(): Router {

    if (!this._router) {

      this._router = this.injector.get(Router);
    }

    return this._router;
  }

  public get events(): Events {

    if (!this._events) {

      this._events = this.injector.get(Events);
    }

    return this._events;
  }

  public get navParams(): NavParams {

    try {

      if (!this._navParams) {

        this._navParams = this.injector.get(NavParams);
      }

    } catch (error) {

    }
    return this._navParams;
  }

  public get navCtrl(): NavController {

    if (!this._navCtrl) {

      this._navCtrl = this.injector.get(NavController);
    }

    return this._navCtrl;
  }

  public get menuCtrl(): MenuController {

    if (!this._menuCtrl) {

      this._menuCtrl = this.injector.get(MenuController);
    }

    return this._menuCtrl;
  }

  public get popoverCtrl(): PopoverController {

    if (!this._popoverCtrl) {

      this._popoverCtrl = this.injector.get(PopoverController);
    }

    return this._popoverCtrl;
  }

  public get modalCtrl(): ModalController {

    if (!this._modalCtrl) {

      this._modalCtrl = this.injector.get(ModalController);
    }

    return this._modalCtrl;
  }

  public get loadingCtrl(): LoadingController {

    if (!this._loadingCtrl) {

      this._loadingCtrl = this.injector.get(LoadingController);
    }

    return this._loadingCtrl;
  }

  public get alertCtrl(): AlertController {

    if (!this._alertCtrl) {

      this._alertCtrl = this.injector.get(AlertController);
    }

    return this._alertCtrl;
  }

  public get actionSheetCtrl(): ActionSheetController {

    if (!this._actionsCtrl) {

      this._actionsCtrl = this.injector.get(ActionSheetController);
    }

    return this._actionsCtrl;
  }

  public get toastCtrl(): ToastController {

    if (!this._toastCtrl) {

      this._toastCtrl = this.injector.get(ToastController);
    }

    return this._toastCtrl;
  }

  public get loggingService(): LoggingService {

    if (!this._loggingService) {

      this._loggingService = this.injector.get(LoggingService);
    }

    return this._loggingService;
  }

  public get storageService(): StorageService {

    if (!this._storageService) {

      this._storageService = this.injector.get(StorageService);
    }

    return this._storageService;
  }

  public get userService(): UserService {

    if (!this._userService) {

      this._userService = this.injector.get(UserService);
    }

    return this._userService;
  }

  public get systemService(): SystemCore {

    if (!this._systemService) {

      this._systemService = this.injector.get(SystemCore);
    }

    return this._systemService;
  }

  // ----------------------- SETTERS -----------------------------    

  public set observableMedia(value: MediaObserver) { this._observableMedia = value; }

  public set route(value: ActivatedRoute) { this._route = value; }

  public set router(value: Router) { this._router = value; }

  public set events(value: Events) { this._events = value; }

  public set navParams(value: NavParams) { this._navParams = value; }

  public set navCtrl(value: NavController) { this._navCtrl = value; }

  public set menuCtrl(value: MenuController) { this._menuCtrl = value; }

  public set popoverCtrl(value: PopoverController) { this._popoverCtrl = value; }

  public set modalCtrl(value: ModalController) { this._modalCtrl = value; }

  public set loadingCtrl(value: LoadingController) { this._loadingCtrl = value; }

  public set alertCtrl(value: AlertController) { this._alertCtrl = value; }

  public set actionSheetCtrl(value: ActionSheetController) { this._actionsCtrl = value; }

  public set toastCtrl(value: ToastController) { this._toastCtrl = value; }

  public set loggingService(value: LoggingService) { this._loggingService = value; }

  public set storageService(value: StorageService) { this._storageService = value; }

  public set userService(value: UserService) { this._userService = value; }

  public set systemService(value: SystemCore) { this._systemService = value; }

  // ---------------------- HELPER USER METHODS --------------------------

  public async getUserData(): Promise<any> {
    return await this.userService.getUserData();
  }
  public async setUserData(userData: UserDataModel): Promise<any> {
    return await this.userService.setUserData(userData);
  }

  public async getUserToken(): Promise<string> {
    return await this.userService.getUserToken();
  }

  // ---------------------- HELPER NAVIGATION METHODS --------------------------

  public getParam(paramName: string) : string{

    // Se valida que exista.
    if (this.route) {

      // Se recupera el parametro de la URL.
      return this.route.snapshot.paramMap.get(paramName);
    }

    return null;
  }
  async getUserParam(name: string) {
    let userData = await this.getUserData();
    if (userData != null) {
      if (userData.CustomParams != null) {
        let result: any = userData.CustomParams.find(p => p["Name"] == name);
        return result;
      } else {
        return null;
      }
    }else{
      return null;
    }
  }
  async getUserParamValue(name: string) {
    let userData = await this.getUserData();
    if (userData != null) {
      if (userData.CustomParams != null) {
        let result: any = userData.CustomParams.find(p => p["Name"] == name);
        if (result != null ){
          return result.Value;
        }else{
          return null;
        }
      } else {
        return null;
      }
    }else{
      return null;
    }
  }
  async setUserParam(name: string, value: any) {
    let userData = await this.getUserData();
    if (userData != null) {
      if (userData.CustomParams == null) {
        userData.CustomParams = [];
      }
      let result: any = userData.CustomParams.find(p => p["Name"] == name);
      if (result == null) {
        result = { Name: name, Value: value };
        userData.CustomParams.push(result);
      } else {
        result["Value"] = value;
      }
      await this.setUserData(userData);
    }

  }

  public getQueryParam(queryParamName: string) {

    // Se valida que exista.
    if (this.route) {

      // Se recupera el query param de la URL.
      return this.route.snapshot.queryParams[queryParamName];
    }

    return null;
  }

  public getNavParam(paramName: string) {

    if (this.navParams) {

      return this.navParams.get(paramName);
    }

    return null;
  }

  public recoverNavigationParams() {

    // Se recupera el titulo
    this.title = this.getNavParam('title');
  }

  public navigateAsPage(route: string, navigationParams: INavigationParams) {

    const navigationExtras: NavigationExtras = {
      state: navigationParams,
    };

    this.router.navigate([route], navigationExtras);
  }

  public async navigateAsModal(component: ComponentRef, navigationParams: INavigationParams) {

    const modal = await this.modalCtrl.create({
      component: component,
      animated: true,
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: navigationParams
    });

    modal.onDidDismiss().then((overlayEvent) => {

      // Se valida que exista el metodo y valor.
      if (navigationParams.onSelectDone != null && overlayEvent != null && overlayEvent.data != null) {

        // Se ejecuta.
        navigationParams.onSelectDone(overlayEvent.data);
      }
    });

    modal.present();
  }

  public async closeModal(data?: any) {
    await this.modalCtrl.dismiss(data);
  }

  // ---------------------- HELPER METHODS --------------------------

  public logError = (error: any, ...optonParams: any[]) => {
    this.loggingService.eventLogError(error, optonParams);
  }

  /** Creates an <a> element with target as '_blank' */
  public openURL = (url) => {
    let href = url;
    let link = document.createElement('a');
    link.target = '_blank';
    link.href = href;
    link.click();
  }

  // ---------------------- HELPER METHODS --------------------------

  public showAlertMessage = async (message: string) => {
    const alert = await this.alertCtrl.create({ message: message, backdropDismiss: true });
    alert.present();
  }

  public showToastMessage = async (message: string) => {
    const toast = await this.toastCtrl.create({ message: message, duration: 4000 });
    toast.present();
  }

  // ---------------------- HANDLERS --------------------------

  public handleNavigationError: (error: any) => Promise<void> | void = async () => {
    this.showAlertMessage('No fue posible acceder a la página. Verifique su conexión a internet e intenta nuevamente.');
  }

  public handleConnectionError: (error: any) => Promise<void> | void = async (error: any) => {
    if (error instanceof HttpErrorResponse) {
      this.showAlertMessage('Por favor revisa tu conexión a internet e intenta nuevamente.');
    }
  }

  public navigateBack = (url?: any, extas?: NavigationExtras) => { 
    this.navCtrl.setDirection('back');
    this.router.navigate([url], extas); 
  }
}