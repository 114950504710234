import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { UserService } from 'sfscommon';


@Injectable({ providedIn: 'root' })
export class RouterDataResolve implements Resolve<any> {

  constructor(public router: Router, public userService: UserService) {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log("resolve", route, state);
    if (state.url.indexOf("my-account") != -1){
      //const isRole = await this.userService.isInRole("role-key");
      let result: any = {};
      // if (isRole){
      //  result = "role-key";
      // }else{
         result =  "hbProxyUser";
      // }
      
      return result;
    }
  }
  
 
}