
export class uiSettings{
    ColorPrimaryButton?: string;
    ColorPrimaryHeader?: string;
    ColorBGFooter?: string;
    ColorBGPage?: string;
    ColorBGForm?: string;
    ColorFont?: string;
    ColorFontTitle1?: string;
    ColorFontTitle2?: string;
    CompanyLogoUrl?: string;
    ColorBGMenu?: string;
    ColorFontMenu?: string;
    ColorFontHeader?: string;

    addButtonPlace?:addButtonPlace= addButtonPlace.float;
    addButtonFloatType?: addButtonFloatType= addButtonFloatType.BR;
    titlePlace?:titlePlace = titlePlace.header;
    useMaterialExpansionPanel?:boolean=true;
}

export enum addButtonPlace{
    float=1,
    header=2,
    start=3,
    end=4,
    footer
}
export enum titlePlace{
   header=1,
   start=2
}

export enum addButtonFloatType{
   TR =1,
   BR= 2
}