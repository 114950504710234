import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
//import { Events, IUserDataModel } from 'sfscommon/sfscommon';
import { Events, EVENT_USER_LOGGED_IN, IUserDataModel, StorageService, SystemCore, UserDataModel, UserService } from 'sfscommon';
import { NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';




@Injectable()
export class AuthFBService {
    user$: Observable<firebase.User>;
    //public user$: BehaviorSubject<IUserDataModel> = new BehaviorSubject<IUserDataModel>(this.newUserObj());
    public async getFirebaseUser() {

        let userFirebase = await this.storageService.getGlobal("userFirebase-temp");
        return userFirebase;
    }
    public async loginWithToken(token:string){
        let result = await  this.angularAuth.signInWithCustomToken(token);
        return result;

    }
    
    constructor(public afs: AngularFirestore, public storageService: StorageService, private system: SystemCore, private nav: NavController, private userService: UserService, public angularAuth: AngularFireAuth, private events: Events, private httpclient: HttpClient) {
        this.angularAuth.onAuthStateChanged((credential) => {
            console.log("authState subscribe", credential);

            this.configureAuthState(credential);
        });
        this.user$ = this.angularAuth.authState.pipe(
            switchMap((user:any )=> {
              if (user) {
                return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
              } else {
                return of(null);
              }
            })
          );

        /*  if (this.system.useFirebaseAuth == true) {

            let currentUser = this.userService.getUserData().then((userData: IUserDataModel) => {
                if (userData != null) {
                    // es autentificado

                } else {
                    // anonimo
                    this.angularAuth.authState.subscribe(async (firebaseUser) => {
                        console.log("AuthFBService auth ", firebaseUser);
                        //let existsUser = this.sfsService.
                        if (firebaseUser != null) {
                            console.log("ProviderID", firebaseUser.providerData);
                            if (firebaseUser != null && firebaseUser.providerData.length == 1) {
                                let userDataForLogin: IUserDataModel = new UserDataModel();
                                userDataForLogin.Provider = firebaseUser.providerData[0]["providerId"];
                                userDataForLogin.Email = firebaseUser.email;
                                userDataForLogin.ProviderID = firebaseUser.providerData[0]["uid"];
                                userDataForLogin.PhotoUrl = firebaseUser.photoURL;
                                userDataForLogin.FirebaseID = firebaseUser.uid;
                                userDataForLogin.DisplayName = firebaseUser.displayName;
                                userDataForLogin.FullName = firebaseUser.displayName;
                                userDataForLogin.ProviderToken = await firebaseUser.getIdToken();
                                if (firebaseUser.providerData["providerId"] = "facebook.com") {

                                } else if (firebaseUser.providerData["providerId"] = "google.com") {

                                }
                                userDataForLogin.Register = true;

                                let response = await this.sfsService.loginToProviderApp(userDataForLogin);

                                if (response) {

                                    // La respuesta fue exitosa.
                                    if (response.status == 'success') {

                                        this.events.publish(EVENT_USER_LOGGED_IN, { userData: userData, url: '' });

                                        //this..navigateByUrl("/");

                                    } else {
                                        if (userDataForLogin.Email != null) {

                                        }
                                        //await  this.storageService.setGlobal("userFirebase-temp", userDataForLogin);

                                        // this.nav.navigateForward("register?mode=complete-provider");

                                        //this.showAlertMessage("No fue posible autenticar al usuario, verifique que la cuenta haya sido activada por medio del link que se envió a su correo cuando se registró.");
                                        // Error en la petición.
                                        //this.handleLoginError(response.reason);
                                    }
                                }
                            }
                        }
                        //this.sfsService.loginToProviderApp(theUser);
                        // verifica si la cuenta ya existe (email)
                        // id si es facebook
                        // if (existe) = login (provider + token) regresa usuario

                        //this.configureAuthState(firebaseUser);
                    });
                }
            }
            )

        }*/
    }
   
    
    newUserObj(): IUserDataModel {
        let result: IUserDataModel = {};

        return result;
    }
    doFBSignIn(provider: string): Promise<void> {
        var firebInstance: any = null;
        if (provider == "google") {
            firebInstance = new firebase.auth.GoogleAuthProvider();
        } else if (provider == "facebook") {

            firebInstance = new firebase.auth.FacebookAuthProvider();
            firebInstance.addScope('public_profile');
        }


        return this.angularAuth.signInWithRedirect(firebInstance).then((auth) => {
            console.log("after sign redirect", auth);
        });
    }

    async configureAuthState(firebaseUser: firebase.User): Promise<void> {
        let userData = await this.userService.getUserData();
        let token = await this.userService.getUserToken();
        if (firebaseUser && userData != null) {
            firebaseUser.getIdToken(true).then(async (theToken) => {
                
                //if (token != theToken) {
                    //let existsUser = this.sfsService.
                    if (firebaseUser != null) {
                        console.log("getIdToken refresh", token, theToken, firebaseUser.providerData);
                        if (firebaseUser != null && firebaseUser.providerData.length == 1) {
                            let userDataForLogin: IUserDataModel = new UserDataModel();
                            userDataForLogin.Provider = firebaseUser.providerData[0]["providerId"];
                            userDataForLogin.Email = firebaseUser.email;
                            userDataForLogin.ProviderID = firebaseUser.providerData[0]["uid"];

                            userDataForLogin.FirebaseID = firebaseUser.uid;
                            userDataForLogin.DisplayName = firebaseUser.displayName;
                            userDataForLogin.FullName = firebaseUser.displayName;
                            userDataForLogin.ProviderToken = theToken;//await firebaseUser.getIdToken();
                            if (firebaseUser.providerData["providerId"] = "facebook.com") {

                            } else if (firebaseUser.providerData["providerId"] = "google.com") {

                            }
                            userDataForLogin.Register = true;

                            await this.userService.setUserToken(theToken);
                           // let response = await this.sfsService.loginToProviderApp(userDataForLogin);
                        }
                    }
               // }
                /* console.log('we have a token');
                 this.httpclient.post('/api/users/verify', { token: theToken }).subscribe({
                     next: async () => {
                         let theUser = this.newUserObj();
                         //theUser.displayName = firebaseUser.displayName;
                         theUser.DisplayName = firebaseUser.displayName;
                         theUser.Email = firebaseUser.email;
                         theUser.ProviderToken = theToken;
                         theUser.IdProvider = firebaseUser.providerId;
 
                         //localStorage.setItem("jwt", theToken);
                         await this.sfsService.loginToProviderApp(theUser);
                         //this.user$.next(theUser);
                     },
                     error: (err) => {
                         console.log('inside the error from server', err);
                         this.doSignedOutUser()
                     }
                 });*/
            }, (failReason) => {
                this.doSignedOutUser();
            });
        } else {
            userData = await this.userService.getUserData();
            if (userData != null && userData.Provider != null ) {
                this.doSignedOutUser();
            }
            //this.doSignedOutUser();
        }
    }

    private doSignedOutUser() {
        // let theUser =  this.newUserObj();
        /* theUser.displayName = null;
         theUser.email = null;
         theUser.isSignedIn = false;
         localStorage.removeItem("jwt");*/
        this.events.publish("user:loggedOut", null);
        //this.user$.next(theUser);
    }

    logout(): Promise<void> {
        return this.angularAuth.signOut();
    }

    /* getUserobservable(): Observable<IUserDataModel> {
         //return this.user$.asObservable();
     }
 */
    getToken(): string {
        return localStorage.getItem("jwt");
    }

    getUserSecrets(): Observable<string[]> {
        return this.httpclient.get("/api/users/secrets").pipe(map((resp: string[]) => resp));
    }
}

