// Angular Imports
import { Injectable } from '@angular/core';

// Ionic Imports
import { Storage } from '@ionic/storage';

// SFSCommon
import { SystemCore } from '../system.service';

@Injectable()
export class StorageService {

  private appNameKey: string;

  constructor(public system: SystemCore, public storage: Storage) {
    this.appNameKey = system.appNameKey;
    this.storage.create();
  }

  // -------------- GET DATA --------------

  /**
   * Get data from 'Global' context.
   */
  public async getGlobal<T = any>(id: string): Promise<T> {
    return <T>await this.storage.get(id);
  }

  /**
   * Get data from 'AppNameKey' context.
   */
  public async get<T = any>(id: string): Promise<T> {
    return <T>await this.getGlobal(id + this.appNameKey);
  }

  // -------------- SET DATA --------------

  /**
   * Set data to 'Global' context.
   */
  public async setGlobal(id: string, data: any): Promise<any> {
    return await this.storage.set(id, data);
  }

  /**
   * Set data to 'AppNameKey' context.
   */
  public async set<T = any>(id: string, data: any): Promise<T> {
    return await this.setGlobal(id + this.appNameKey, data);
  }

  // -------------- REMOVE DATA --------------

  /**
   * Remove data from 'Global' context.
   */
  public async removeGlobal(key: string) {
    return await this.storage.remove(key);
  }

  /**
   * Remove data from  'AppNameKey' context.
   */
  public async remove(key: string) {
    return await this.removeGlobal(key + this.appNameKey);
  }
}
