// Angular Imports
import { Injectable } from '@angular/core';

// Ionic Native Imports
import { Camera } from '@ionic-native/camera/ngx';
import { File, FileEntry, Entry, IFile, FileError } from '@ionic-native/file/ngx';

// SFSCommon Imports
import { HttpService } from './../../http/http.service';
import { RequestOptions } from '../../http/models/RequestOptions';
import { SystemCore } from './../system.service';

@Injectable()
export class FilesService {

  // ---------------------- Private Fields --------------------------    

  constructor(
    public systemCore: SystemCore,
    public httpService: HttpService,
    public fileNative: File,
    public camera: Camera) {
  }

  public generateUploadRequestOptions(type: string, size: string, blobDate: string, blobSharedKey: string) {
    console.log();
    const headers = {
      'Content-Type': type,
      'Content-Length': size,
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-version': '2017-07-29',
      'x-ms-date': blobDate,
      'Authorization': blobSharedKey
    };
    console.log("headers", headers);
    return {
      headers: headers,
      reportProgress: false,
      observe: 'events',
      responseType: 'text'
    };
  }

  // ------------------ FILES -----------------

  public fromCamera = async (quality: number = 75, allowEdit?: boolean): Promise<string> => {

    const fileUri = await this.camera.getPicture({
      quality: quality,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.CAMERA,
      encodingType: this.camera.EncodingType.JPEG,
      allowEdit: allowEdit,
    });

    return fileUri;
  }

  public fromPhotoLibrary = async (quality: number = 75): Promise<string> => {

    const fileUri = await this.camera.getPicture({
      quality: quality,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      encodingType: this.camera.EncodingType.JPEG,

    });

    return fileUri;
  }

  public recoverEntry = async (fileUri: any): Promise<Entry> => {

    const entry = await this.fileNative.resolveLocalFilesystemUrl(fileUri);

    return entry;
  }

  public recoverFileFromEntry = (fileEntry: FileEntry,
    onSuccess: (iFile: IFile, fileBlob: Blob) => void,
    onError: (err: FileError) => void) => {

    // Returns a File that represents the current state of the file that this FileEntry represents.
    fileEntry.file((file: IFile) => {

      const reader = new FileReader();

      reader.onloadend = () => {

        const blob = new Blob([reader.result], { type: file.type });

        onSuccess(file, blob);
      };

      reader.readAsArrayBuffer(file);

    }, onError);
  }

  // ------------------ UPLOAD -----------------

  public upload = (url: string, data: any, options: RequestOptions) => {

    if (!options) { options = {}; }

    return this.httpService.putWeb(url, data, options);
  }

  // ------------------ DOWNLOAD -----------------

  public download = (url: string, options?: RequestOptions) => {

    if (!options) {
      options = {
        reportProgress: false,
        observe: 'events',
        responseType: 'blob'
      };
    }

    return this.httpService.get(url, options);
  }
}