export const SYSTEM_USER_TOKEN = 'SystemUserToKen';
export const SYSTEM_USER_TOKEN_LOCAL = 'SystemUserToKenLocal';
export const SYSTEM_USER_DATA = 'SystemUserData';
export const SYSTEM_GUID_COMPANY_ID = 'SystemGuidCompanyId';

export const TOKEN_USER_SYSTEM = 'TokenUserSystem';
export const TOKEN_REQUEST_NAME = 'Token';
export const TOKEN_COMPANY_ID_NAME = 'CompanyId';
export const TOKEN_CURRENT_USER = 'TokenCurrentUser';
export const KEY_CURRENT_USER = 'KeyCurrentUser';
export const EMAIL_CURRENT_USER = 'EmailCurrentUser';

export const PROVIDER_LOGIN_GOOGLE = 'google';
export const PROVIDER_LOGIN_FACEBOOK = 'facebook';

export const EVENT_USER_LOGGED_IN = 'user:loggedIn';
export const EVENT_USER_LOGGED_OUT = 'user:loggedOut';
export const EVENT_USER_COMPANY_CHANGED = 'company:changed';
