<div class="pre-form-signin">
  <form [formGroup]="form" class="form-signin">

    <ion-img *ngIf="urlLogo" class="logo-form" [src]="urlLogo"></ion-img>

    <p *ngIf="middleMessage" class="middle-message">
      {{ middleMessage }}
    </p>

    <ion-item class="item-user ion-no-padding">
      <ion-icon [name]="iconUser" slot="start" class="icon-person"></ion-icon>
      <ion-label [position]="positionLabel">{{ userLabelText }}</ion-label>
      <ion-input type="text" formControlName="username" [placeholder]="userPlaceholderText"></ion-input>
    </ion-item>

    <ion-item class="item-password ion-no-padding">
      <ion-icon [name]="iconPassword" slot="start" class="icon-password"></ion-icon>
      <ion-label [position]="positionLabel">{{ passwordLabelText }}</ion-label>
      <ion-input type='password' [clearOnEdit]="false" formControlName='password' [placeholder]="passwordPlaceholderText" (keyup.enter)="loginToSFS(form.value)"></ion-input>
    </ion-item>

    <div class="submit-button">
      <ion-button [disabled]="!form.valid || submitButtonDisabled" (click)="loginToSFS(form.value)">
        {{ submitButtonText }}
      </ion-button>
    </div>

    <div class="or-wrapper" *ngIf="allowSocialLogin == true">
      <hr class="or-hr">
      <span class="or-span">{{ orText }}</span>
    </div>

    <div class="login-social" *ngIf="allowSocialLogin == true">
      <ion-button class="google" (click)="loginToGoogle()">
        <ion-icon name="logo-google" slot="start"></ion-icon>
        Google
      </ion-button>
      <ion-button class="facebook" (click)="loginToFacebook()">
        <ion-icon name="logo-facebook" slot="start"></ion-icon>
        Facebook
      </ion-button>
    </div>

    <span class="recover-password" *ngIf="allowRecoverPassword == true">
      {{ recoverTextPassword }} <span class="recover-password-click" (click)="resetPassword()">{{ recoverTextClickPassword }}</span>
    </span>

  </form>
</div>