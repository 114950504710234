// Angular Imports
import { Injectable } from '@angular/core';

// SFSCommon Imports
import { SystemCore } from '../system.service';
import { HttpService } from '../../http/http.service';
import { ServiceData } from './models/ServiceData';
import { ApiResponse } from './../../http/models/ApiResponse';

@Injectable()
export class DataService {

  constructor(
    public httpCore: HttpService,
    public system: SystemCore) {
  }

  public async customMethod<T = any>(serviceData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;

    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }

    const url = this.httpCore.generateUrl();

    // Se recupera el AppKey
    const appKey = serviceData.AppNameKey ? serviceData.AppNameKey : this.system.appNameKey;

    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${serviceData.EntitySet}/CustomMethod?v=2&usemode=${serviceData.Usemode}`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKey,
      CompanyId: companyId,
      Fields: serviceData.Fields,
      Usemode: serviceData.Usemode,
      Item: serviceData.Item,
      Items: serviceData.Items,
      OverrideAppNameKey: serviceData.OverrideAppNameKey,
      ItemKeys: serviceData.ItemKeys,
      MethodName: serviceData.MethodName,
      ExtraParams: serviceData.ExtraParams,
      ServerVersion: this.system.serverVersion,
      ExtraUrlQuery: serviceData.ExtraUrlQuery,
      ContentText: serviceData.ContentText,
      ContentObject: serviceData.ContentObject,
      ReturnType: serviceData.ReturnType,
      UIEntity: serviceData.UIEntity, 
      FreeText: serviceData.FreeText
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return response;
  }

  public async get<T = any>(listData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;
    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }
    const url = this.httpCore.generateUrl();

    let extraUrlQuery = '';
    let allFields = '';
    let usemode = '';

    if (listData.ExtraUrlQuery != null) {
      extraUrlQuery = '&' + listData.ExtraUrlQuery;
    }

    if (listData.Usemode != null) {
      usemode = '&usemode=' + listData.Usemode;
    }

    if (listData.AllFields == true) {
      allFields = '&allFields=1&';
    }

    // Se recupera el AppKey
    let appKey = listData.AppNameKey ? listData.AppNameKey : this.system.appNameKey;
    const appKeyForWrapper  = appKey;
    // nuevo objeto override, para cuando una aplicación hace uso de otra
    // dentro del framework
    if (listData.RestServiceAppKey != null ){
      appKey = listData.RestServiceAppKey;
    }

    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${listData.EntitySet}/Get?v=2${allFields}${usemode}${extraUrlQuery}`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKeyForWrapper,
      CompanyId: companyId,
      Page: listData.Page,
      Usemode: listData.Usemode,
      ItemKey: listData.ItemKey,
      ItemKeys: listData.ItemKeys,
      PageSize: listData.PageSize,
      ExtraParams: listData.ExtraParams,
      ExtraUrlQuery: listData.ExtraUrlQuery,
      AllFields: listData.AllFields,
      ServerVersion: this.system.serverVersion,
      QueryFilter: listData.Query,
      Fields: listData.Fields,
      SortBy: listData.SortBy,
      OverrideAppNameKey: listData.OverrideAppNameKey,
      SortDirection: listData.SortDirection,
      UIEntity: listData.UIEntity, 
      FreeText: listData.FreeText,
      Params: listData.Params
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return Object.assign(new ApiResponse<T>(), response);
  }

  public async getCount<T = any>(listData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;
    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }

    const url = this.httpCore.generateUrl();

    let extraUrlQuery = '';
    let usemode = '';

    if (listData.ExtraUrlQuery != null) {
      extraUrlQuery = '&' + listData.ExtraUrlQuery;
    }

    if (listData.Usemode != null) {
      usemode = '&usemode=' + listData.Usemode;
    }

    // Se recupera el AppKey
    let appKey = listData.AppNameKey ? listData.AppNameKey : this.system.appNameKey;
    const appKeyForWrapper  = appKey;
    // nuevo objeto override, para cuando una aplicación hace uso de otra
    // dentro del framework
    if (listData.RestServiceAppKey != null ){
      appKey = listData.RestServiceAppKey;
    }

    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${listData.EntitySet}/GetCount?v=2${usemode}${extraUrlQuery}`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKeyForWrapper,
      CompanyId: companyId,
      UIEntity: listData.UIEntity, 
      Page: listData.Page,
      Usemode: listData.Usemode,
      PageSize: listData.PageSize,
      ExtraParams: listData.ExtraParams,
      ExtraUrlQuery: listData.ExtraUrlQuery,
      Params: listData.Params,
      QueryFilter: listData.Query,
      Fields: listData.Fields,
      SortBy: listData.SortBy,
      ServerVersion: this.system.serverVersion,
      SortDirection: listData.SortDirection,
      FreeText: listData.FreeText
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return Object.assign(new ApiResponse<T>(), response);
  }

  public async create<T = any>(serviceData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;
    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }
    const url = this.httpCore.generateUrl();

    // Se recupera el AppKey
    let appKey = serviceData.AppNameKey ? serviceData.AppNameKey : this.system.appNameKey;
    const appKeyForWrapper  = appKey;
    // nuevo objeto override, para cuando una aplicación hace uso de otra
    // dentro del framework
    if (serviceData.RestServiceAppKey != null ){
      appKey = serviceData.RestServiceAppKey;
    }
    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${serviceData.EntitySet}/Create?v=2&`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKeyForWrapper,
      OverrideAppNameKey:serviceData.OverrideAppNameKey,
      CompanyId: companyId,
      Usemode: serviceData.Usemode,
      ExtraParams: serviceData.ExtraParams,
      ExtraUrlQuery: serviceData.ExtraUrlQuery,
      Fields: serviceData.Fields,
      Item: serviceData.Item,
      ServerVersion: this.system.serverVersion,
      ReturnType: serviceData.ReturnType,
      
      Items: serviceData.Items
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return Object.assign(new ApiResponse<T>(), response);
  }

  public async update<T = any>(serviceData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;
    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }
    const url = this.httpCore.generateUrl();

    // Se recupera el AppKey
    let appKey = serviceData.AppNameKey ? serviceData.AppNameKey : this.system.appNameKey;
    const appKeyForWrapper  = appKey;
    // nuevo objeto override, para cuando una aplicación hace uso de otra
    // dentro del framework
    if (serviceData.RestServiceAppKey != null ){
      appKey = serviceData.RestServiceAppKey;
    }
    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${serviceData.EntitySet}/Update?v=2&`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKeyForWrapper,
      Usemode: serviceData.Usemode,
      OverrideAppNameKey: serviceData.OverrideAppNameKey,
      ExtraParams: serviceData.ExtraParams,
      ExtraUrlQuery: serviceData.ExtraUrlQuery,
      CompanyId: companyId,
      ServerVersion: this.system.serverVersion,
      Fields: serviceData.Fields,
      ReturnType: serviceData.ReturnType,
      Item: serviceData.Item,
      Items: serviceData.Items
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return Object.assign(new ApiResponse<T>(), response);
  }

  public async delete<T = any>(serviceData: ServiceData, useHeader: boolean = true): Promise<ApiResponse<T>> {

    // Se recupera el Header para la petición.
    let headers = null;

    if (useHeader == true) {
      headers = await this.httpCore.getHeaderOptions();
    }

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;
    if (companyId == null ){
      companyId = await this.httpCore.userService.getIdCompanyFromUserData();
    }

    const url = this.httpCore.generateUrl();

    // Se recupera el AppKey
    let appKey = serviceData.AppNameKey ? serviceData.AppNameKey : this.system.appNameKey;
    const appKeyForWrapper  = appKey;
    // nuevo objeto override, para cuando una aplicación hace uso de otra
    // dentro del framework
    if (serviceData.RestServiceAppKey != null ){
      appKey = serviceData.RestServiceAppKey;
    }
    // Se genera la URL.
    const urlRequest = `${url}/${appKey}/${serviceData.EntitySet}/Delete?v=2&`;

    // Se genera la información a enviar.
    const data = {
      AppKey: appKeyForWrapper,
      CompanyId: companyId,
      Usemode: serviceData.Usemode,
      ItemKeys: serviceData.ItemKeys,
      ServerVersion: this.system.serverVersion,
      ItemKey: serviceData.ItemKey,
      Fields: serviceData.Fields,
      OverrideAppNameKey: serviceData.OverrideAppNameKey,
      Item: serviceData.Item,
      Items: serviceData.Items
    };

    const response = await this.httpCore.post(urlRequest, data, headers);

    return Object.assign(new ApiResponse<T>(), response);
  }
}
