// Angular Imports
import { Component, Injector, OnInit } from '@angular/core';

// SFSCommon Imports
import { EVENT_USER_COMPANY_CHANGED } from '../../configs/system.variables';
import { ICompany } from '../../core/models/ICompany';
import { BasePage } from '../../core/base-page';
import { UserDataModel } from '../../core/models/UserDataModel';
import { DataService } from '../../core/services/data/data.service';
import { UserService } from '../../core/services/user/user.service';
import { CompanyService } from './../../core/services/company/company.service';

@Component({
  selector: 'sfs-select-company-page',
  templateUrl: './select-company.page.html',
  styleUrls: ['./select-company.page.scss'],
})
export class SelectCompanyPage extends BasePage implements OnInit {

  public idCompanySelected: string = null;
  public companies: Array<ICompany> = [];

  constructor(public injector: Injector,
    
    public companyService: CompanyService,
    public dataService: DataService) {
    super(injector);
  }

  public async ngOnInit() {
    this.idCompanySelected = await this.userService.getIdCompanyFromUserData();
    await this.recoverCompaniesFromCurrentUser();
  }

  public async recoverCompaniesFromCurrentUser() {

    try {

      const userData: UserDataModel = await this.userService.getUserData();

      if (userData) {
        this.companies = userData.Companies;
      }

    } catch (error) { this.logError(error); }
  }

  public async selectCompany() {

    const loader = await this.loadingCtrl.create({ message: 'Cambiando empresa' });

    loader.present();

    try {

      
      
      await this.userService.setIdCompanyInUserData(this.idCompanySelected);

      const dataEvent = {
        idCompanySelected: this.idCompanySelected
        
      };

      this.events.publish(EVENT_USER_COMPANY_CHANGED, dataEvent);

    } catch (error) { this.logError(error); } finally { loader.dismiss(); }
  }
}
