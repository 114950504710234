import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpService, DataService, EntityWrapper, ApiResponse, ServiceData, IGeoData, TableColumn, GeolocationService } from 'sfscommon';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { ServiceDataOptions, sfsService } from './sfs.service';
import { SeparatorSettings, FieldSettings, ControlTypes, FieldTypes } from '../models/common/page.model';
import { bizAppService } from './business.service';
import { table } from 'console';
import { setupTestingRouter } from '@angular/router/testing';
import { secGeoCountryModel } from '../models/common/system-models';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'any' })
export class GeoDataService {
  constructor(public http: HttpService, public dataService: DataService, public bizAppService: bizAppService, public sfsService: sfsService) {
    //this.url = http.generateUrl();
  }

  public  getDistance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
}

  async getCountry(countryCode):Promise<secGeoCountryModel> {
    let result = await this.sfsService.CustomMethod({  "CountryCode": countryCode }, "GeoData", "GetCountryByCode");
    if (result.status == "success"){
      return result.data;
    }else{
      return null;
    }
    
  }
  async getCountries():Promise<Array<secGeoCountryModel>> {
    let result = await this.sfsService.CustomMethod({ }, "GeoData", "GetCountries");
    if (result.status == "success"){
      return result.data;
    }else{
      return null;
    }
    
  }
  geoCountry:any=null;
  geoStates:any[]=[];
  geoCities:any[]=[];
  geoSubCities:any[]=[];
  getGeoStates(): Observable<any[]> {
    return of(this.geoStates);
}
  getGeoCities(): Observable<any[]> {
    return of(this.geoCities);
}
  getGeoSubcities(): Observable<any[]> {
    return of(this.geoSubCities);
}
public  async getFullAddress( address:string, guidSubcity:string, guidCity?:string, guidState?:string,guidCountry?:string,postalCode?:string) {
  let result:Array<string>=[];
  if(address != null && address.length > 0){
    result.push( address);
  }
  
   //subcity
   if ( guidSubcity != null && this.geoCities.length > 0){
      let city = this.geoSubCities.find(p=> p.GuidCity == guidSubcity);
      if (city != null ){
          result.push(city.ToponymName);
      }
      
  }

  if (guidCity!= null && this.geoCities.length > 0){
      let city = this.geoCities.find(p=> p.GuidCity == guidCity);
      if (city != null ){
          result.push(city.ToponymName);
      }
      
  }
  
  if (guidState!= null && this.geoStates.length > 0){
    let state = this.geoStates.find(p=> p.GuidState == guidState);
    if (state != null ){
        result.push(state.ToponymName);
    }
    
}

  //country
  if (guidCountry != null && this.geoCountry != null){
      result.push(this.geoCountry.ToponymName);
  }
  
  if(postalCode != null ){
    result.push(postalCode);
  }
 
  return  result.join(", ");
}


  async handleZipCodeChange(page:any, zipCode: string){
    
        if (zipCode && zipCode.length > 4) {
            
            
            // this.loading =  await this.loadingCtrl.create({ message: "Validando código postal, por favor espere...", });
            // this.loading.present();
            let geoData:any = null; 
            try {
              geoData = await page.geoDataService.getGeoData("mx", zipCode);
            }catch(error){
              console.log(error);
              this.geoStates = [];
              this.geoCities = [];
              this.geoSubCities = [];
            }
            if (geoData != null && geoData.GeoCountry != null && geoData.GeoCountry.secGeoStates.length == 1){
                this.geoCountry = geoData.GeoCountry;
                this.geoStates = geoData.GeoCountry.secGeoStates;
                this.geoCities = geoData.GeoCountry.secGeoStates[0].secGeoCities;
                this.geoSubCities = geoData.GeoCountry.secGeoStates[0].secGeoCities[0].SubCities;
            }
           // console.log("geoData", geoData);
            
        }
  }
  async getGeoData(countryCode, zipCode):Promise<IGeoData> {
    
    

      let geoData: IGeoData;

      let result = await this.sfsService.CustomMethod({ "PostalCode": zipCode, "CountryCode": countryCode }, "GeoData", "GetByPostalCode");

      if (result.status == "success") {
          geoData = result.data;
      } else {
          //console.log(result);
      }
      return geoData;
  
  }
}


