// Angular Imports
import { Injectable } from '@angular/core';

// Ionic Imports
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';

// SFSCommon Imports
import { IUserDataModel } from '../models/IUserDataModel';
import { PROVIDER_LOGIN_GOOGLE, PROVIDER_LOGIN_FACEBOOK } from '../../configs/system.variables';

import { HttpService } from './../http/http.service';
import { ApiResponse } from '../http/models/ApiResponse';

import { SystemCore } from '../services/system.service';
import { UserService } from './../services/user/user.service';
import { LoggingService } from './../services/logging/logging.service';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpService,
    private googlePlus: GooglePlus,
    private facebook: Facebook,
    private system: SystemCore,
    private userService: UserService,
    private loggingService: LoggingService) {
  }

  public async register<T>(userData: T): Promise<T> {

    // Se valida el input.
    if (!userData && !userData['AppKey']) { throw new Error('appkey-not-available'); }

    // Se recupera el Header para la petición.
    const headers = await this.http.getHeaderOptions();

    // Se recuperan la Url y el GuidCompany.
    const url = this.http.generateUrl();

    // Se hace la petición al servidor.        
    const response = await this.http.post(`${url}/Api/Register`, JSON.stringify(userData), headers);

    if (response['status'] !== 'error') {

      // Se guarda la información.
      await this.userService.setUserData(response['data']);
    }

    return response;
  }

  public async loginToSFSApp(userData: IUserDataModel): Promise<any> {

    // Se recupera el Header para la petición.
    const headers = await this.http.getHeaderOptions();

    // Se recuperan la Url y el GuidCompany.
    const url = this.http.generateUrl();

    userData.GetCompanyRoles = true;
    userData.AppKey = this.system.appNameKey;

    if (this.system.guidCompanyId != null && userData.IdCompany == null) {
      userData.IdCompany = this.system.guidCompanyId;
    }
    let apiPath = `${url}/Api/Login`;

    if (this.system.serverVersion === 3){
      apiPath = `${url}/Auth/Login`
    }
    const response = await this.http.post(apiPath, userData, headers);

    if (response['status'] !== 'error') {

      // Se guarda la información.
      await this.userService.setUserData(response['data']);
      await this.userService.setUserToken(response['data'].Token);
    }

    return response;
  }

  public async recoverPassword(email: string): Promise<any> { // TODO: Revisar

    // Se recupera el Header para la petición.
    const headers = await this.http.getHeaderOptions();

    // Se recuperan la Url y el GuidCompany.
    const url = this.http.generateUrl();

    const userData = {
      Email: email,
      UseCode: true,
      AppKey: this.system.appNameKey,
      GetCompanyRoles: true
    };

    return this.http.post(`${url}/Api/RecoveryPassword`, userData, headers);
  }

  public async changePassword(email: string, code: string, newPassword: string): Promise<any> { // TODO: Revisar

    // Se recupera el Header para la petición.
    const headers = await this.http.getHeaderOptions();

    // Se recuperan la Url y el GuidCompany.
    const url = this.http.generateUrl();

    const userData = {
      Email: email,
      UseCode: true,
      AppKey: this.system.appNameKey,
      Code: code,
      NewPassword: newPassword
    };

    return this.http.post(`${url}/Api/ChangePassword`, userData, headers);
  }

  // ---------------- GOOGLE ----------------

  public loginToGoogleWeb = async () => {

    // Se realiza la petición al provider.
    // const user = await this.authProvider.signIn(GoogleLoginProvider.PROVIDER_ID);

    const user: any = {};

    this.loggingService.eventLog('Login - Google Response', user);

    // Se crea el UserData con la información devuelta de Facebook.
    const userData: IUserDataModel = {
      FullName: user.firstName + ' ' + user.lastName,
      ProviderToken: user.authToken,
      Provider: PROVIDER_LOGIN_GOOGLE,
      ProviderID: user.id,
      Email: user.email,
      UserName: user.email,
      PhotoUrl: user.photoUrl,
      FirstName: null,
      GetCompanyRoles: true,
      LastName: null,
      IdUser: null,
      CompanyRoles: [],
      IdProvider: null,
      ProviderName: null,
      AppKey: this.system.appNameKey,
      IdCustomer: null,
      TimeCreated: null
    };

    // Se realiza la petición al servidor, para realizar el registro.
    const response = await this.afterProviderLogin(userData);

    return response;
  }

  public loginToGoogleNative = async (googleConfig) => {

    if (!googleConfig) { throw new Error('Not value exists for googleConfig - GoogleConfigAuth() in loginToGoogleNative'); }

    // Se realiza el proceso de autenticación a Google.
    const response = await this.googlePlus.login(googleConfig);

    this.loggingService.eventLog('Login - GooglePlus Response', response);

    // Se crea el UserData con la información devuelta de Google.
    const userData: IUserDataModel = {
      FullName: response.displayName,
      FirstName: response.givenName,
      LastName: response.familyName,
      ProviderToken: response.idToken,
      Provider: PROVIDER_LOGIN_GOOGLE,
      ProviderID: response.userId,
      Email: response.email,
      UserName: response.email,
      PhotoUrl: response.imageUrl,
      IdUser: null,
      IdProvider: null,
      CompanyRoles: [],
      GetCompanyRoles: true,
      ProviderName: null,
      AppKey: this.system.appNameKey,
      IdCustomer: null,
      TimeCreated: null
    };

    // Se realiza la petición al servidor, para realizar el registro.
    const responseServer = await this.afterProviderLogin(userData);

    return responseServer;
  }

  public loginToGoogle = async (): Promise<any> => {

    // Es Plataforma WEB.
    if (this.system.isBrowser()) {

      return await this.loginToGoogleWeb();

    } else {

      // Se recupera la configuración de System.
      const googleConfig = this.system.getGoogleConfigAuth();

      return await this.loginToGoogleNative(googleConfig);
    }
  }

  // ---------------- FACEBOOK ----------------

  public loginToFacebookWeb = async () => {

    // Se realiza la petición al provider.
    // const user = await this.authProvider.signIn(FacebookLoginProvider.PROVIDER_ID);

    const user: any = {};

    this.loggingService.eventLog('Login - Facebook Response', user);

    // Se crea el UserData con la información devuelta de Facebook.
    const userData: IUserDataModel = {
      FullName: user.firstName + ' ' + user.lastName,
      ProviderToken: user.authToken,
      Provider: PROVIDER_LOGIN_FACEBOOK,
      ProviderID: user.id,
      Email: user.email,
      UserName: user.email,
      PhotoUrl: user.photoUrl,
      FirstName: null,
      LastName: null,
      IdUser: null,
      IdProvider: null,
      CompanyRoles: [],
      ProviderName: null,
      GetCompanyRoles: true,
      AppKey: this.system.appNameKey,
      IdCustomer: null,
      TimeCreated: null
    };

    // Se realiza la petición al servidor, para realizar el registro.
    const response = await this.afterProviderLogin(userData);

    return response;
  }

  public loginToFacebookNative = async (facebookConfig) => {

    if (!facebookConfig) { throw new Error('Not value exists for facebookConfig - getFacebookConfigAuth() in loginToFacebookNative'); }

    // Se realiza el proceso de autenticación a Facebook.
    const response = await this.facebook.login(facebookConfig.permissions);

    this.loggingService.eventLog('Login - FacebookNative Response', response);

    // TODO: Validar si es una respuesta valida o con error en la solicitud.
    const userId = response.authResponse.userID;

    // Se solicita a Facebook: name, gender, email
    const user = await this.facebook.api('/me?fields=name,gender,email,picture,birthday', []);

    this.loggingService.eventLog('API Response - FacebookNative', user);

    // Se crea el UserData con la información devuelta de Facebook.
    const userData: IUserDataModel = {
      FullName: user.displayName,
      ProviderToken: response.authResponse.accessToken,
      Provider: PROVIDER_LOGIN_FACEBOOK,
      ProviderID: userId,
      Email: user.email,
      UserName: user.email,
      PhotoUrl: 'https://graph.facebook.com/' + userId + '/picture?type=large',
      FirstName: null,
      LastName: null,
      CompanyRoles: [],
      IdUser: null,
      IdProvider: null,
      ProviderName: null,
      AppKey: this.system.appNameKey,
      GetCompanyRoles: true,
      IdCustomer: null,
      TimeCreated: null
    };

    // Se realiza la petición al servidor, para realizar el registro.
    const responseServer = await this.afterProviderLogin(userData);

    return responseServer;
  }

  public loginToFacebook = async (): Promise<any> => {

    // Es Plataforma WEB.
    if (this.system.isBrowser()) {

      return await this.loginToFacebookWeb();

    } else {

      // Se recupera la configuración de System.
      const facebookConfig = this.system.getFacebookConfigAuth();

      return await this.loginToFacebookNative(facebookConfig);
    }
  }

  public logout = async () => {

    const userData = await this.userService.getUserData();

    // Se valida si esta logeado en un provider
    if (userData && userData.Provider === 'facebook' && !this.system.isBrowser()) {

      const facebookLogoutResponse = await this.facebook.logout();

      this.loggingService.eventLog('Facebook Logout Response', facebookLogoutResponse);

      const userDataRemovedResponse = await this.userService.removeUserData();

      this.loggingService.eventLog('UserData eliminado', userDataRemovedResponse);

      const tokenRemovedResponse = await this.userService.removeUserToken();

      this.loggingService.eventLog('Token eliminado', tokenRemovedResponse);

      return tokenRemovedResponse;

    } else if (userData && userData.Provider === 'google' && !this.system.isBrowser()) {

      const googlePlusLogoutResponse = await this.googlePlus.logout();

      this.loggingService.eventLog('GooglePlus Logout Response', googlePlusLogoutResponse);

      const userDataRemovedResponse = await this.userService.removeUserData();

      this.loggingService.eventLog('UserData eliminado', userDataRemovedResponse);

      const tokenRemovedResponse = await this.userService.removeUserToken();

      this.loggingService.eventLog('Token eliminado', tokenRemovedResponse);

      return tokenRemovedResponse;

    } else {

      const userDataRemovedResponse = await this.userService.removeUserData();

      this.loggingService.eventLog('UserData eliminado', userDataRemovedResponse);

      const tokenRemovedResponse = await this.userService.removeUserToken();

      this.loggingService.eventLog('Token eliminado', tokenRemovedResponse);

      return tokenRemovedResponse;
    }
  }

  public loginToSFSAppWithProvider = async (userData: IUserDataModel): Promise<any> => {

    // Se recupera el Header para la petición.
    const headers = await this.http.getHeaderOptions();

    // Se recuperan la Url y el GuidCompany.
    const url = this.http.generateUrl();

    if (this.system.guidCompanyId != null && userData.IdCompany == null) {
      userData.IdCompany = this.system.guidCompanyId;
    }

    const response = <ApiResponse>await this.http.post(`${url}/Api/LoginWithProvider`, userData, headers);

    if (response['status'] !== 'error') {

      // Se guarda la información.
      await this.userService.setUserData(response['data']);
      await this.userService.setUserToken(response['data'].Token);
    }

    return response;
  }

  /**
   * Se realiza la petición al servidor con los datos del provider.
   * En caso de ser exitoso el registro, se almacena los datos del usuario.
   * Se retorna toda la llamada al servidor.
   */
  private afterProviderLogin = async (userData: IUserDataModel): Promise<any> => {

    const response = await this.loginToSFSAppWithProvider(userData);

    return response;
  }
}
