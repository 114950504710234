import { hbProxyUserFileModel } from 'src/app/sfs/models/codegen/hbProxyUserFile.model';
import { hbProxyUserModel } from 'src/app/sfs/models/codegen/hbProxyUser.model';
import { AppBaseComponent } from 'src/app/sfs/common/app-base-component/app-base.component';
import { Component, Injector, OnInit, Input } from '@angular/core';
import { hbProxyRoleModel } from '../../models/codegen/hbProxyRole.model';
import { UserDataModel } from 'sfscommon/sfscommon';

@Component({
  selector: 'app-menu-profile-photo',
  templateUrl: './menu-profile-photo.component.html',
  styleUrls: ['./menu-profile-photo.component.scss'],
})
export class MenuProfilePhotoComponent extends AppBaseComponent implements OnInit {
  public currentUser: UserDataModel;
  photo: string = "assets/images/user-no-photo.svg";
  primaryRole: any;
  //colPicSize: number = 4;
  

  
  public colPicSize():number{
    console.log(this.minimized);
    if (this.minimized ){
      return 12;
    }else{
      return 4;
    }
    
  }
  constructor(public injector: Injector) {
    super(injector);
    console.log("MenuProfilePhotoComponent...");
    
  }
  goToAccount(){
    this.navCtrl.navigateRoot("/my-account");
  }
  @Input() minimized: boolean = false;
  async ngOnInit() {
    //console.log("minimized---", this.minimized);
    
    this.currentUser = await this.userService.getUserData();
    let urlPhoto = this.currentUser.PhotoUrl;
    this.primaryRole = await this.userService.getParamValue("defaultRole");
    if (urlPhoto == null ){
      urlPhoto = await this.userService.getParamValue("urlPhoto");
    }
    if (urlPhoto != null) {
      if (urlPhoto.indexOf("http") != -1){
        this.photo =   urlPhoto;
      }else{
        this.photo = "https:" + urlPhoto;
      }
      
    }
    this.events.subscribe('user:data-updated', async (data) => {

      let existentData = await this.userService.getUserData();
      let userRequest = await this.bizAppService.Get({ EntityModel: hbProxyUserFileModel, Fields: "FirstName,LastName,Email,UrlThumbFile,GuidFile,GuidRole", AllFields: true, Query: `it.GuidUser = "${existentData.IdUser}"` });
      if (userRequest.isSuccess() && userRequest.data.length == 1) {
        // userFile no existe
        existentData.Email = userRequest.data[0].Email;
        existentData.FirstName = userRequest.data[0].FirstName;
        existentData.LastName = userRequest.data[0].LastName;
        existentData.DisplayName = userRequest.data[0].DisplayName;
        existentData.PhotoUrl =  userRequest.data[0].UrlThumbFile;
        await this.userService.setParamValue("urlPhoto", userRequest.data[0].PhotoUrl);
        this.photo = "https:" + userRequest.data[0].UrlThumbFile;
        this.currentUser = existentData;

        await this.userService.setUserData(existentData);
      } else {
        // userFile no existe
        userRequest = await this.bizAppService.Get({ EntityModel: hbProxyUserModel, Fields: "FirstName,LastName,Email,GuidFile,GuidRole", AllFields: true, Query: `it.GuidUser = "${existentData.IdUser}"` });
        if (userRequest.isSuccess() && userRequest.data.length == 1) {
          existentData.Email = userRequest.data[0].Email;
          existentData.FirstName = userRequest.data[0].FirstName;
          existentData.LastName = userRequest.data[0].LastName;
          this.currentUser = existentData;

          await this.userService.setUserData(existentData);
        }
        this.photo = "assets/images/user-no-photo.svg";
      }
      if (userRequest.data?.length == 1) {
        // obtener rol
        if (userRequest.data[0].GuidRole != null) {
          let roleRequest = await this.bizAppService.Get({ EntityModel: hbProxyRoleModel, AllFields: true, Fields: "RoleName,RoleKey", Query: `it.GuidRole = "${userRequest.data[0].GuidRole}"` });
          if (roleRequest.isSuccess() && roleRequest.data.length > 0) {
            this.primaryRole = roleRequest.data[0];
            await this.userService.setParamValue("defaultRole", this.primaryRole);
            this.events.publish("user:role-updated", this.primaryRole);
          }
        }
      }
      //await this.userService.setUserData();
    });


  }

}
