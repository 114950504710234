import { UserService } from './../services/user/user.service';
import { IUserDataModel } from './IUserDataModel';
import { ICompanyRole } from './ICompanyRole';
import { ICompany } from './ICompany';

export class UserDataModel implements IUserDataModel {

  constructor() {
    
  }
  public FirebaseID?:string
  public Register?:boolean
  public FirstName?: string;

  public LastName?: string;
  public FullName?: string;
  public DisplayName?: string;
  
  public Email?: string;
  public IdUser?: string;
  public IdProvider?: string;
  public IdCompany?: string;
  public IdAppUserCompany?: string;
  public ProviderName?: string;
  public AppKey?: string;
  public ProviderToken?: string;
  public Provider?: string;
  public ProviderID?: string;
  public UserName?: string;
  public PhotoUrl?: string;
  public IdCustomer?: string;
  public CustomParams?:Array<any>=[];
  public TimeCreated?: string;
  public CompanyRoles?: Array<ICompanyRole> = [];
  public GetCompanyRoles?: boolean;
  public Companies?: Array<ICompany> = [];

  public GetParam(name:string):any{
    if (this.CustomParams != null ){
    let result:any = this.CustomParams.find(p=> p["Name"] == name);
      return result;
    }else{
      return null;
    }
  }
  public GetParamValue(name:string):any{
    if (this.CustomParams != null ){
    let result:any = this.CustomParams.find(p=> p["Name"] == name);
    if (result != null){
      return result.Value;
    }else{
      return null;
    }
      
    }else{
      return null;
    }
  }
  public SetParam(name:string, value:any ):void{
    if (this.CustomParams == null ){
      this.CustomParams = [];
    }
    let result:any = this.CustomParams.find(p=> p["Name"] == name);
    if (result == null ){
      result =  { Name:name, Value: value };
      this.CustomParams.push(result);
    }else{
      result["Value"] = value;
    }
    
    
  }
  public SetParamValue(name:string, value:any ):void{
    if (this.CustomParams == null ){
      this.CustomParams = [];
    }
    let result:any = this.CustomParams.find(p=> p["Name"] == name);
    if (result == null ){
      result =  { Name:name, Value: value };
      this.CustomParams.push(result);
    }else{
      result["Value"] = value;
    }
    
    
    
  }
  public IsCompanyAdmin(): boolean {

    const rel = this.CompanyRoles.find(p => p.GuidCompany === this.IdCompany && p.GuidRole === 'fd385e50-e344-4260-98d8-44e99bef5cec');
    
    if (rel != null) {
      return true;
    } else {
      return false;
    }
  }
}
