import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class hbProxyRolePropertyNames{
	constructor(){}
	//axd
	GuidRole:string = "GuidRole";	

				 
				 
	RoleName:string = "RoleName";	

				 
				 
	RoleKey:string = "RoleKey";	

				 
				 
}

//test
export  class hbProxyRoleModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbProxyUsers",
            FkPropertyName: "GuidRole",
            Label: "hbProxyUsers",
            EntityName: "hbProxyUser"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProxyRoleModel.PropertyNames.RoleName,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbProxyRoleModel.PropertyNames.RoleName,
        placeholder: '',
        required: true,
		
		maxLength: 255,
    }
},
{
    key: hbProxyRoleModel.PropertyNames.RoleKey,
    type: 'input',

    templateOptions: {
	
	


        label: hbProxyRoleModel.PropertyNames.RoleKey,
        placeholder: '',
        required: false,
		
		maxLength: 100,
    }
},
	];
    }
	Id?:string;

		GuidRole:string;	

		 
	RoleName:string;	

		 
	RoleKey?:string;	

		 

	public static _EntitySetName:string =  "hbProxyRoleSet";
	public static _EntityName:string =  "hbProxyRole";
	public static PropertyNames: hbProxyRolePropertyNames = new hbProxyRolePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidRole,RoleName,RoleKey";

	public static _DefaultProperty: string= "RoleName";

	public get getSetName() : string {
		return hbProxyRoleModel._EntitySetName;
	}
	public get getPropertyNames() : hbProxyRolePropertyNames {
		return hbProxyRoleModel.PropertyNames;
	}
}


	
