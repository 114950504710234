// Ionic Imports
import { Injectable } from '@angular/core';

// SFSCommon Imports
import { SYSTEM_USER_DATA, SYSTEM_USER_TOKEN } from '../../../configs/system.variables';
import { StorageService } from '../storage/storage.service';
import { IUserDataModel } from '../../models/IUserDataModel';
import { UserDataModel } from '../../models/UserDataModel';

@Injectable()
export class UserService {

  constructor(public storageService: StorageService) {
  }

  // -------------- USER --------------

  public async getUserData(): Promise<UserDataModel> {

    let userData: UserDataModel = null;

    const result: UserDataModel = await this.storageService.get(SYSTEM_USER_DATA);

    if (result != null) {

      userData = new UserDataModel();

      Object.assign(userData, result);
    }

    return userData;
  }

  public async setUserData(userData: IUserDataModel): Promise<any> {
    await this.storageService.set(SYSTEM_USER_DATA, userData);
  }

  public async removeUserData(): Promise<any> {
    return await this.storageService.remove(SYSTEM_USER_DATA);
  }

  public async isInRole(roleNameKey: string, userdata?: UserDataModel): Promise<any> {

    const userData = userdata != null ? userdata : await this.getUserData();

    const companyRole = userData.CompanyRoles.find(r => r.RoleNameKey == roleNameKey);

    return companyRole != null;
  }

  public async isInRoles(rolesNameKey: string[], userdata?: UserDataModel): Promise<any> {

    const userData = userdata != null ? userdata : await this.getUserData();

    let isInRole = false;

    for (const roleNameKey of rolesNameKey) {

      const companyRole = userData.CompanyRoles.find(r => r.RoleNameKey == roleNameKey);

      if (companyRole != null) {

        isInRole = true;

        break;
      }
    }

    return isInRole;
  }

  // -------------- TOKEN --------------

  public async getUserToken(): Promise<any> {
    return await this.storageService.get(SYSTEM_USER_TOKEN);
  }

  public async setUserToken(token: any): Promise<any> {
    return await this.storageService.set(SYSTEM_USER_TOKEN, token);
  }

  public async removeUserToken(): Promise<any> {
    return await this.storageService.remove(SYSTEM_USER_TOKEN);
  }

  // -------------- COMPANY --------------

  public async getIdCompany() {

    const userData: IUserDataModel = await this.getUserData();

    let idCompany = null;

    if (userData != null && userData.IdCompany == null && userData.Companies.length >= 1) {

      idCompany = userData.Companies[0].IdCompany;

    }

    return idCompany;
  }

  public async getIdCompanyFromUserData() {

    const userData: IUserDataModel = await this.getUserData();

    if (userData) { return userData.IdCompany; }

    return null;
  }

  public async getCompanyFromUserData() {

    const userData: IUserDataModel = await this.getUserData();

    if (userData && userData.Companies) {

      const company = userData.Companies.find(p => p.IdCompany === userData.IdCompany);

      return company;
    }

    return null;
  }
  public async getParamValue(name: string): Promise<any> {
    const userData: UserDataModel = await this.getUserData();
    if (userData.CustomParams != null) {
      let result: any = userData.CustomParams.find(p => p["Name"] == name);
      if (result != null) {
        return result.Value;
      } else {
        return null;
      }

    } else {
      return null;
    }
  }

  public async setParamValue(name: string, value: any): Promise<void> {
    const userData: UserDataModel = await this.getUserData();
    if (userData.CustomParams == null) {
      userData.CustomParams = [];
    }
    let result: any = userData.CustomParams.find(p => p["Name"] == name);
    if (result == null) {
      result = { Name: name, Value: value };
      userData.CustomParams.push(result);
    } else {
      result["Value"] = value;
    }

    await this.setUserData(userData);

  }
  public async setIdCompanyInUserData(idCompany: string) {

    const userData: IUserDataModel = await this.getUserData();

    if (userData) {

      userData.IdCompany = idCompany;

      await this.setUserData(userData);
    }
  }
}
