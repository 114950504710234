// Angular Imports
import { Component, Injector, ViewChild } from '@angular/core';

// Ionic Imports
import { IonInfiniteScroll, IonContent } from '@ionic/angular';

// SFSCommon Imports
import { BasePage } from './../../core/base-page';
import { Pagination } from '../../core/services/data/models/Pagination';
import { TableColumn } from '../../core/services/entity/models/TableColumn';
import { ServiceData } from '../../core/services/data/models/ServiceData';
import { DataService } from '../../core/services/data/data.service';

@Component({
  selector: 'sfs-list-page',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss']
})
export class ListPage extends BasePage {

  @ViewChild(IonContent) public ionContent: IonContent;

  public entityKey: string;
  public entitySetName: string;
  public propertyName: string;
  public propertyPrimaryKey: string;
  public propertyDefaultField: string;

  public entityRelationCallerName: string;
  public entityRelationCallerGuid: string;
  public entityRelationCallerText: string;

  // Data
  public data: any[];
  public serviceData: ServiceData;

  public itemSelected: any;
  public itemsSelected: any[] = [];

  // Tabla/Columnas
  public displayedColumns: string[] = [];
  public tableColumns: TableColumn[] = [];

  public isSelectable: boolean = false;
  public isMultiple: boolean = false;

  public tablePageSize = 7;
  public listPageSize = 20;
  public averageItemHeight = 50;

  // Paginacion
  public pagination: Pagination;
  public infiniteScrollPagination: Pagination;
  public disbaleInfiniteScroll: boolean;

  // Media Query
  public desktopMediaQueries: string[] = ['lg', 'xl'];
  public mobileMediaQueries: string[] = ['xs', 'sm', 'md'];

  // Configuracion
  public loadingData: boolean = true;
  public showSearchBar: boolean = false;
  public allowAddItems: boolean = false;

  // ----------------------- SFS SERVICES -----------------------

  private _dataService: DataService;

  // ----------------------- SERVICES GETTERS -----------------------------

  public get dataService(): DataService {

    if (!this._dataService) {

      this._dataService = this.injector.get(DataService);
    }

    return this._dataService;
  }

  // ----------------------- SERVICES SETTERS -----------------------------

  public set dataService(value: DataService) { this._dataService = value; }

  // ----------------------- CONSTRUCTOR -----------------------------

  constructor(public injector: Injector, dataService?: DataService) {

    super(injector);

    // Se inicializa el Service Data
    this.serviceData = {
      Page: 1,
      PageSize: 7,
      AllFields: true,
      EntitySet: this.entitySetName,
      SortDirection: 'desc'
    };

    // Se inicializan los Paginadores
    this.pagination = this.infiniteScrollPagination = {
      total: 0,
      pageIndex: 1,
      pageSize: 7,
      previousPageIndex: 0
    };

    this.data = [];

    // Se recupera la información del NavParams
    this.recoverNavigationParams();

    // Será una lista de relacion uno a Muchos.
    if (this.isSelectable === true && this.isMultiple === true) {

      if (this.entityRelationCallerName != null && this.entityRelationCallerGuid != null) {

        const query = `it.${this.entityRelationCallerName} = new Guid('${this.entityRelationCallerGuid}')`;;

        if (this.serviceData.Query == null) {

          this.serviceData.Query = query;

        } else {

          this.serviceData.Query += `AND ${query}`;
        }
      }
    }
  }

  public onMediaQueryChangeDefault = async (currentMediaQuery, newActiveMediaQuery) => {

    if (this.serviceData != null && currentMediaQuery != newActiveMediaQuery) {

      // Se recupera la información.
      this.ionViewWillEnter();
    }
  };

  // ----------------------- LIFE CYCLE HOOKS -----------------------------

  public ngOnInit(): Promise<any> | any { }

  public ionViewWillEnter(): Promise<any> | any {

    if (this.loadingData == false) {

      this.initList();
    }
  }

  public async initList() {

    // Se procura la existencia del "entitySetName".
    if (this.serviceData.EntitySet == null && this.entitySetName != null) { this.serviceData.EntitySet = this.entitySetName; }

    this.loadingData = true;

    if (this.ionContent != null) {

      this.ionContent.scrollToTop();
    }

    try {

      const sortBy = this.serviceData.SortBy;
      const sortDirection = this.serviceData.SortDirection;

      // Se calcula la cantidad de elementos por página.
      this.listPageSize = await this.calculateAverageItemsPerPage();

      // Si aun no hay media query, que el tamaño sea de una tabla.
      this.serviceData.PageSize = this.systemService.isMobile() == true
        ? this.listPageSize
        : this.tablePageSize;

      // Se asignan los valores a la paginación.
      this.pagination.previousPageIndex = 0;
      this.pagination.pageIndex = 1;
      this.infiniteScrollPagination = { ...this.pagination };

      // Se hace el llamado al Count y Get al mismo tiempo.
      const result = await Promise.all([
        this.configurePagination(this.serviceData),
        this.getData(),
      ]);

      // Se asignan los datos recuperados.
      if (result && result[1]) {

        this.data = result[1];
      }

    } catch (error) {

      this.logError(error);

      this.handleConnectionError(error);

    } finally { this.loadingData = false; }
  }

  public recoverNavigationParams() {

    super.recoverNavigationParams();

    this.entityKey = this.getNavParam('entityKey');
    this.entitySetName = this.getNavParam('entitySetName');
    this.propertyName = this.getNavParam('propertyName');
    this.propertyPrimaryKey = this.getNavParam('propertyPrimaryKey');
    this.propertyDefaultField = this.getNavParam('propertyDefaultField');

    // Entidad de Relación.
    this.entityRelationCallerName = this.getNavParam('entityRelationCallerName');
    this.entityRelationCallerGuid = this.getNavParam('entityRelationCallerGuid');
    this.entityRelationCallerText = this.getNavParam('entityRelationCallerText');

    this.allowAddItems = this.getNavParam('allowAddItems');
    this.isSelectable = this.getNavParam('isSelectable');
    this.isMultiple = this.getNavParam('isMultiple');
  }

  // ----------------------- HELPER DATA -----------------------------

  /**
   * Set ServiceData with default values
   * 
   * Where initialServiceData = { EntitySetName, ListSettings[], SortBy, SortDirection }
   */
  public configureServiceData<T = any>(initialServiceData: T) {

    // Se recupera el EntitySetName
    const entitySetName = initialServiceData['EntitySetName'];

    // Se recupera la configuración de la Lista
    const listSettings = initialServiceData['ListSettings'];

    // Se recupera el tamaño de la lista
    const pageSize = listSettings != null ? listSettings['PageSize'] : 10;

    // Se recupera la propiedad a Ordenar
    const sortBy = initialServiceData['SortBy'];

    // Se recupera la direccion
    const sortDirection = initialServiceData['SortDirection'];

    // Se inicializa el ServiceData
    this.serviceData = {
      Page: 1,
      PageSize: pageSize,
      AllFields: true,
      SortBy: sortBy,
      SortDirection: sortDirection,
      EntitySet: entitySetName,
    };
  }

  public configureDisplayedColumns(tableDefinition: TableColumn[]) {

    const _displayedColumns = [];

    // Se iteran las propiedades
    for (const tableColumn of tableDefinition) {

      // Se asignan que columnas se van a mostrar
      _displayedColumns.push(tableColumn.prop);
    }

    // Se asignan las nuevas columnas que se visualizarán
    this.displayedColumns = _displayedColumns;

    // Se establece la definición de las columnas
    this.tableColumns = tableDefinition;
  }

  /**
   * Recover from server the total items and configure pagination
   */
  async configurePagination(serviceData: ServiceData) {

    // Se recupera el total de elementos conforme al ServiceData proporcionado
    const response = await this.dataService.getCount(serviceData, true);
    if (response.isSuccess() == true){
      this.onCountedData(response.data);
    }
    
    const pageIndex = serviceData.Page - 1;
    const pageSize = serviceData.PageSize;
    const previousPageIndex = pageIndex > 1 ? pageIndex - 1 : 1;

    if (response != null) {

      this.pagination = this.infiniteScrollPagination = {
        total: response.data,
        pageIndex: pageIndex,
        pageSize: pageSize,
        previousPageIndex: previousPageIndex
      };
    }
  }

  public onGetData(data:any): Promise<any> | any { }
  public onCountedData(data:any): Promise<any> | any { }
  async getData<T = any>(pagination?: Pagination, sortBy?: string, sortDirection?: string): Promise<T> {

    const tempServiceData = <ServiceData>{ ...this.serviceData };

    if (pagination != null) {
      tempServiceData.Page = pagination.pageIndex;
      tempServiceData.PageSize = pagination.pageSize;
    }

    if (sortBy != null) {
      tempServiceData.SortBy = sortBy;
    }

    if (sortDirection != null) {
      tempServiceData.SortDirection = sortDirection;
    }

    const apiResponse = await this.dataService.get<T>(tempServiceData, true);

    

    if (apiResponse && apiResponse.isSuccess() == true) {

      await this.onGetData(apiResponse.data );

      return apiResponse.data;
    }

    return null;
  }

  /** 
   * Has validation errors
   */
  public async loadData(pagination?: any, sortBy?: any, sortDirection?: any) {

    this.loadingData = true;

    try {

      this.data = await this.getData(pagination, sortBy, sortDirection);

    } catch (error) {

      this.handleConnectionError(error);

      throw error;

    } finally { this.loadingData = false; }
  }

  public handleRowClicked({ event, row }) {
    console.log('handleRowClicked', event, row);
  }

  public handleCellClicked({ event, property, row }) {
    console.log('handleCellClicked', event, property, row);
  }

  /**
   * DEPRECATED: Use handleSortChange
   */
  public async onSortChange({ pagination, sortBy, sortDirection }) {

    // Si no hay Propiedad para ordenar, se asigna el de ServiceData
    if (sortBy == null || sortBy == '') { sortBy = this.serviceData.SortBy; }

    // Si no hay Direccion para ordenar, se asigna el de ServiceData
    if (sortDirection == null || sortDirection == '') { sortDirection = this.serviceData.SortDirection; }

    pagination.pageIndex = pagination.pageIndex + 1;

    await this.loadData(pagination, sortBy, sortDirection);
  }

  public handleSortChange = this.onSortChange;

  public async handlePageChange({ pagination, sortBy, sortDirection }) {

    // Si no hay Propiedad para ordenar, se asigna el de ServiceData
    if (sortBy == null || sortBy == '') { sortBy = this.serviceData.SortBy; }

    // Si no hay Direccion para ordenar, se asigna el de ServiceData
    if (sortDirection == null || sortDirection == '') { sortDirection = this.serviceData.SortDirection; }

    pagination.pageIndex = pagination.pageIndex + 1;
    //this.pagination = pagination;
    await this.loadData(pagination, sortBy, sortDirection);
  }

  // -------------------- Searchbar Handlers ---------------------

  public async handleChangeSearchbar($event: any) {

    this.serviceData.FreeText = $event.detail['value'];

    // Se recuperan los primeros elementos.
    await this.configurePagination(this.serviceData);

    // Se recuperan los primeros elementos.
    await this.loadData();
  }

  public async handleClearSearchbar($event: any) {

    this.serviceData.FreeText = null;
  }

  public async handleCancelSearchbar($event: any) {

    this.showSearchBar = !this.showSearchBar;

    this.serviceData.FreeText = null;

    // Se recuperan los primeros elementos.
    await this.configurePagination(this.serviceData);

    // Se recuperan los primeros elementos.
    await this.loadData();
  }

  public async handleInfiniteScroll(infiniteScroll: IonInfiniteScroll, infiniteScrollEvent) {

    const sortBy = this.serviceData.SortBy;
    const sortDirection = this.serviceData.SortDirection;

    if (((this.infiniteScrollPagination.pageIndex + 1) * this.infiniteScrollPagination.pageSize) <= this.infiniteScrollPagination.total) {

      // La "paginación" del InfiniteScroll trabaja desde el indice 0 (así como la tabla), pero la información es recuperada de pagina 1.
      if (this.infiniteScrollPagination.pageIndex == 0) {

        // La página 1 siempre es recuperada, por lo que la siguiente petición de datos es en el pagina 2.
        this.infiniteScrollPagination.pageIndex = 2;

      } else {

        // La siguiente pagina es la consecutiva.
        this.infiniteScrollPagination.pageIndex += 1;
      }

      try {

        const dataRecovered = await this.getData(this.infiniteScrollPagination, sortBy, sortDirection);

        const newData = [...this.data].concat(dataRecovered);

        this.data = newData;

      } catch (error) {

        this.logError(error);

        this.handleConnectionError(error);

      } finally { infiniteScroll.complete(); }

    } else {

      infiniteScroll.complete();
    }
  }

  // -------------------- UTILS ---------------------

  public calculateAverageItemsPerPage: () => Promise<number> = async () => {

    let averageItemsPerPage = 0;

    // Existe el IonContent y se permite calcular de forma automatica el promedio de elementos.
    if (this.ionContent != null) {

      // Se recupera el Scroll
      const scroll = await this.ionContent.getScrollElement();

      if (scroll != null) {

        // Se calcula el promedio de elementos que caben en dicha resolución.
        averageItemsPerPage = Math.ceil(scroll.clientHeight / this.averageItemHeight);

      }
    }

    if (averageItemsPerPage == 0) { averageItemsPerPage = this.listPageSize; }

    return averageItemsPerPage;
  }

  // --------------------  ---------------------

  public handleSingleItemSelected(event: any) {

    const listItem = event.detail['value'];

    if (listItem != null) {

      this.itemSelected = {
        propertyName: this.entityKey,
        guid: listItem[this.propertyPrimaryKey],
        text: listItem[this.propertyDefaultField]
      };
    }
  }

  public handleMultipleItemsSelected(event: any, listItem) {


  }
}
