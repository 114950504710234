<ion-item *ngIf="showMode == 'item' && user != undefined">
  <ion-avatar slot="start" *ngIf="user.PhotoUrl">
    <img [src]="user.PhotoUrl">
  </ion-avatar>
  <ion-label>
    <h2 class="user-info-name">{{ user.FirstName }} {{ user.LastName }}</h2>
    <p class="user-info-email">{{ user.Email }}</p>
    <p *ngIf="user.CompanyRoles != null && user.CompanyRoles.length > 0" class="user-info-role">
      {{ user?.CompanyRoles[0].RoleName }}
    </p>
  </ion-label>
</ion-item>

<ion-card *ngIf="showMode == 'card' && user != undefined">
  <ion-item>
    <ion-avatar slot="start" *ngIf="user.PhotoUrl">
      <img [src]="user.PhotoUrl">
    </ion-avatar>
    <ion-label>
      <h2 class="user-info-name">{{ user.FirstName }} {{ user.LastName }}</h2>
      <p class="user-info-email">{{ user.Email }}</p>
      <p *ngIf="user.CompanyRoles != null && user.CompanyRoles.length > 0" class="user-info-role">
        {{ user?.CompanyRoles[0].RoleName }}
      </p>
    </ion-label>
  </ion-item>
</ion-card>