import { GenericFormPage } from 'src/app/sfs/generic/generic-form/generic-form.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './sfs/services/AuthGuard.service';
import { RouterDataResolve } from './sfs/services/routerDataResolve.service';

const routes: Routes = [
//start custom code
{
  path: '',
 // redirectTo: 'FolderIn/Index',
  redirectTo: 'all-posts',
  pathMatch: 'full'
},
//end custom code  

  {
    path: 'login',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)

  },
  {
    path: 'recovery-password',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'set-password/email/:email/code/:code',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'activate-account/email/:email/code/:code',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)
  }
  ,
  {
    path: 'activate-account-go/email/:email/code/:code',
    loadChildren: () => import('./sfs/common/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'catalog/:catalog',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'catalog/:catalog/form',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule)
  },
  {
    path: 'catalog/:catalog/form/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule)
  } ,
  {
    path: 'catalog/:catalog/form-tabs/:id',
    loadChildren: () => import('./sfs/generic/generic-tabs/generic-tabs.module').then( m => m.GenericTabsPageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./custom/pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },

  //start custom code
  {
    path: 'my-account',
    canActivate: [AuthGuardService],
    resolve: { "catalog": RouterDataResolve},
    data : { "formMode": "read"  },
    

    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'my-posts',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'my-posts/form',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'my-posts/form/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  {
    path: 'my-posts/details/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
 
  {
    path: 'all-posts',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'all-posts/form',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'all-posts/form/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  {
    path: 'all-posts/details/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },

  {
    path: 'services',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'services/form',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'services/form/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  {
    path: 'services/details/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  {
    path: 'my-services',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'my-services/form',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'my-services/form/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },



  {
    path: 'my-posts/details/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProject"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },

  {
    path: 'my-experts',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbExpert"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },
  {
    path: 'my-experts/form',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbExpert"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },
  
  {
    path: 'my-experts/form/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbExpert"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },

  {
    path: 'my-proposals',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProposal"  },
    loadChildren: () => import('./sfs/generic/generic-list/generic-list.module').then( m => m.GenericListPageModule)
  },

  {
    path: 'proposal/:id',
    canActivate: [AuthGuardService],
    data : {  "catalog": "hbProposal"  },
    loadChildren:   () =>   import('./sfs/generic/generic-form/generic-form.module').then( m => m.GenericFormPageModule),
  },



  {
    path: 'chat',
    loadChildren: () => import('./sfs/common/chat/chat.module').then( m => m.ChatPageModule)
  },


  //end custom code
  
];
// 


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {  useHash: true, paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
