import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbFileModel } from './hbFile.model';
import { hbProxyUserModel } from './hbProxyUser.model';
	



 class hbProxyUserFilePropertyNames{
	constructor(){}
	//axd
	GuidProxyUserFile:string = "GuidProxyUserFile";	

				 
				 
	GuidFile:string = "GuidFile";	

				 
				 
	GuidUser:string = "GuidUser";	

				 
				 
		FkhbFile:string = "FkhbFile";
		hbFile:string = "hbFile"; //test
				 
		FkhbProxyUser:string = "FkhbProxyUser";
		hbProxyUser:string = "hbProxyUser"; //test
				 
	ExistFile:string = "ExistFile";	

				 
				 
	FileStorage:string = "FileStorage";	

				 
				 
	UrlFile:string = "UrlFile";	

				 
				 
	UrlThumbFile:string = "UrlThumbFile";	

				 
				 
	FileThumbSizes:string = "FileThumbSizes";	

				 
				 
	FileName:string = "FileName";	

				 
				 
	FileType:string = "FileType";	

				 
				 
	FileSize:string = "FileSize";	

				 
				 
	BlobSAS:string = "BlobSAS";	

				 
				 
	BlobSharedKey:string = "BlobSharedKey";	

				 
				 
	BlobDate:string = "BlobDate";	

				 
				 
	UrlContractComputed:string = "UrlContractComputed";	

				 
				 
	FirstName:string = "FirstName";	

				 
				 
	LastName:string = "LastName";	

				 
				 
	Email:string = "Email";	

				 
				 
	DisplayName:string = "DisplayName";	

				 
				 
	GuidRole:string = "GuidRole";	

				 
				 
}

//test
export  class hbProxyUserFileModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProxyUserFileModel.PropertyNames.GuidFile,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbFile",
			EntityModel: hbFileModel,  
			DataValue: hbFileModel.PropertyNames.GuidFile,
            DataText: hbFileModel.PropertyNames.FileName 
			},

        label: hbProxyUserFileModel.PropertyNames.GuidFile,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.GuidUser,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidRole:hbProxyRole.GuidRole;GuidFile:hbFile.GuidFile",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProxyUser",
			EntityModel: hbProxyUserModel,  
			DataValue: hbProxyUserModel.PropertyNames.GuidUser,
            DataText: hbProxyUserModel.PropertyNames.Username 
			},

        label: hbProxyUserFileModel.PropertyNames.GuidUser,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.ExistFile,
    type: 'checkbox',

    templateOptions: {
	 
	indeterminate: false,

	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.ExistFile,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FileStorage,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FileStorage,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.UrlFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserFileModel.PropertyNames.UrlFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.UrlThumbFile,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserFileModel.PropertyNames.UrlThumbFile,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FileThumbSizes,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FileThumbSizes,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FileName,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FileName,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FileType,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FileType,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FileSize,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FileSize,
        placeholder: '',
        required: false,
		
        type: "number",
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.BlobSAS,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserFileModel.PropertyNames.BlobSAS,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.BlobSharedKey,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserFileModel.PropertyNames.BlobSharedKey,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.BlobDate,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbProxyUserFileModel.PropertyNames.BlobDate,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.UrlContractComputed,
    type: 'textarea',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.UrlContractComputed,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.FirstName,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.FirstName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.LastName,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.LastName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.Email,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.Email,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.DisplayName,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.DisplayName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbProxyUserFileModel.PropertyNames.GuidRole,
    type: 'input',

    templateOptions: {
	
	

	  isComputed: true,
	  isManuallySetted: false,

        label: hbProxyUserFileModel.PropertyNames.GuidRole,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
	];
    }
	Id?:string;

		GuidProxyUserFile:string;	

		 
	GuidFile?:string;	

		 
	GuidUser?:string;	

		 
		FkhbFile:string ;

		FkhbFileText:any;

		hbFile:string;
		
			 
		 
		FkhbProxyUser:string ;

		FkhbProxyUserText:any;

		hbProxyUser:string;
		
			 
		 
	ExistFile:boolean = false;	

		 
	FileStorage?:string;	

		 
	UrlFile?:string;	

		 
	UrlThumbFile?:string;	

		 
	FileThumbSizes?:string;	

		 
	FileName?:string;	

		 
	FileType?:string;	

		 
	FileSize?:number;	

		 
	BlobSAS?:string;	

		 
	BlobSharedKey?:string;	

		 
	BlobDate?:string;	

		 
	UrlContractComputed?:string;	

		 
	FirstName?:string;	

		 
	LastName?:string;	

		 
	Email?:string;	

		 
	DisplayName?:string;	

		 
	GuidRole?:string;	

		 

	public static _EntitySetName:string =  "hbProxyUserFileSet";
	public static _EntityName:string =  "hbProxyUserFile";
	public static PropertyNames: hbProxyUserFilePropertyNames = new hbProxyUserFilePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidProxyUserFile,GuidFile,GuidUser,ExistFile,FileStorage,UrlFile,UrlThumbFile,FileThumbSizes,FileName,FileType,FileSize,BlobSAS,BlobSharedKey,BlobDate,UrlContractComputed,FirstName,LastName,Email,DisplayName,GuidRole";

	public static _DefaultProperty: string= "GuidProxyUserFile";

	public get getSetName() : string {
		return hbProxyUserFileModel._EntitySetName;
	}
	public get getPropertyNames() : hbProxyUserFilePropertyNames {
		return hbProxyUserFileModel.PropertyNames;
	}
}


	
