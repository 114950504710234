<div class="table-container">
  <table class="table table-striped table-hover" cdk-table [dataSource]="data" matSort
    (matSortChange)="handleSortChange($event)">

    <!-- Checkbox Column -->
    <!-- <ng-container cdkColumnDef="select">
      <th cdk-header-cell *cdkHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container> -->

    <ng-container *ngFor="let column of columns; let i = index;" [cdkColumnDef]="column.prop">

      <ng-container *ngIf="column.headerTemplate != null && column.sortable == true">
        <th class="header-cell-{{column.prop}}-{{i}}" cdk-header-cell *cdkHeaderCellDef mat-sort-header>
          <ng-container *ngTemplateOutlet="column.headerTemplate; context: { prop: column.prop, colName: column.name }">
          </ng-container>
        </th>
      </ng-container>

      <ng-container *ngIf="column.headerTemplate != null && column.sortable != true">
        <th class="header-cell-{{column.prop}}-{{i}}" cdk-header-cell *cdkHeaderCellDef>
          <ng-container *ngTemplateOutlet="column.headerTemplate; context: { prop: column.prop, colName: column.name }">
          </ng-container>
        </th>
      </ng-container>

      <ng-container *ngIf="column.headerTemplate == null && column.sortable == true">
        <th class="header-cell-{{column.prop}}-{{i}}" [className]="getCellClass(column)" cdk-header-cell *cdkHeaderCellDef mat-sort-header>
          {{ column.name }}
        </th>
      </ng-container>

      <ng-container *ngIf="column.headerTemplate == null && column.sortable != true">
        <th class="header-cell-{{column.prop}}-{{i}}" [className]="getCellClass(column)" cdk-header-cell *cdkHeaderCellDef>
          {{ column.name }}
        </th>
      </ng-container>

      <td class="cell-{{column.prop}}-{{i}}" cdk-cell *cdkCellDef="let row" [className]="getCellClass(column, row)"
        (click)="clickCell($event, column.prop, row)">

        <ng-container *ngIf="column.cellTemplate != null">
          <ng-container
            *ngTemplateOutlet="column.cellTemplate; context: { row: row, prop: column.prop, value: row[column.prop] }">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="column.cellTemplate == null">
          {{ row[column.prop] }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Row Actions -->
    <!-- <ng-container cdkColumnDef="rowActions">
      <th cdk-header-cell *cdkHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container> -->

    <tr #headerRow cdk-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr #cellRow cdk-row *matRowDef="let row; columns: displayedColumns;" [className]="getRowClass(row)" (click)="selection.toggle(row); clickRow($event, row)">
    </tr>

   
  </table>
</div>

<ng-template #genericHeaderCellTemplate let-prop="prop" let-colName="colName">
  <div>{{ colName }}</div>
</ng-template>

<ng-template #genericRowCellTemplate let-row="row" let-prop="prop" let-value="value">
  <div>{{ value }}</div>
</ng-template>

<ng-container *ngIf="paginatorTemplate != null && displayPaginator == true">
  <ng-container
    *ngTemplateOutlet="paginatorTemplate; context: { length: pagination.total, pageSize: pagination.pageSize, pageIndex: pagination.pageIndex, pageSizeOptions: pageSizeOptions, disabled: disabledPaginator, page: handlePageChange }">
  </ng-container>
</ng-container>



<table class="table-footer-ap">
  <tr>
    <td class="footer-actions">
      <ng-container *ngIf="actionsFooter != null && displayActionsFooter == true">
        <ng-container
          *ngTemplateOutlet="actionsFooter; ">
        </ng-container>
      </ng-container>
    </td>
    <td class="footer-pag">
      <ng-container *ngIf="paginatorTemplate == null && displayPaginator == true">
        <mat-paginator [length]="pagination.total" [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex"
          [pageSizeOptions]="pageSizeOptions" [hidePageSize]="hidePageSize" [showFirstLastButtons]="showFirstLastButtons"
          [disabled]="disabledPaginator" (page)="handlePageChange($event)"></mat-paginator>
      </ng-container>
    </td>
  </tr>
</table>
