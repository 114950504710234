// Angular Imports
import { Component, Injector, OnInit } from '@angular/core';

// SFSCommon Imports
import { UIModel } from '../../core/services/entity/models/UIModel';
import { TableColumn } from '../../core/services/entity/models/TableColumn';
import { EntityService } from '../../core/services/entity/entity.service';
import { ListPage } from '../list/list.page';

@Component({
  selector: 'sfs-entity-list-page',
  templateUrl: './entity-list.page.html',
  styleUrls: ['./entity-list.page.scss']
})
export class EntityListPage extends ListPage implements OnInit {

  public uiModel: UIModel;

  // ----------------------- SFS SERVICES -----------------------

  private _entityService: EntityService;

  // ----------------------- CONSTRUCTOR -----------------------

  constructor(public injector: Injector) {

    super(injector);

    // Se recupera la entidad
    this.entitySetName = this.getParam('entitySetName');

    // Se establece un título
    this.title = this.entitySetName;
  }

  // ----------------------- GETTERS -----------------------------    

  public get entityService(): EntityService {

    if (!this._entityService) {

      this._entityService = this.injector.get(EntityService);
    }

    return this._entityService;
  }

  // ----------------------- SETTERS -----------------------------    

  public set entityService(value: EntityService) { this._entityService = value; }

  public ngOnInit(): Promise<any> | any { }

  public ionViewWillEnter() {

    if (this.loadingData == false) {

      this.initList();
    }
  }

  public async initList() {

    try {

      this.loadingData = true;

      super.recoverNavigationParams();

      // Configurar el UIModel
      await this.configureUIModel();

      // Configurar ServiceData
      this.configureServiceData(this.uiModel);

      // Configurar la Tabla
      const config = this.configureTableDefinition(this.uiModel);

      // Configurar las Columnas a mostrar
      this.configureDisplayedColumns(config);

      // Se hace el llamado al Count y Get al mismo tiempo.
      const result = await Promise.all([
        this.configurePagination(this.serviceData),
        this.getData(),
      ]);

      // Se asignan los datos recuperados.
      this.data = result[1];

    } catch (error) { this.logError(error); }
    finally { this.loadingData = false; }
  }

  /**
   * Configure the UIModel from Local, or Server in case UIModel does not exists.
   */
  public async configureUIModel() {

    // Se verifica si existe localmente el Model
    this.uiModel = await this.entityService.getLocalModel(this.entitySetName);

    // No existe UIModel
    if (this.uiModel == null) {

      // Se recupera el UIModel desde el servidor
      this.uiModel = await this.entityService.getModel(this.entitySetName);

      // Se guardar el UIModel localmente
      this.entityService.setUIModel(this.uiModel);
    }
  }

  public configureTableDefinition(uiModel: UIModel): TableColumn[] {

    // Se recuperan las propiedades.
    const tableDefinition = this.entityService.getTableDefinition(uiModel);

    return tableDefinition;
  }
}
