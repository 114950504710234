// Angular Imports
import { Injectable } from '@angular/core';

// Ionic Imports
import { CodePush, InstallMode, SyncOptions, DownloadProgress, SuccessCallback, SyncStatus } from '@ionic-native/code-push/ngx';

// Third Library Imports
import { Observable } from 'rxjs';

@Injectable()
export class CodePushService {

  constructor(private codePush: CodePush) {

    this.successDefault = (data) => {
      console.log('CODE PUSH SUCCESSFUL: ' + data);
    };

    this.errorDefault = (err) => {
      console.log('CODE PUSH ERROR: ' + err);
    };
  }

  public appendReleaseDescription: boolean = true;
  public descriptionPrefix: string = '\n\nChange log:\n';

  public successDefault: (value: SyncStatus) => void;

  public errorDefault: (error: any) => void;

  public downloadProgressDefault = (progress) => { console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`); };

  public async notifyApplicationReady() {
    await this.codePush.notifyApplicationReady();
  }

  public sync(syncOptions?: SyncOptions, downloadProgress?: SuccessCallback<DownloadProgress>, ): Observable<SyncStatus> {

    const opt = {
      updateDialog: {
        appendReleaseDescription: this.appendReleaseDescription,
        descriptionPrefix: this.descriptionPrefix
      },
      installMode: InstallMode.IMMEDIATE
    };

    Object.assign(opt, syncOptions);

    if (downloadProgress === undefined) {
      downloadProgress = this.downloadProgressDefault;
    }

    return this.codePush.sync(opt, downloadProgress);
  }
}
