// Angular Imports
import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, Validators, FormGroup } from '@angular/forms';
import { UIModel } from './entity/models/UIModel';
import { UIModelProperty } from './entity/models/UIModelProperty';

// SFSCommon Imports

@Injectable()
export class FormsService {

  constructor() {
  }

  // Solo se generará el Formulario de aquellos campos que coincida con "propertiesDefinition".
  public generateForm(uiModel: UIModel, uiModelProperties: UIModelProperty[], dataObject: any): FormGroup {

    // Se inicializa.
    const formGroup: { [key: string]: AbstractControl } = {};

    // Por cada Key de Item, se buscará su relación con el modelo.
    for (const modelField of uiModelProperties) {

      // Se omiten aquellos que sean de tipo Guid; se omite tambien el PrimaryKey por ser Guid, se recupera despues.
      if (modelField.TypeName !== 'Guid') {

        if (modelField.TypeName === 'Boolean') {
          formGroup[modelField.PropertyName] = new FormControl(dataObject[modelField.PropertyName] || false, []);
        } else {
          formGroup[modelField.PropertyName] = new FormControl(dataObject[modelField.PropertyName] || '', []);
        }
      }
    }

    // Generado el Form con los attributos requeridos, se busca el Primary Key.
    formGroup[uiModel.PropertyKeyName] = new FormControl(dataObject[uiModel.PropertyKeyName] || '', []);

    return new FormGroup(formGroup);
  }

  // Solo se generará el Formulario de aquellos campos que coincida con "propertiesDefinition".
  public generateBlankForm(uiModel: UIModel, uiModelProperties: UIModelProperty[]): FormGroup {

    // setup the form
    const formGroup: { [key: string]: AbstractControl } = {};

    // Por cada Key de Item, se buscará su relación con el modelo.
    for (const modelField of uiModelProperties) {

      if (modelField.TypeName === 'Boolean') {
        formGroup[modelField.PropertyName] = new FormControl(false, []);
      } else if (modelField.TypeName !== 'Guid') { // Se omiten ya que se estan utilizando los atributos de Navegación.
        formGroup[modelField.PropertyName] = new FormControl('', []);
      }
    }

    return new FormGroup(formGroup);
  }

  // TODO: 
  private mapValidators(validators) {
    const formValidators = [];

    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required') {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
      }
    }

    return formValidators;
  }
}
