// Angular Imports
import { Injectable, InjectionToken, Inject } from '@angular/core';

// Ionic Imports
import { Platform } from '@ionic/angular';

// SFSCommon Imports
import { ISystemSettings } from '../models/ISystemSettings';
import { IGoogleConfigAuth } from '../models/IGoogleConfigAuth';
import { IFacebookConfigAuth } from '../models/IFacebookConfigAuth';

export const SystemSettings = new InjectionToken<ISystemSettings>('SYSTEMSETTINGS');

export function systemCoreFactory(systemSettings: ISystemSettings, platform: Platform) {
  return new SystemCore(systemSettings, platform);
}

@Injectable()
export class SystemCore {

  private _systemSettings: ISystemSettings = null;

  constructor(@Inject(SystemSettings) systemSettings: ISystemSettings, public platform: Platform) {
    this._systemSettings = systemSettings;
  }

  public get appNameKey() {
    return this._systemSettings.AppNameKey;
  }
  public get useFirebaseAuth() {
    return this._systemSettings.useFirebaseAuth;
  }
  public get useFirebaseForLocalUser() {
    return this._systemSettings.useFirebaseForLocalUser;
  }

  public get uiSettingsMobile() {
    return this._systemSettings.uiSettingsMobile;
  }
  public get uiSettingsDesktop() {
    return this._systemSettings.uiSettingsDesktop;
  }
  public get uiSettingsTablet() {
    return this._systemSettings.uiSettingsTablet;
  }
  public get serverVersion() {
    return this._systemSettings.ServerVersion;
  }

  public get guidCompanyId() {
    return this._systemSettings.GuidCompanyId;
  }

  public get getIdCompany() {
    return this._systemSettings.GuidCompanyId;
  }

  /** Usually it is 'superadmin' */
  public get systemUser() {
    return this._systemSettings.SystemUser;
  }

  /** Usually it is superadmin's password */
  public get systemUserPassword() {
    return this._systemSettings.SystemUserPassword;
  }

  /** Usually it is superadmin's token */
  public get systemUserTokenLocal() {
    return this._systemSettings.SystemUserTokenLocal;
  }

  public get generalUrl() {
    return this._systemSettings.GeneralUrl;
  }

  public get localUrl() {
    return this._systemSettings.LocalUrl;
  }

  public get isDebug() {
    return this._systemSettings.IsDebug;
  }

  public isLandscape(): boolean {
    return this.platform.isLandscape();
  }

  public isPortrait(): boolean {
    return this.platform.isPortrait();
  }

  // -------------- PLATAFORMS --------------

  public isAndroid(): boolean {
    return this.platform.is('android');
  }

  public isCapacitor(): boolean {
    return this.platform.is('capacitor');
  }

  public isCordova(): boolean {
    return this.platform.is('cordova');
  }

  public isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  public isElectron(): boolean {
    return this.platform.is('electron');
  }

  public isHybrid(): boolean {
    return this.platform.is('hybrid');
  }

  public isIOS(): boolean {
    return this.platform.is('ios');
  }

  public isIPad(): boolean {
    return this.platform.is('ipad');
  }

  public isIPhone(): boolean {
    return this.platform.is('iphone');
  }

  public isMobile(): boolean {
    return this.platform.is('mobile');
  }

  public isPhablet(): boolean {
    return this.platform.is('phablet');
  }

  public isPWA(): boolean {
    return this.platform.is('pwa');
  }

  public isTablet(): boolean {
    return this.platform.is('tablet');
  }

  public isBrowser(): boolean {
    return !this.isHybrid();
  }

  public isSafari(): boolean {
    return navigator && navigator.userAgent ? navigator.userAgent.indexOf('Safari') > -1 : false;
  }

  // -------------- CONFIGS APIs --------------  

  public getGoogleConfigAuth(): IGoogleConfigAuth {
    return this._systemSettings.GoogleConfigAuth;
  }

  public getFacebookConfigAuth(): IFacebookConfigAuth {
    return this._systemSettings.FacebookConfigAuth;
  }

  public getGeopositionEndPointApi(): any {
    return 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + this._systemSettings.GoogleGeolocationAPIKey;
  }

  public getGeolocationEndPointApi(): any {
    return 'https://maps.googleapis.com/maps/api/geocode/json?key=' + this._systemSettings.GoogleLocationAPIKey;
  }

  public getBlobStorageURL(): any {
    return this._systemSettings.BlobStorageURL;
  }

  public getBuildVersion() {
    return this._systemSettings.BuildVersion;
  }
}
