//import { FieldSettings } from 'src/app/pages/common/app-form-base/app-form-base.page';
import { ModalPage } from '../modal/modal.page';
import { BasePage, DataService } from 'sfscommon';
import { OnInit, Injector, Component, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { bizAppService } from '../../services/business.service';
import { PageService } from '../../services/page.service';
import { DialogSettings, DialogButton} from '../../models/common/page.model';
import { confirmSettings } from '../app-list-base/app-list-base.page';
import { DomSanitizer } from '@angular/platform-browser';
import { GeoDataService } from '../../services/geodata.service';
import { Platform } from '@ionic/angular';
@Component({
  
  template: ''
 
})
export abstract class AppBaseComponent extends BasePage implements OnInit {
  public async loadComponent(component:any, elementRef:any, data:any) {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);

  
    const componentRef = elementRef.createComponent(componentFactory);
    componentRef.instance["itemData"] = data.itemData;
    componentRef.instance["value"] = data.value;
    componentRef.instance["prop"] = data.prop;
    componentRef.instance["data"] = data.data;
    componentRef.instance["entityName"] = data.entityName;
    componentRef.instance["formMode"] = data.formMode;
    componentRef.instance["guidItem"] = data.guidItem;
    componentRef.instance["extraData"] = data.extraData;
    
    componentRef.instance["event"]?.subscribe((data:any) => {
      // console.log("subscription Generic Component", this.prop);
      // this.event.emit(data);
    });
  }

  private _bizAppService: bizAppService;

  public get bizAppService(): bizAppService {
    if (!this._bizAppService) {

      this._bizAppService = this.injector.get(bizAppService);
    }

    return this._bizAppService;
  }


  private _platform: Platform;

  public get platform(): Platform {
    if (!this._platform) {

      this._platform = this.injector.get(Platform);
    }

    return this._platform;
  }

  private _pageService: PageService;

  public get pageService(): PageService {
    if (!this._pageService) {

      this._pageService = this.injector.get(PageService);
    }

    return this._pageService;
  }

  private _geoService: GeoDataService;

  public get geoService(): GeoDataService {
    if (!this._geoService) {

      this._geoService = this.injector.get(GeoDataService);
    }

    return this._geoService;
  }

  private _componentFactoryResolver: ComponentFactoryResolver;

  public get componentFactoryResolver(): ComponentFactoryResolver {
    if (!this._componentFactoryResolver) {

      this._componentFactoryResolver = this.injector.get(ComponentFactoryResolver);
    }

    return this._componentFactoryResolver;
  }

  private _changeDetectorRefr: ChangeDetectorRef;

  public get changeDetectorRefr(): ChangeDetectorRef {
    if (!this._changeDetectorRefr) {

      this._changeDetectorRefr = this.injector.get(ChangeDetectorRef);
    }

    return this._changeDetectorRefr;
  }

  private _sanitizer: DomSanitizer;

  public get sanitizer(): DomSanitizer {
    if (!this._sanitizer) {

      this._sanitizer = this.injector.get(DomSanitizer);
    }

    return this._sanitizer;
  }

// private componentFactoryResolver: ComponentFactoryResolver

  public set bizAppService(value: bizAppService) { this._bizAppService = value; }

  constructor(public injector: Injector) {
    super(injector);

    if (this.currentMediaQuery == "sm" || this.currentMediaQuery == "xs" ){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
    this.onMediaQueryChange = (currentMediaQuery, newActiveMediaQuery)=>{
      if (newActiveMediaQuery == "sm" || newActiveMediaQuery == "xs" ){
        this.isMobile = true;
      }else{
        this.isMobile = false;
      }
    } 
  }
 

  async showDialog(settings?: DialogSettings) {
    let message = settings.Message;
    let title = settings.Title;
    let buttons = settings.Buttons;
    let typeClass = settings.Type;//== ""

    if ((message != null && message.indexOf("<") != -1) || settings.Type == "form") {

      let typeIcon = "check_circle_outline";
      if (settings.Type == "warning") {
        typeIcon = "error_outline";


      } else if (settings.Type == "form") {

      }
      let htmlIcon = null;
      let layout = null;
      if (settings.Type != "form") {
        htmlIcon = `<i class="material-icons">${typeIcon}</i>`;
        layout = ` <div class="dialog-header">   <div class="dialog-icon">${htmlIcon}</div> <div class="dialog-title">${title}</div> </div> <div class="dialog-message">${message}</div>`;

      }

      if (settings.Buttons == null) {
        let button: DialogButton = {
          Text: "Continuar",
          ActionKey: "continue",
          CssClass: "sfs-button primary modal-continuar-button",
          Click: (modalPage, button) => {
            modalPage["modalCtrl"]["dismiss"](button);

            console.log("click continuar d43", button);
            if (settings.OnClose != null) {
              settings.OnClose(null, this);
            }
          }
        };

        settings.Buttons = [];
        settings.Buttons.push(button);
      }else{

      }
      
      let modal = await this.modalCtrl.create({ component: ModalPage, componentProps: { title: settings.Title, contentHtml: layout, buttons: settings.Buttons, fields: settings.Fields, primaryButtonText:settings.PrimaryButtonText, hideCancel: settings.HideCancel, item: settings.Data, dialogType: settings.Type }, cssClass: 'swal-ok-modal custom-modal ' + settings.CssClass });
      modal.onDidDismiss().then((data) => {
        if (settings.OnClose != null && data != null) {
          settings.OnClose(data.data, this);
        }
        return data;
      });
      await modal.present();




    } else {
      console.log("No HTML", settings.Buttons);
   //   let ress = await this.showOk("ok");
      
    let ionButtons = [];
    if (settings.Buttons != null){
      settings.Buttons.forEach(element => {
        let ionButton =  {
          text: element.Text,
          role: element.ActionKey,
          cssClass: element.CssClass,
          handler: element.Click
        };
        ionButtons.push(ionButton);
      });
    }else{
      if (settings.PrimaryButtonText == null){
        settings.PrimaryButtonText = "Si, confirmar";
      }
      if(settings.HideCancel == false){
        ionButtons.push({ text: "Cancelar", role:"cancel", cssClass:"secondary", handler: (e)=> {  }});
      }
     
      ionButtons.push({ text: settings.PrimaryButtonText, role:"ok", cssClass:"primary", handler: (e)=> { return true }});
    }
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: `${title}`,
      message: `${message}`,
      buttons: ionButtons
    });
  
     return alert.present();
    
    }
  }
  public async showOk(title?: string, message?: string, buttons?: Array<any>) {
    return await this.showConfirm({ text:message });
  }
  public async showWarning(title?: string, message?: string, buttons?: Array<any>) {
    return await this.showDialog({ Message: message, Title: title, Buttons: buttons, Type: "warning" });
  }
  public  async showDeleteConfirm(settings:confirmSettings){
      

    const alert = await this.alertCtrl.create({
    cssClass: 'my-custom-class',
    header: 'Confirmar',
    message: settings.text != null ?settings.text:`¿ Desea eliminar el registro actual?`,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'Sí, eliminar',
        cssClass: 'primary',
        handler: () => {
         settings.onOk();
        }
      }
    ]
  });

   alert.present();
  
   
   //return true;
}





  public  async showConfirm(settings:confirmSettings){
      

    const alert = await this.alertCtrl.create({
    cssClass: 'my-custom-class',
    header: 'Confirmar',
    message: settings.text,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'Sí, confirmar',
        handler: () => {
         settings.onOk();
        }
      }
    ]
  });

   alert.present();
  
   //return true;
}
  isMobile:boolean = true;
  ngOnInit(): void {
   
  }
 

  

}
