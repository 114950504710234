<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{ title }}
    </ion-title>
  </ion-toolbar>
  <ion-progress-bar type="indeterminate" *ngIf="loadingData == true"></ion-progress-bar>
</ion-header>

<ion-content no-padding>

  <!-- <ion-row align-items-end justify-content-end>
    <ion-col align-self-end>
      <ion-button>
        Agregar
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-lg="5" size-md="6">
      <ion-item>
        <ion-label>Buscar</ion-label>
        <ion-input></ion-input>
      </ion-item>
    </ion-col>
  </ion-row> -->

  <!-- Desktop List -->
  <sfs-table *ngIf="(currentMediaQuery =='lg' || currentMediaQuery =='xl') && isSelectable == false" [data]="data" (onSortChange)="handleSortChange($event)"
    [columns]="tableColumns" [pagination]="pagination" (onCellClick)="handleCellClicked($event)"
    (onRowClick)="handleRowClicked($event)" (onPageChange)="handlePageChange($event)">
  </sfs-table>

  <!-- Mobile List - Como "detalle" -->
  <ng-container
    *ngIf="(currentMediaQuery =='xs' || currentMediaQuery =='sm' || currentMediaQuery =='md') && isSelectable == false">

    <ion-card *ngFor="let item of data; let odd = odd;let index = index;">
      <ion-item mode="md" lines="none" class="remove-padding" *ngFor="let tableColumn of tableColumns">
        <ion-label>
          <p>{{ tableColumn.name != null ? tableColumn.name : tableColumn.prop }}</p>
          <h2>{{ item[tableColumn.prop] }}</h2>
        </ion-label>
      </ion-item>
    </ion-card>

    <!-- Infinite Scroll -->
    <ion-infinite-scroll #infiniteScroll (ionInfinite)="handleInfiniteScroll(infiniteScroll, $event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>

  <!-- Mobile List -->
  <ng-container *ngIf="isSelectable == true">

    <ion-list *ngIf="isMultiple == false">
      <ion-radio-group (ionChange)="handleSingleItemSelected($event)">
        <ion-item *ngFor="let item of data">
          <ion-label>{{ item[propertyDefaultField] }}</ion-label>
          <ion-radio [value]="item"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <ion-list *ngIf="isMultiple == true">
      <ion-item *ngFor="let item of data">
        <ion-label>{{ item[propertyDefaultField] }}</ion-label>
        <ion-checkbox (ionChange)="handleMultipleItemsSelected($event, item)"></ion-checkbox>
      </ion-item>
    </ion-list>

    <!-- Infinite Scroll -->
    <ion-infinite-scroll #infiniteScroll (ionInfinite)="handleInfiniteScroll(infiniteScroll, $event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>

</ion-content>

<ion-footer no-border *ngIf="isSelectable == true && (itemSelected != null || itemsSelected.length > 0)">
  <ion-toolbar>
    <ion-buttons>
      <ion-button fill="clear" (click)="closeModal()">
        Cancelar
      </ion-button>
      <ion-button (click)="closeModal(itemSelected)">
        Aceptar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>