import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpService, DataService, EntityWrapper, ApiResponse, ServiceData, IGeoData, TableColumn } from 'sfscommon';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { ServiceDataOptions } from './sfs.service';
import { SeparatorSettings, FieldSettings, ControlTypes, FieldTypes } from '../models/common/page.model';
import { bizAppService } from './business.service';
import { table } from 'console';
import { setupTestingRouter } from '@angular/router/testing';
@Injectable({ providedIn: 'any' })
export class PageService {
  constructor(public http: HttpService, public dataService: DataService, public bizAppService: bizAppService) {
    //this.url = http.generateUrl();
  }
  temp: Array<FormlyFieldConfig> = null;
  fieldsBack: Array<FormlyFieldConfig> = null;

  private colsSum = 0;
  public isFilter: boolean = false;
  public isFilterRange: boolean = false;
  rowGroup: Array<FormlyFieldConfig> = null
  setSeparator(settings: SeparatorSettings, fields: Array<FormlyFieldConfig>) {
    if (this.fieldsBack == null) {
      this.fieldsBack = fields;
    }
    if (this.temp == null) {
      this.temp = new Array<FormlyFieldConfig>();
    }
    this.temp.push({ key: settings.Id, className: settings.ClassName, template: settings.Content });
  }

  public async fillDefaultData(settings: FieldSettings, field: FormlyFieldConfig, serviceData: ServiceDataOptions , page?: any){
    if (settings.Data == null) {

      let response = await this.bizAppService.Get(serviceData);
      if (response.isSuccess() == true) {
        if (response.data.length > 0) {
          response.data.unshift({});
        }
        settings.Data = response.data;

      }


    } else {
      // settings.Data 
      if (settings.Data != null && Array.isArray(settings.Data) && (settings.DataValue == null && settings.DataText == null)) {
        // data si hay, pero no propiedades
        field.templateOptions.options = settings.Data;
        let dataArray: Array<any> = [];
        for (let index = 0; index < settings.Data.length; index++) {
          const element = settings.Data[index];
          dataArray.push({ value: element[field.templateOptions["relation"]["DataValue"]], text: element[field.templateOptions["relation"]["DataText"]] });
        }
        //dataArray.unshift({});
        settings.Data = dataArray;
        settings.DataValue = "value";
        settings.DataText = "text";
      } else {
        // hay datos y propiedades

      }

    }
  }
  public async addRelatedData(settings: FieldSettings, field: FormlyFieldConfig, page?: any) {
    //
    let serviceData: ServiceDataOptions = { EntityModel: settings.EntityModel, Fields: `${settings.DataValue},${settings.DataText}` };
    if (settings.DataQuery != null) {
      serviceData.Query = settings.DataQuery;
    }
    if (settings.DataFields != null) {
      serviceData.Fields = settings.DataFields;
      serviceData.AllFields = true;
    }
    if (settings.DataUseMode != null) {
      serviceData.Usemode = settings.DataUseMode;
    }

    let parentsTotal:number=0;
    let parentsFinded:number=0;
    if (field.templateOptions["parents"] == null) {
      await this.fillDefaultData(settings, field, serviceData, page);
    } else {
      
      //parents
      if (field.hooks == null || field.hooks.onInit == null) {

       
        field.hooks =  {
          onInit: async field => {
            console.log("onInit hooks");
            // obtener campos con relación parent
            const parents: string = field.templateOptions["parents"];
           
            if (parents != null && parents.length > 0) {
              const relations: Array<string> = parents.split(";");
              parentsTotal = relations.length;
              relations.forEach(element => {
                const localPropertyFk = element.split(":")[0];

                let parentControl: AbstractControl = page.form.get(localPropertyFk);
                if (parentControl != null) {
                  parentsFinded +=1;
                  parentControl.valueChanges.subscribe(async value => {
                    await this.fillCascading(field, relations, element, value, page, serviceData);

                  });
                } else {
                  // puede estar escondido
                  field.form.valueChanges.subscribe(async value => {
                    if (page["subscription_" + localPropertyFk] != true) {
                      let parentControl: AbstractControl = page.form.get(localPropertyFk);
                      // console.log("suscription event " + localPropertyFk);
                      if (parentControl != null) {
                        page["subscription_" + localPropertyFk] = true;
                        parentControl.valueChanges.subscribe(async value => {

                          await this.fillCascading(field, relations, element, value, page, serviceData);

                        });
                      }else{
                       
                      }
                    }else{
                     
                    }
                  });

                
                }

              });
              this.fillCascading(field, relations, null, null, page, serviceData);

            }


           
          }
        }
      }
     
    }
   
   

    field.templateOptions.options = settings.Data;
    field.templateOptions.valueProp = settings.DataValue;
    field.templateOptions.labelProp = settings.DataText;



  }

  async fillCascading(field: FormlyFieldConfig, relations: Array<string>, element: string, parentValue: string, page: any, serviceData: ServiceData) {
    const value: string = parentValue;
    //console.log(value);
    let queryBuilder: Array<string> = [];
    relations.forEach(rel => {
      const localProp = rel.split(":")[0];
      const entityAndKey = rel.split(":")[1];
      const entityRel = entityAndKey.split(".")[0];
      // project, ActivityType
      const entityRelKey = entityAndKey.split(".")[1];
      if (rel == element) {
        if (value != null && value != "") {
          queryBuilder.push(`${entityRelKey} = "${value}"`);
        }
      } else {
        if (page.item != null && page.item[localProp] != null && page.item[localProp] != "") {
          queryBuilder.push(`${entityRelKey} = "${page.item[localProp]}"`);
        }
      }
    });

    console.log("queryBuilder", queryBuilder.join(" AND "));
    if (queryBuilder.length > 0) {
      serviceData.Query = queryBuilder.join(" AND "); //`${entityRelKey} = "${value}"`;
      let response = await this.bizAppService.Get(serviceData);
      if (response.isSuccess() == true) {
        if (response.data.length > 0) {
          response.data.unshift({});
        }
        field.templateOptions.options = response.data;
      }
    }
  }
  visibleFields: Array<string> = [];
  propertyChanges: Array<string> = [];
  propertiesForUpdate: Array<string> = [];

  private setOrderForRange(settings: FieldSettings, fieldName: string, fields: Array<FormlyFieldConfig>, page: any) {
    //this.setOrder(settings, fields, true);

    // agregar dos nuevos campos
    // start


    let field = fields.find(p => p.key == fieldName);
    if (settings.Label == null) {
      settings.Label = settings.Name;
    }
    this.setOrder({
      Content: settings.Label, Columns: 12
    }, fields, true, page);
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };
    let startField = JSON.parse(JSON.stringify(field, getCircularReplacer()));  //Object.assign({}, field);
    let startSettings: FieldSettings = JSON.parse(JSON.stringify(settings, getCircularReplacer()));//Object.assign({}, settings);
    startField.key = "__start" + startField.key;
    if (startField.templateOptions != null) {
      startField.templateOptions.label = "Desde";

    }
    this.fieldsBack.push(startField);
    //startSettings.PlaceHolder = startSettings.PlaceHolder + " (from)";
    startSettings.Columns = 6;
    startSettings.Name = startField.key;
    startSettings.Label = "Desde";
    this.setOrder(startSettings, fields, true, page);

    // end
    let endField = JSON.parse(JSON.stringify(field, getCircularReplacer()));
    let endSettings: FieldSettings = JSON.parse(JSON.stringify(settings, getCircularReplacer()));
    endField.key = "__end" + endField.key;
    if (endField.templateOptions != null) {
      endField.templateOptions.label = "Hasta";
      // endField.expressionProperties = { 'templateOptions.datepickerOptions.min': 'model.__startDueDate', };
    }
    this.fieldsBack.push(endField);
    //endSettings.PlaceHolder = endSettings.PlaceHolder + " (to)";
    endSettings.Name = endField.key;
    // endSettings.ExpressionProperties = { 'templateOptions.datepickerOptions.min': 'model.__startDueDate', };
    endSettings.Label = "Hasta";
    endSettings.Columns = 6;
    this.setOrder(endSettings, fields, true, page);
  }
  setOrderRelation() {

  }
  setOrder(settings: FieldSettings, fields: Array<FormlyFieldConfig>, preventFilterSettings?: boolean, page?: any) {

    if (page != null && page.fk != null && page.fk == settings.Name) {
      return;
    }
    if (this.isFilter == true) {
      settings.Required = false;
    }
    if (page["formMode"] == "read") {
      settings.ReadOnly = true;
      settings.Disabled = true;
    }
    let addField: boolean = true;
    if (preventFilterSettings != true) {
      if (this.isFilter == true && this.isFilterRange == true && settings.Name != null) {


        const fieldFinded = this.fieldsBack.find(p => p.key == settings.Name);
        if (fieldFinded != null) {
          if (fieldFinded.templateOptions != null) {
            fieldFinded.templateOptions.required = false;
          }


          if ((fieldFinded.templateOptions != null &&
            fieldFinded.templateOptions.type == "number"
          ) || fieldFinded.type == "datepicker"

          ) {
            addField = false;
            this.setOrderForRange(settings, <string>fieldFinded.key, this.fieldsBack, page);

          }
        }

      }
    }

    if (addField == true) {
      if (settings.Id == null) {
        settings.Id = settings.Name;
      }

      //console.log("currentMediaQuery: ", this.currentMediaQuery);

      if (window.innerWidth < 570) {
        if (this.isFilter == false || (this.isFilter == true && !settings.Name?.startsWith("__start") && !settings.Name?.startsWith("__end"))) {
          settings.Columns = 12;
        }
      }


      // verificar si termina una fila
      // si el colsSum anterior es menor a 12, se entiende que sí

      let isMultiColumn = false;
      if (this.colsSum >= 12) {
        this.colsSum = 0;
      }
      if (settings.Columns != null && settings.Columns != 12) {
        this.colsSum = this.colsSum + settings.Columns;
        // let futureCols = this.colsSum +  settings.Columns;
        // if (futureCols <= 12){

        // }
        if (this.colsSum < 12 && this.rowGroup == null) {
          this.rowGroup = new Array<FormlyFieldConfig>();
        }
      } else {
        settings.Columns = 12;
        this.colsSum = 12;
        //if (this.colsSum == 12){
        this.rowGroup = new Array<FormlyFieldConfig>();
        //}
      }

      if (this.rowGroup == null) {
        this.rowGroup = new Array<FormlyFieldConfig>();
      }
      if (this.fieldsBack == null) {
        this.fieldsBack = settings.Fields == null ? fields : settings.Fields;
      }

      if (this.temp == null) {
        this.temp = new Array<FormlyFieldConfig>();
      }



      if (settings.Name != null && settings.Content == null) {
        //propertiesForUpdate
        if (page.visibleFields == null) {
          page["visibleFields"] = [];
        }
        page.visibleFields.push(settings.Name);
        this.propertiesForUpdate.push(settings.Name);
        let finded = this.fieldsBack.find(p => p.key == settings.Name);
        if (finded != null) {
          this.applySettingsField(settings, finded, page);
          //this.temp.push(finded);
          //this.rowGroup["hideExpression"] = settings.HideExpression;
          this.rowGroup.push(finded);
        } else {
          this.fieldsBack.push({
            key: settings.Name,
            type: 'input',

            templateOptions: {

              label: settings.Label,
              placeholder: '',
              required: true

             
            }
          });
          let finded = this.fieldsBack.find(p => p.key == settings.Name);
          this.applySettingsField(settings, finded, page);
          //this.temp.push(finded);
          this.rowGroup.push(finded);
        }
      } else {
        let field: FormlyFieldConfig = { key: settings.Name, className: settings.ClassName, template: settings.Content };
        this.applySettingsField(settings, field, page);
        this.rowGroup.push(field);
      }

      if (this.colsSum == 12) {
        let classNames = "";
        if (settings.ClassNameGroup != null) {
          classNames = settings.ClassNameGroup;
        }
        this.temp.push({ fieldGroupClassName: 'row ' + classNames, fieldGroup: this.rowGroup });
        this.rowGroup = null;
      }
    }
  }
  public getFieldsMode(fields: Array<FormlyFieldConfig>, page: any) {

    if (page.formMode == "edit") {
      fields = fields.filter(p => p.templateOptions != null && p.templateOptions.isComputed != true);
    } else {

    }

    return fields;

  }

  public showForm(fields: Array<FormlyFieldConfig>, page?: any): Array<any> {
    //this.propertiesForUpdate. = this.visibleFields;

    if (this.temp == null) {
      //this.temp = this.fieldsBack;

      if (this.fieldsBack != null) {
        this.fieldsBack = this.getFieldsMode(this.fieldsBack, page);
        this.fieldsBack.forEach(element => {
          this.setOrder({ Name: <string>element.key, Columns: 12 }, fields, null, page);
        });


      }
      //page.childrenRelations
      //this.setOrderRelation()
    }

    if (this.temp != null) {
      fields = this.temp;
      fields["type"] = "flex-layout";
      fields["templateOptions"] = {
        fxLayout: 'row',
      };
    }


    return fields;
  }
  public resetFieldsForm(fields: Array<FormlyFieldConfig>): Array<any> {
    this.temp = null;
    this.colsSum = 0;
    if (this.fieldsBack != null) {
      fields = this.fieldsBack;
    }
    return fields;
  }

  public getFieldsForm(fields: Array<FormlyFieldConfig>) {
    if (this.rowGroup != null && this.temp != null) {
      this.temp.push({ fieldGroupClassName: 'row', fieldGroup: this.rowGroup });
      this.rowGroup = null;
    }
    if (this.temp != null) {
      fields = this.temp;
      fields["type"] = "flex-layout";
      fields["templateOptions"] = {
        fxLayout: 'row',
      };
    }

    return fields;
  }

  private setTemplateOptions(field: FormlyFieldConfig, settings: FieldSettings) {

    if (settings.TemplateOptions != null) {

      field["templateOptions"] = settings.TemplateOptions;

    }
    //else {

    if (field["templateOptions"] == null) {
      field["templateOptions"] = {};
    }
    if (settings.Label != null) {
      field["templateOptions"]["label"] = settings.Label;
    }
    if (settings.Mask != null) {
      field["templateOptions"]["mask"] = settings.Mask;
    }
    if (settings.Type != null) {
      if (settings.Type == FieldTypes.Password) {
        field["templateOptions"]["type"] = "password";
      } else if (settings.Type == FieldTypes.Time) {
        field["templateOptions"]["type"] = "time";
      } else if (settings.Type == FieldTypes.Number) {
        field["templateOptions"]["type"] = "number";

      } else if (settings.Type == FieldTypes.Currency) {
        field["templateOptions"]["mask"] = "money";

      }

    } else {
      if (field["templateOptions"]["type"] == null )
        field["templateOptions"]["type"] = "text";
    }

    if (field["templateOptions"]["type"] == "number") {
      if (settings.Min != null) {
        field["templateOptions"]["min"] = settings.Min;
      }
      if (settings.Max != null) {
        field["templateOptions"]["max"] = settings.Max;
      }

    }

    if (field.type == "datepicker") {
      field["templateOptions"]["type"] = "text";
    }
    if (settings.OnChange != null) {
      field["templateOptions"]["change"] = settings.OnChange;
    }
    if (settings.OnKeyUp != null) {
      field["templateOptions"]["keyup"] = settings.OnKeyUp;
    }
    if (settings.LabelHtml != null) {
      field["templateOptions"]["labelHtml"] = [settings.LabelHtml];
    }
    if (settings.ReadOnly != null)
      field["templateOptions"]["readonly"] = settings.ReadOnly;
    if (settings.Disabled != null)
      field["templateOptions"]["disabled"] = settings.Disabled;

    if (settings.MinLength != null) {
      field["templateOptions"]["minLength"] = settings.MinLength;
    }
    //}
    if (settings.IsMultiLine == true) {
      field.type = "textarea";
    }
    if (settings.PlaceHolder != null) {
      field.templateOptions.placeholder = settings.PlaceHolder;

    }
    if (settings.Required != null && this.isFilter == false) {
      field.templateOptions.required = settings.Required;
    }

    if (settings.ReadOnly == true && field.type == "datepicker") {
      field.type = "input";
    }
  }
  private applySettingsField(settings: FieldSettings, field: FormlyFieldConfig, page: any) {
    settings.Page = page;
    settings.Model = page.item;
    field["fieldSettings"] = settings;
    if (settings.Columns != null) {
      field.className = field.className + " col-" + settings.Columns;
    }
    field.className = field.className + " " + field.key;
    if (settings.ClassName != null) {
      field.className = field.className + " " + settings.ClassName;
    }
    if (settings.ReadOnly == true) {
      field.className = field.className + " readonly";
    }

    if (settings.ControlType == ControlTypes.Custom) {
      field.type = settings.CustomControl;
      this.setTemplateOptions(field, settings);

      if (settings.FileSettings?.EntitySetName != null) {
        field.templateOptions["EntitySet"] = settings.FileSettings?.EntitySetName;

      }
      if (settings.FileSettings?.PrepareUploadAction != null) {
        field.templateOptions["ActionPrepareUpload"] = settings.FileSettings?.PrepareUploadAction;

      }
      if (settings.FileSettings?.IdEntityFkName != null) {
        field.templateOptions["IdEntityFkName"] = settings.FileSettings?.IdEntityFkName;

      }
      //EntityFileName
      if (settings.FileSettings?.IdEntityFkName != null) {
        field.templateOptions["EntityFileName"] = settings.FileSettings?.EntityFileName;

      }
      if (settings.Validators != null) {
        field["validators"] = settings.Validators;
      }

    } else {

      if (settings.ControlType != null) {
        if (settings.ControlType == ControlTypes.Checkbox) {
          field.type = "checkbox";
        } else {
          field.type = settings.ControlType.toString();

          //console.log("number xsz" , field.type);
        }

      }

      this.setTemplateOptions(field, settings);



      if (settings.ExpressionProperties != null) {
        field["expressionProperties"] = settings.ExpressionProperties;
      }
      if (settings.HideExpression != null) {
        field["hideExpression"] = settings.HideExpression;
      }


      if (settings.Validators != null) {
        field["validators"] = settings.Validators;
      }
      if (settings.Hooks != null) {
        field["hooks"] = settings.Hooks;
      } else {
        //  console.log("hooks", field["hooks"]);
      }
      if (settings.AsyncValidators != null) {
        field["asyncValidators"] = settings.AsyncValidators;
      }
      if ( (settings.EntityModel != null || (settings.Data != null)) || (field.templateOptions != null && field.templateOptions["relation"] != null)  ) {
        field.type = "select";
        //   //this.addRelatedData(settings, field, page);
        // } else {
        if (field.templateOptions != null && field.templateOptions["relation"] != null) {

          settings.EntityModel = field.templateOptions["relation"].EntityModel;
          if (settings.DataValue == null) {
            settings.DataValue = field.templateOptions["relation"].DataValue;
          }
          if (settings.DataText == null) {
            settings.DataText = field.templateOptions["relation"].DataText;
          }
          //this.addRelatedData(settings, field, page);


        }

        this.addRelatedData(settings, field, page);
      }


    }

    if (settings.Wrapper != null) {
      field["wrappers"] = [settings.Wrapper];
    }
  }
  setOrderFields(fieldNames: Array<string>, fields: Array<FormlyFieldConfig>) {
    let temp: Array<FormlyFieldConfig> = [];

    fieldNames.forEach(element => {
      let finded = fields.find(p => p.name == element);

      temp.push()
    });

  }
  public getPrimaryColumn(tableColumns: Array<any>, defaultProperty?: string): any {
    let result = tableColumns[0];
    if (defaultProperty != null) {
      result = tableColumns.find(p => p.prop == defaultProperty);
    }
    if (result == null) {
      result = tableColumns.find(p => p["isDefaultProperty"] == true);
    }
    if (result == null) {
      result = tableColumns[0];
    }

    return result;
  }
  public getValueColumn(tableColumns: Array<any>, valueColumn: string): any {

    let result = null;

    result = tableColumns.find(p => p.prop == valueColumn);
    return result;
  }

  private getDateValue(date: Date, startOrEnd?: string) {
    let result: Array<string> = [];
    result.push("DateTime(");
    if (date != null && date["_isAMomentObject"] == true) {
      date = date["_d"];
    }
    result.push(date.getFullYear().toString() + ",");

    result.push((date.getMonth() + 1).toString() + ",");
    if (startOrEnd == null || startOrEnd == "start") {
      result.push(date.getDate().toString() + ",0,0,0");
    } else {
      result.push(date.getDate().toString() + ",23,59,59");
    }
    result.push(")");

    return result.join("");
  }
  public getQueryFilter(itemFilter: any, fields?: Array<FormlyFieldConfig>, withRange?: boolean) {
    let queryBuilder: Array<string> = [];

    // Asegurarse que las propiedades simples existen
    for (const prop in itemFilter) {
      if (prop.startsWith("__start") || prop.startsWith("__end")) {
        itemFilter[prop.replace("__start", "").replace("__end", "")] = null;
      }
    }

    for (const prop in itemFilter) {
      // if (itemFilter[prop] != null && itemFilter[prop] != "") {
      if (prop != "IsDeleted" &&
        (!prop.startsWith("__start") && !prop.startsWith("__end"))
      ) {
        let fieldFinded: FormlyFieldConfig = fields.find(p => p.key == prop);
        if (fieldFinded != null) {
          let fieldType: string = "";
          if (fieldFinded.type.indexOf("date") != -1) {
            fieldType = "date";
          } else if (fieldFinded.templateOptions != null && fieldFinded.templateOptions.type == "number") {
            fieldType = "number";
          } else if (fieldFinded.templateOptions != null && fieldFinded.templateOptions.relation != null) {
            fieldType = "guid";
          }

          if (withRange == true) {

            let queryRange: Array<string> = [];
            //queryBuilder.push("(");
            let existStart: boolean = false;
            // verificamos tipo

            if (itemFilter["__start" + prop] != null && itemFilter["__start" + prop] != "") {
              // start si tiene
              existStart = true;
              if (fieldType == "number") {
                queryRange.push(`${prop} >=  ${itemFilter["__start" + prop]}`);
              } else if (fieldType == "date") {
                queryRange.push(`${prop} >= ${this.getDateValue(itemFilter["__start" + prop], 'start')}`);
              } else if (fieldType == "guid" && itemFilter[prop] != null && itemFilter[prop] != "") {
                queryBuilder.push(`${prop} = "${itemFilter[prop]}"`);
              } else if (itemFilter[prop] != null && itemFilter[prop] != "") {
                queryBuilder.push(`${prop}.Contains("${itemFilter[prop]}")`);
              }
            } else {

              if (fieldType == "guid" && itemFilter[prop] != null && itemFilter[prop] != "") {
                queryBuilder.push(`${prop} = "${itemFilter[prop]}"`);
              } else if (itemFilter[prop] != null && itemFilter[prop] != "") {
                queryBuilder.push(`${prop}.Contains("${itemFilter[prop]}")`);
              }

            }


            if (itemFilter["__end" + prop] != null && itemFilter["__end" + prop] != "") {
              // start si tiene
              if (fieldType == "number") {
                queryRange.push(`${prop} <=  ${itemFilter["__end" + prop]}`);
              } else if (fieldType == "date") {
                queryRange.push(`${prop} <= ${this.getDateValue(itemFilter["__end" + prop], 'end')}`);
              }
            }
            if (queryRange.length > 0) {
              queryBuilder.push("(" + queryRange.join(" AND ") + ")");
            }
            // queryBuilder.push(")");
          } else {
            if (itemFilter[prop] != null && itemFilter[prop] != "") {
              if (fieldType == "number") {
                queryBuilder.push(`${prop} =  ${itemFilter[prop]}`);
              } else if (fieldType == "date") {
                queryBuilder.push(`${prop} =  ${this.getDateValue(itemFilter[prop], 'start')}`);
              } else if (fieldType == "guid") {
                queryBuilder.push(`${prop} = "${itemFilter[prop]}"`);
              }
              else {
                queryBuilder.push(`${prop}.Contains("${itemFilter[prop]}")`);
              }
            }
          }
        }
      } else {

      }
      //    }
    }

    return queryBuilder.join(" AND ");
  }

  public getColumnsFromFields(fields: Array<FormlyFieldConfig>): Array<any> {
    let result: Array<any> = [];

    fields.forEach(element => {
      let isFk = false;
      let prop = element.key;

      if (element.templateOptions != null && element.templateOptions.relation != null) {
        isFk = true;
        prop = `Fk${element.templateOptions.relation.PropertyRelationName}Text`;
      }
      let isDefault = false;
      if (element.templateOptions != null && element.templateOptions.isDefaultProperty == true) {
        isDefault = true;
      }
      result.push(
        {
          name: element.key,
          prop: prop,
          sortable: true,
          isFk: isFk,
          sortBy: element.key,
          isDefaultProperty: isDefault,
          headerClass: 'title-cell'
        }
      );
    });

    return result;
  }
}


