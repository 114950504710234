// Angular Imports
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// SFSCommon Imports
import { IUserDataModel } from '../../../core/models/IUserDataModel';
import { ICompany } from '../../../core/models/ICompany';

@Component({
  selector: 'sfs-user-info-menu',
  templateUrl: './user-info-menu.component.html',
  styleUrls: ['./user-info-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SFSUserInfoMenuComponent {

  @Input() public user: IUserDataModel;

  @Input() public showMode: 'card' | 'item' | 'any' = 'card';

  public company: ICompany;

  constructor() { }
}
