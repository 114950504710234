// Angular Imports
import { Injectable } from '@angular/core';

// Ionic Imports
import { Geolocation, GeolocationOptions, PositionError } from '@ionic-native/geolocation/ngx';

// SFSCommon Imports
import { HttpService } from './../../http/http.service';
import { SystemCore } from '../system.service';
import { IGoogleLocation } from './models/IGoogleLocation';
import { IAddress } from './models/IAddress';
import { IGeoData } from './models/IGeoData';
import { ICoordinates } from './models/ICoordinates';

@Injectable()
export class GeolocationService {

  private _options: GeolocationOptions;

  constructor(
    public geolocation: Geolocation, 
    public http: HttpService, 
    public system: SystemCore) {
  }

  public getPositionWeb = async (): Promise<ICoordinates> => {
    return this.tryGetPosition();
    // // Se valida si se trata del buscador de Safari.
    // const isSafari = navigator.userAgent.indexOf('Safari') > -1;

    // // Es un navegador de Safari.
    // if (isSafari) {

    //   // Se hace la llamada directamente a la API de Google.
    //   const location = await this.tryGetPosition();

    //   return location;

    // } else if (navigator.geolocation) {

    //   // TODO: Revisar los valores
    //   const positionOptions: PositionOptions = {
    //     enableHighAccuracy: true,
    //     maximumAge: 50000,
    //     timeout: 20000
    //   };

    //   // Se hace la petición de la Geolocalización.
    //   navigator.geolocation.getCurrentPosition(
    //     (success: any) => {

    //       const lat = success.coords.altitude;
    //       const lng = success.coords.longitude;

    //       const actualPosition = {
    //         lat: lat,
    //         lng: lng
    //       };

    //       return actualPosition;
    //     },
    //     async (error: PositionError) => {

    //       const location = await this.browserGeolocationFails(error);

    //       return location;

    //     }, positionOptions);
       
    // } else { // No tiene permitido el 'navigator.geolocation'.

    //   // Se hace la llamada directamente a la API de Google.
    //   const location = await this.tryGetPosition();

    //   return location;
    // }
  }

  public getPositionNative = async (): Promise<ICoordinates> => {

    const pos = await this.geolocation.getCurrentPosition({ enableHighAccuracy: false });

    const lat = pos.coords.latitude;
    const lng = pos.coords.longitude;

    const actualPosition = {
      lat: lat,
      lng: lng
    };

    return actualPosition;
  }

  public async getPosition(): Promise<ICoordinates> {

    // Es Web.
    if (this.system.isBrowser()) {

      return this.getPositionWeb();

    } else { // Es Mobile.

      return this.getPositionNative();
    }
  }

  private async browserGeolocationFails(error: PositionError): Promise<{ lat: number, lng: number }> {

     let response = null;

    // switch (error.code) {

    //   case error.TIMEOUT:
    //     console.log('Browser geolocation error - Timeout.');
    //     break;

    //   case error.PERMISSION_DENIED:

    //     // La geolocalización fue bloqueada por no estar en contexto seguro.
    //     if (error.message.indexOf('Only secure origins are allowed') === 0) {
    //       response = await this.tryGetPosition();
    //     }
    //     break;

    //   case error.POSITION_UNAVAILABLE:

    //     // Dirty hack for Safari.
    //     if (error.message.indexOf('Origin does not have permission to use Geolocation service') === 0) {
    //       response = await this.tryGetPosition();
    //     } else {
    //       console.log('Browser geolocation error - Position unavailable.');
    //     }
    //     break;
    // }

     return response;
  }

  /** Use 'googleapis/geolocation' API */
  public async tryGetPosition(): Promise<{ lat: number, lng: number }> {

    const response = await this.http.post(this.system.getGeopositionEndPointApi(), null, null);

    return response.location;
  }

  /** Use 'maps/api/geocode' API */
  public async getAddressByGeoCode(countryCode, zipCode): Promise<IGoogleLocation[]> {

    let locations: IGoogleLocation[];

    if (countryCode && zipCode) {

      const url = `${this.system.getGeolocationEndPointApi()}&address=${zipCode}&components=country:${countryCode}`;

      const response = await this.http.get(url);

      console.log('getAddressByGeoCode', response);

      if (response['status'] === 'OK') {

        locations = response['results'];
      }
    }

    return locations;
  }

  public getAddressFromGoogeLocation(location: IGoogleLocation): IAddress {

    const address: IAddress = {};

    address.formatted_address = location.formatted_address;

    const admin_1 = location.address_components.find(p => p.types.indexOf('administrative_area_level_1') >= 0);
    const admin_2 = location.address_components.find(p => p.types.indexOf('administrative_area_level_2') >= 0);
    const locality = location.address_components.find(p => p.types.indexOf('locality') >= 0);
    const sublocality = location.address_components.find(p => p.types.indexOf('sublocality') >= 0);
    const neighborhood = location.address_components.find(p => p.types.indexOf('neighborhood') >= 0);
    const route = location.address_components.find(p => p.types.indexOf('route') >= 0);
    const street_number = location.address_components.find(p => p.types.indexOf('street_number') >= 0);
    const postal_code = location.address_components.find(p => p.types.indexOf('postal_code') >= 0);
    const lat = location.geometry.location.lat;
    const lng = location.geometry.location.lng;

    if (route != null) {
      address.address = route.long_name;
      address.route_name = route.long_name;
      address.route_short = route.short_name;
    }

    if (street_number != null) {
      address.street_num = street_number.short_name;
    }

    address.admin_level_1_name = admin_1.long_name;
    address.admin_level_1_short = admin_1.short_name;

    if (admin_2 != null) {
      address.admin_level_2_name = admin_2.long_name;
      address.admin_level_2_short = admin_2.short_name;
    }

    if (locality != null) {
      address.locality_name = locality.long_name;
      address.locality_short = locality.short_name;
    }

    if (sublocality != null) {
      address.sublocality_name = sublocality.long_name;
      address.sublocality_short = sublocality.short_name;
      address.sublocality = sublocality.long_name;
    }

    if (neighborhood != null) {
      address.neighborhood_name = neighborhood.long_name;
      address.neighborhood_short = neighborhood.short_name;
    }

    if (route != null) {
      address.route_name = route.long_name;
      address.route_short = route.short_name;
    }

    if (postal_code != null) {
      address.zipCode = postal_code.short_name;
    }

    return address;
  }

  public async getContries(fromServer?:boolean) {
    let countries:Array<{name,code}>
    if (fromServer == true){
      const url = this.http.generateUrl();

    const params = await this.http.generateFullParamsRequest();
    let  result = await this.http.post(`${url}/GeoData/GetCountries`, params.data, null);

    }else{
       countries = [
        { 'name': 'Afghanistan', 'code': 'AF' },
        { 'name': 'land Islands', 'code': 'AX' },
        { 'name': 'Albania', 'code': 'AL' },
        { 'name': 'Algeria', 'code': 'DZ' },
        { 'name': 'American Samoa', 'code': 'AS' },
        { 'name': 'AndorrA', 'code': 'AD' },
        { 'name': 'Angola', 'code': 'AO' },
        { 'name': 'Anguilla', 'code': 'AI' },
        { 'name': 'Antarctica', 'code': 'AQ' },
        { 'name': 'Antigua and Barbuda', 'code': 'AG' },
        { 'name': 'Argentina', 'code': 'AR' },
        { 'name': 'Armenia', 'code': 'AM' },
        { 'name': 'Aruba', 'code': 'AW' },
        { 'name': 'Australia', 'code': 'AU' },
        { 'name': 'Austria', 'code': 'AT' },
        { 'name': 'Azerbaijan', 'code': 'AZ' },
        { 'name': 'Bahamas', 'code': 'BS' },
        { 'name': 'Bahrain', 'code': 'BH' },
        { 'name': 'Bangladesh', 'code': 'BD' },
        { 'name': 'Barbados', 'code': 'BB' },
        { 'name': 'Belarus', 'code': 'BY' },
        { 'name': 'Belgium', 'code': 'BE' },
        { 'name': 'Belize', 'code': 'BZ' },
        { 'name': 'Benin', 'code': 'BJ' },
        { 'name': 'Bermuda', 'code': 'BM' },
        { 'name': 'Bhutan', 'code': 'BT' },
        { 'name': 'Bolivia', 'code': 'BO' },
        { 'name': 'Bosnia and Herzegovina', 'code': 'BA' },
        { 'name': 'Botswana', 'code': 'BW' },
        { 'name': 'Bouvet Island', 'code': 'BV' },
        { 'name': 'Brazil', 'code': 'BR' },
        { 'name': 'British Indian Ocean Territory', 'code': 'IO' },
        { 'name': 'Brunei Darussalam', 'code': 'BN' },
        { 'name': 'Bulgaria', 'code': 'BG' },
        { 'name': 'Burkina Faso', 'code': 'BF' },
        { 'name': 'Burundi', 'code': 'BI' },
        { 'name': 'Cambodia', 'code': 'KH' },
        { 'name': 'Cameroon', 'code': 'CM' },
        { 'name': 'Canada', 'code': 'CA' },
        { 'name': 'Cape Verde', 'code': 'CV' },
        { 'name': 'Cayman Islands', 'code': 'KY' },
        { 'name': 'Central African Republic', 'code': 'CF' },
        { 'name': 'Chad', 'code': 'TD' },
        { 'name': 'Chile', 'code': 'CL' },
        { 'name': 'China', 'code': 'CN' },
        { 'name': 'Christmas Island', 'code': 'CX' },
        { 'name': 'Cocos (Keeling) Islands', 'code': 'CC' },
        { 'name': 'Colombia', 'code': 'CO' },
        { 'name': 'Comoros', 'code': 'KM' },
        { 'name': 'Congo', 'code': 'CG' },
        { 'name': 'Congo, The Democratic Republic of the', 'code': 'CD' },
        { 'name': 'Cook Islands', 'code': 'CK' },
        { 'name': 'Costa Rica', 'code': 'CR' },
        { 'name': 'Cote D"Ivoire', 'code': 'CI' },
        { 'name': 'Croatia', 'code': 'HR' },
        { 'name': 'Cuba', 'code': 'CU' },
        { 'name': 'Cyprus', 'code': 'CY' },
        { 'name': 'Czech Republic', 'code': 'CZ' },
        { 'name': 'Denmark', 'code': 'DK' },
        { 'name': 'Djibouti', 'code': 'DJ' },
        { 'name': 'Dominica', 'code': 'DM' },
        { 'name': 'Dominican Republic', 'code': 'DO' },
        { 'name': 'Ecuador', 'code': 'EC' },
        { 'name': 'Egypt', 'code': 'EG' },
        { 'name': 'El Salvador', 'code': 'SV' },
        { 'name': 'Equatorial Guinea', 'code': 'GQ' },
        { 'name': 'Eritrea', 'code': 'ER' },
        { 'name': 'Estonia', 'code': 'EE' },
        { 'name': 'Ethiopia', 'code': 'ET' },
        { 'name': 'Falkland Islands (Malvinas)', 'code': 'FK' },
        { 'name': 'Faroe Islands', 'code': 'FO' },
        { 'name': 'Fiji', 'code': 'FJ' },
        { 'name': 'Finland', 'code': 'FI' },
        { 'name': 'France', 'code': 'FR' },
        { 'name': 'French Guiana', 'code': 'GF' },
        { 'name': 'French Polynesia', 'code': 'PF' },
        { 'name': 'French Southern Territories', 'code': 'TF' },
        { 'name': 'Gabon', 'code': 'GA' },
        { 'name': 'Gambia', 'code': 'GM' },
        { 'name': 'Georgia', 'code': 'GE' },
        { 'name': 'Germany', 'code': 'DE' },
        { 'name': 'Ghana', 'code': 'GH' },
        { 'name': 'Gibraltar', 'code': 'GI' },
        { 'name': 'Greece', 'code': 'GR' },
        { 'name': 'Greenland', 'code': 'GL' },
        { 'name': 'Grenada', 'code': 'GD' },
        { 'name': 'Guadeloupe', 'code': 'GP' },
        { 'name': 'Guam', 'code': 'GU' },
        { 'name': 'Guatemala', 'code': 'GT' },
        { 'name': 'Guernsey', 'code': 'GG' },
        { 'name': 'Guinea', 'code': 'GN' },
        { 'name': 'Guinea-Bissau', 'code': 'GW' },
        { 'name': 'Guyana', 'code': 'GY' },
        { 'name': 'Haiti', 'code': 'HT' },
        { 'name': 'Heard Island and Mcdonald Islands', 'code': 'HM' },
        { 'name': 'Holy See (Vatican City State)', 'code': 'VA' },
        { 'name': 'Honduras', 'code': 'HN' },
        { 'name': 'Hong Kong', 'code': 'HK' },
        { 'name': 'Hungary', 'code': 'HU' },
        { 'name': 'Iceland', 'code': 'IS' },
        { 'name': 'India', 'code': 'IN' },
        { 'name': 'Indonesia', 'code': 'ID' },
        { 'name': 'Iran, Islamic Republic Of', 'code': 'IR' },
        { 'name': 'Iraq', 'code': 'IQ' },
        { 'name': 'Ireland', 'code': 'IE' },
        { 'name': 'Isle of Man', 'code': 'IM' },
        { 'name': 'Israel', 'code': 'IL' },
        { 'name': 'Italy', 'code': 'IT' },
        { 'name': 'Jamaica', 'code': 'JM' },
        { 'name': 'Japan', 'code': 'JP' },
        { 'name': 'Jersey', 'code': 'JE' },
        { 'name': 'Jordan', 'code': 'JO' },
        { 'name': 'Kazakhstan', 'code': 'KZ' },
        { 'name': 'Kenya', 'code': 'KE' },
        { 'name': 'Kiribati', 'code': 'KI' },
        { 'name': 'Korea, Democratic People"S Republic of', 'code': 'KP' },
        { 'name': 'Korea, Republic of', 'code': 'KR' },
        { 'name': 'Kuwait', 'code': 'KW' },
        { 'name': 'Kyrgyzstan', 'code': 'KG' },
        { 'name': 'Lao People"S Democratic Republic', 'code': 'LA' },
        { 'name': 'Latvia', 'code': 'LV' },
        { 'name': 'Lebanon', 'code': 'LB' },
        { 'name': 'Lesotho', 'code': 'LS' },
        { 'name': 'Liberia', 'code': 'LR' },
        { 'name': 'Libyan Arab Jamahiriya', 'code': 'LY' },
        { 'name': 'Liechtenstein', 'code': 'LI' },
        { 'name': 'Lithuania', 'code': 'LT' },
        { 'name': 'Luxembourg', 'code': 'LU' },
        { 'name': 'Macao', 'code': 'MO' },
        { 'name': 'Macedonia, The Former Yugoslav Republic of', 'code': 'MK' },
        { 'name': 'Madagascar', 'code': 'MG' },
        { 'name': 'Malawi', 'code': 'MW' },
        { 'name': 'Malaysia', 'code': 'MY' },
        { 'name': 'Maldives', 'code': 'MV' },
        { 'name': 'Mali', 'code': 'ML' },
        { 'name': 'Malta', 'code': 'MT' },
        { 'name': 'Marshall Islands', 'code': 'MH' },
        { 'name': 'Martinique', 'code': 'MQ' },
        { 'name': 'Mauritania', 'code': 'MR' },
        { 'name': 'Mauritius', 'code': 'MU' },
        { 'name': 'Mayotte', 'code': 'YT' },
        { 'name': 'Mexico', 'code': 'MX' },
        { 'name': 'Micronesia, Federated States of', 'code': 'FM' },
        { 'name': 'Moldova, Republic of', 'code': 'MD' },
        { 'name': 'Monaco', 'code': 'MC' },
        { 'name': 'Mongolia', 'code': 'MN' },
        { 'name': 'Montenegro', 'code': 'ME' },
        { 'name': 'Montserrat', 'code': 'MS' },
        { 'name': 'Morocco', 'code': 'MA' },
        { 'name': 'Mozambique', 'code': 'MZ' },
        { 'name': 'Myanmar', 'code': 'MM' },
        { 'name': 'Namibia', 'code': 'NA' },
        { 'name': 'Nauru', 'code': 'NR' },
        { 'name': 'Nepal', 'code': 'NP' },
        { 'name': 'Netherlands', 'code': 'NL' },
        { 'name': 'Netherlands Antilles', 'code': 'AN' },
        { 'name': 'New Caledonia', 'code': 'NC' },
        { 'name': 'New Zealand', 'code': 'NZ' },
        { 'name': 'Nicaragua', 'code': 'NI' },
        { 'name': 'Niger', 'code': 'NE' },
        { 'name': 'Nigeria', 'code': 'NG' },
        { 'name': 'Niue', 'code': 'NU' },
        { 'name': 'Norfolk Island', 'code': 'NF' },
        { 'name': 'Northern Mariana Islands', 'code': 'MP' },
        { 'name': 'Norway', 'code': 'NO' },
        { 'name': 'Oman', 'code': 'OM' },
        { 'name': 'Pakistan', 'code': 'PK' },
        { 'name': 'Palau', 'code': 'PW' },
        { 'name': 'Palestinian Territory, Occupied', 'code': 'PS' },
        { 'name': 'Panama', 'code': 'PA' },
        { 'name': 'Papua New Guinea', 'code': 'PG' },
        { 'name': 'Paraguay', 'code': 'PY' },
        { 'name': 'Peru', 'code': 'PE' },
        { 'name': 'Philippines', 'code': 'PH' },
        { 'name': 'Pitcairn', 'code': 'PN' },
        { 'name': 'Poland', 'code': 'PL' },
        { 'name': 'Portugal', 'code': 'PT' },
        { 'name': 'Puerto Rico', 'code': 'PR' },
        { 'name': 'Qatar', 'code': 'QA' },
        { 'name': 'Reunion', 'code': 'RE' },
        { 'name': 'Romania', 'code': 'RO' },
        { 'name': 'Russian Federation', 'code': 'RU' },
        { 'name': 'RWANDA', 'code': 'RW' },
        { 'name': 'Saint Helena', 'code': 'SH' },
        { 'name': 'Saint Kitts and Nevis', 'code': 'KN' },
        { 'name': 'Saint Lucia', 'code': 'LC' },
        { 'name': 'Saint Pierre and Miquelon', 'code': 'PM' },
        { 'name': 'Saint Vincent and the Grenadines', 'code': 'VC' },
        { 'name': 'Samoa', 'code': 'WS' },
        { 'name': 'San Marino', 'code': 'SM' },
        { 'name': 'Sao Tome and Principe', 'code': 'ST' },
        { 'name': 'Saudi Arabia', 'code': 'SA' },
        { 'name': 'Senegal', 'code': 'SN' },
        { 'name': 'Serbia', 'code': 'RS' },
        { 'name': 'Seychelles', 'code': 'SC' },
        { 'name': 'Sierra Leone', 'code': 'SL' },
        { 'name': 'Singapore', 'code': 'SG' },
        { 'name': 'Slovakia', 'code': 'SK' },
        { 'name': 'Slovenia', 'code': 'SI' },
        { 'name': 'Solomon Islands', 'code': 'SB' },
        { 'name': 'Somalia', 'code': 'SO' },
        { 'name': 'South Africa', 'code': 'ZA' },
        { 'name': 'South Georgia and the South Sandwich Islands', 'code': 'GS' },
        { 'name': 'Spain', 'code': 'ES' },
        { 'name': 'Sri Lanka', 'code': 'LK' },
        { 'name': 'Sudan', 'code': 'SD' },
        { 'name': 'Suriname', 'code': 'SR' },
        { 'name': 'Svalbard and Jan Mayen', 'code': 'SJ' },
        { 'name': 'Swaziland', 'code': 'SZ' },
        { 'name': 'Sweden', 'code': 'SE' },
        { 'name': 'Switzerland', 'code': 'CH' },
        { 'name': 'Syrian Arab Republic', 'code': 'SY' },
        { 'name': 'Taiwan, Province of China', 'code': 'TW' },
        { 'name': 'Tajikistan', 'code': 'TJ' },
        { 'name': 'Tanzania, United Republic of', 'code': 'TZ' },
        { 'name': 'Thailand', 'code': 'TH' },
        { 'name': 'Timor-Leste', 'code': 'TL' },
        { 'name': 'Togo', 'code': 'TG' },
        { 'name': 'Tokelau', 'code': 'TK' },
        { 'name': 'Tonga', 'code': 'TO' },
        { 'name': 'Trinidad and Tobago', 'code': 'TT' },
        { 'name': 'Tunisia', 'code': 'TN' },
        { 'name': 'Turkey', 'code': 'TR' },
        { 'name': 'Turkmenistan', 'code': 'TM' },
        { 'name': 'Turks and Caicos Islands', 'code': 'TC' },
        { 'name': 'Tuvalu', 'code': 'TV' },
        { 'name': 'Uganda', 'code': 'UG' },
        { 'name': 'Ukraine', 'code': 'UA' },
        { 'name': 'United Arab Emirates', 'code': 'AE' },
        { 'name': 'United Kingdom', 'code': 'GB' },
        { 'name': 'United States', 'code': 'US' },
        { 'name': 'United States Minor Outlying Islands', 'code': 'UM' },
        { 'name': 'Uruguay', 'code': 'UY' },
        { 'name': 'Uzbekistan', 'code': 'UZ' },
        { 'name': 'Vanuatu', 'code': 'VU' },
        { 'name': 'Venezuela', 'code': 'VE' },
        { 'name': 'Viet Nam', 'code': 'VN' },
        { 'name': 'Virgin Islands, British', 'code': 'VG' },
        { 'name': 'Virgin Islands, U.S.', 'code': 'VI' },
        { 'name': 'Wallis and Futuna', 'code': 'WF' },
        { 'name': 'Western Sahara', 'code': 'EH' },
        { 'name': 'Yemen', 'code': 'YE' },
        { 'name': 'Zambia', 'code': 'ZM' },
        { 'name': 'Zimbabwe', 'code': 'ZW' }
      ];
  

    }
    
    return countries;
  }

  public async getGeoDataByPostalCode(countryCode: string, zipCode: string): Promise<IGeoData> {

    let geoData: IGeoData;

    const url = this.http.generateUrl();

    const params = await this.http.generateFullParamsRequest();
   let result:any = null;
    if (this.system.serverVersion == 3){
      result = await this.http.post(`${url}/GeoData/GetByPostalCode?postalCode=${zipCode}&countryCode=${countryCode}`, params.data, null);

    }else{
      result = await this.http.post(`${url}/SFSdotNetFrameworkSecurity/GeoData/GetByPostalCode?postalCode=${zipCode}&countryCode=${countryCode}`, params.data, null);

    }
  
    if (result && result['status'] === 'success') {
      geoData = result['data'];
    } else {
      console.log(result);
    }

    return geoData;
  }
}
