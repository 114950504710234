// Angular Imports
import { Component, Output, EventEmitter, Input, ViewEncapsulation, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';

// Angular Material
import { Sort, MatSort } from '@angular/material/sort';
import { PageEvent, MatPaginator, } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

// SFSCommon Imports
import { TableColumn } from '../../../core/services/entity/models/TableColumn';
import { Pagination } from '../../../core/services/data/models/Pagination';

@Component({
  selector: 'sfs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SFSTableComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('headerRow') headerRowReference: TemplateRef<any>;

  @ViewChildren('cellRow') cellRowReferences: QueryList<any>;

  @ViewChild('genericRowCellTemplate') genericRowCellTemplate: TemplateRef<any>;
  @ViewChild('genericHeaderCellTemplate') genericHeaderCellTemplate: TemplateRef<any>;

  // ---------- TABLE ----------

  @Input()
  public data: any[];

  private _columns: Array<TableColumn> = [];

  public get columns() { return this._columns; }

  @Input()
  public set columns(columns) {

    if (columns != null) {

      if (this._displayedColumns == null) {

        const displayedColumns = [];

        for (const column of columns) { displayedColumns.push(column.prop); }

        this.displayedColumns = displayedColumns;
      }

      for (const column of columns) {
        
        if (column.cellTemplate == null && this.genericRowCellTemplate) {
        
          column.cellTemplate = this.genericRowCellTemplate;
        }
      }

      this._columns = columns;
    }
  }

  private _displayedColumns: Array<string>;

  public get displayedColumns(): Array<string> { return this._displayedColumns; }

  @Input()
  public set displayedColumns(displayedColumns) { this._displayedColumns = displayedColumns; }

  @Output()
  public onSortChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public onRowClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onCellClick: EventEmitter<any> = new EventEmitter();

  public dataSource: any[];

  public selection = new SelectionModel(true, [], true);

  // ---------- PAGINATOR ----------

  @Input()
  public paginatorTemplate: TemplateRef<any>;
  @Input()
  public actionsFooter: TemplateRef<any>;

  @Input()
  public displayPaginator: boolean = true;

  @Input()
  public displayActionsFooter: boolean = true;

  @Input()
  public pagination: Pagination;

  @Input()
  public pageSizeOptions: Array<number>;

  @Input()
  public disabledPaginator: boolean;

  @Input()
  public hidePageSize: boolean = true;

  @Input()
  public showFirstLastButtons: boolean = true;

  @Output()
  public onPageChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  clickRow(event, row) {
    this.onRowClick.emit({ event, row });
  }

  clickCell(event, property, row) {
    this.onCellClick.emit({ event, property, row });
  }

  /** 
   * Whether the number of selected elements matches the total number of rows.
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource != null ? this.dataSource.length : 0;
    return numSelected == numRows;
  }

  /** 
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?:any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }

  handleSortChange(sortEvent: Sort) {

    const total = this.paginator.length;
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const previousPageIndex = pageIndex >= 1 ? pageIndex - 1 : 0;

    // Se coloca el default
    let sortBy = sortEvent.active;

    // Se busca la columna
    const column = this.columns.find(c => c.prop == sortEvent.active);

    // Si existe y hay una definicion del SortBy, se usa esa definición
    if (column != null && column.sortBy) {
      
      sortBy = column.sortBy;
    }

    const sortDirection: 'asc' | 'desc' | '' = sortEvent.direction;

    const event = {
      pagination: {
        total: total,
        pageIndex: pageIndex,
        pageSize: pageSize,
        previousPageIndex: previousPageIndex
      },
      sortBy,
      sortDirection
    };

    this.onSortChange.emit(event);
  }

  handlePageChange(pageEvent: PageEvent) {

    const total = pageEvent.length;
    const pageIndex = pageEvent.pageIndex;
    const pageSize = pageEvent.pageSize;
    const previousPageIndex = pageIndex >= 1 ? pageIndex - 1 : 0;

    // Se coloca el default
    let sortBy = this.sort.active;

    // Se busca la columna
    const column = this.columns.find(c => c.prop == this.sort.active);

    // Si existe y hay una definicion del SortBy, se usa esa definición
    if (column != null && column.sortBy) {
      
      sortBy = column.sortBy;
    }

    const sortDirection: 'asc' | 'desc' | '' = this.sort.direction;

    const event = {
      pagination: {
        total: total,
        pageIndex: pageIndex,
        pageSize: pageSize,
        previousPageIndex: previousPageIndex,
      },
      sortBy,
      sortDirection
    };

    this.onPageChange.emit(event);
  }
  getRowClass(item: any) {
    let result = [];
  
    if (item != null && typeof item.__rowClass === 'string') {
      result.push(item.__rowClass); 
      //return column.cellClass;

    } else if (item != null && typeof item.__rowClass === 'function') {
      result.push(item.__rowClass()); 
      //return column.cellClass(row);
    }

    return result.join(" ");
  }
  getCellClass(column: TableColumn, row?) {
    let result = [];
    result.push(column.prop); 
    if (column["isDefaultProperty"] == true){
      result.push("default-prop");
    }
    if (column != null && typeof column.cellClass === 'string') {
      result.push(column.cellClass); 
      //return column.cellClass;

    } else if (column != null && typeof column.cellClass === 'function') {
      result.push(column.cellClass(row)); 
      //return column.cellClass(row);
    }

    return result.join(" ");
  }
}
