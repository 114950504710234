// Angular Imports
import { Injectable } from '@angular/core';

// SFSCommon Imports
import { HttpService } from '../../http/http.service';
import { UIModel } from './models/UIModel';
import { StorageService } from '../storage/storage.service';
import { ApiResponse } from '../../http/models/ApiResponse';
import { FormField } from './models/FormField';
import { UIModelProperty } from './models/UIModelProperty';
import { TableColumn } from './models/TableColumn';

@Injectable()
export class EntityService {

  private appNameKey: string;

  constructor(
    public httpService: HttpService,
    public storage: StorageService) {

    this.appNameKey = this.httpService.appNameKey;
  }

  /**
   * Recover UIModel from Server
   */
  public async getModel(entityName: string, appNameKey?: string): Promise<UIModel> {

    // Se generan los parametros de la petición.
    const requestParams = await this.httpService.generateFullParamsRequest(true);

    let tempAppNameKey = this.appNameKey;

    if (appNameKey != null) { tempAppNameKey = appNameKey; }

    // Se genera el URL.
    const url = `${requestParams.url}/${tempAppNameKey}/${entityName}/GetModel`;

    // Se recupera el modelo.
    const resultModel: ApiResponse<UIModel> = await this.httpService.post(url, requestParams.data, requestParams.headers);

    return resultModel != null ? resultModel.data : null;
  }

  /**
   * If exists, recover UIModel from Local
   */
  public async getLocalModel(entityName: string) : Promise<UIModel> {

    let uiModel: UIModel = null;

    const allModels: Array<UIModel> = await this.storage.get('all-uimodels');

    if (allModels && allModels.length > 0) {
      uiModel = allModels.find(p => p.EntityKey == entityName || p.EntitySetName == entityName);
    }

    return uiModel;
  }

  /**
   * Create or Update an UIModel existing
   */
  public async setUIModel(uiModel: UIModel) {

    // Se recuperan los modelos.
    let allModels: Array<UIModel> = await this.storage.get('all-uimodels');

    // Si no existen, se crean,
    if (allModels == null) { allModels = new Array<UIModel>(); }

    // Se busca el modelo a actualizar/crear.
    let uiModelFinded: UIModel = allModels.find(p => p != null && p.EntityKey == uiModel.EntityKey);

    if (uiModel.SortDirection != null) {
      uiModel.SortDirection = uiModel.SortDirection.toLowerCase();
    }

    // No existe.
    if (uiModelFinded == null) {

      // Se agrega.
      allModels.push(uiModel);

    } else {

      // Se actualiza.
      uiModelFinded = uiModel;
    }

    // Se guardan los modelos.
    await this.storage.set('all-uimodels', allModels);
  }

  /**
   * Clear all UIModels from current 'AppNameKey' Context
   */
  public async clearUIModels() {
    await this.storage.remove('all-uimodels');
  }

  /**
   * Value key pair. Property, DisplayName
   */
  public getPlainProperties(uiModel: UIModel): Array<{ property: string, displayName: string }> {

    const properties: Array<{ property: string, displayName: string }> = [];

    if (uiModel == null) { throw new Error('UIModel required for getPlainProperties in EntityService'); }

    const uiProperties = uiModel.Properties;

    for (const iuProperty of uiProperties) {

      // Se recuperan los campos
      const property: string = iuProperty.PropertyName;
      let displayName: string = iuProperty.PropertyDisplayName;

      // Se valida los valores
      if (iuProperty.PropertyDisplayName == null) { displayName = iuProperty.PropertyName; }

      // Se agrega a la lista de propiedades
      properties.push({
        property,
        displayName
      });
    }

    return properties;
  }

  public getTableColumnDefinition(uiModelProperty: UIModelProperty): TableColumn {

    if (uiModelProperty == null) { throw new Error('UIModelProperty required for getTableColumnDefinition in EntityService'); }

    const tableColumn = {
      prop: uiModelProperty.PropertyName,
      name: uiModelProperty.PropertyDisplayName
    };

    return tableColumn;
  }

  /**
  * Returns set of TableColumn of UIModel
  * 
  * Not System Properties included
  * 
  * Not Navigation Properties Many included
  */
  public getTableDefinition(uiModel: UIModel): Array<TableColumn> {

    if (uiModel == null) { throw new Error('UIModel required for getTableDefinition in EntityService'); }

    const tableDefinition: Array<TableColumn> = [];

    const uiProperties = uiModel.Properties;

    for (const uiModelProperty of uiProperties) {

      // No incluir Propiedades de sistema, solo aquella que sea 'Identifier'
      if (uiModelProperty.SystemProperty != null && uiModelProperty.SystemProperty != 'Identifier') { continue; }

      // No incluir Propiedades de navegacion a muchos
      if (uiModelProperty.IsNavigationPropertyMany == true) { continue; }
      
      const column = this.getTableColumnDefinition(uiModelProperty);

      tableDefinition.push(column);
    }

    return tableDefinition;
  }

  /**
   * Returns the standard FieldConfig of UIModelProperty
   */
  public getFormFieldDefinition(uiModelProperty: UIModelProperty) {

    const controlType: FormField = {
      uiModelPropertyTypeName: uiModelProperty.TypeName,
      label: uiModelProperty.PropertyDisplayName != null ? uiModelProperty.PropertyDisplayName : uiModelProperty.PropertyName,
      name: uiModelProperty.PropertyName,
      isNavigationProperty: uiModelProperty.IsNavigationProperty,
      isNavigationPropertyMany: uiModelProperty.IsNavigationPropertyMany,
      isComputed: uiModelProperty.IsComputed
    };

    switch (uiModelProperty.TypeName) {

      case 'Guid':
        controlType.componentName = 'input';
        controlType.inputType = 'hidden';
        break;

      case 'String':
        controlType.componentName = 'input';
        controlType.inputType = 'text';
        break;

      case 'DateTime':
        controlType.componentName = 'datetime';
        controlType.inputType = null;
        break;

      case 'Int32':
        controlType.componentName = 'input';
        controlType.inputType = 'number';
        break;

      case 'Decimal':
        controlType.componentName = 'input';
        controlType.inputType = 'number';
        break;

      case 'Boolean':
        controlType.componentName = 'checkbox';
        controlType.inputType = null;
        break;

      default:

        // Se identifica si es un ForeignKey
        if (uiModelProperty.IsNavigationProperty == true || uiModelProperty.IsNavigationPropertyMany == true) {

          controlType.componentName = 'navigation';
          controlType.inputType = 'text';
          controlType.name = uiModelProperty.PropertyNavigationKey;
          controlType.label = uiModelProperty.PropertyDisplayName != null ? uiModelProperty.PropertyDisplayName : uiModelProperty.PropertyName;
        }

        break;
    }

    return controlType;
  }

  /**
  * Returns the standard set of FieldConfig of UIModel
  * 
  * Not System Properties included
  * 
  * Not Computed Properties included
  */
  public getFormDefinition(uiModel: UIModel): Array<FormField> {

    if (uiModel == null) { throw new Error('UIModel required for getFormFieldsConfig in EntityService'); }

    const fields: Array<FormField> = [];

    const uiProperties = uiModel.Properties;

    for (const uiModelProperty of uiProperties) {

      // No incluir Propiedades de sistema, solo aquella que sea 'Identifier'
      if (uiModelProperty.SystemProperty != null && uiModelProperty.SystemProperty != 'Identifier') { continue; }

      // No incluir Propiedades computadas
      if (uiModelProperty.IsComputed == true) { continue; }

      // Se recupera la Configuracion del Campo
      const control = this.getFormFieldDefinition(uiModelProperty);

      if (control != null) {

        fields.push(control);
      }
    }

    return fields;
  }

  /**
  * Returns the blank standard set of FieldConfig of UIModel
  * 
  * Not System Properties included
  * 
  * Not Computed Properties included
  * 
  * Not Navigation Properties Many included
  */
  public getBlankFormDefinition(uiModel: UIModel): Array<FormField> {

    if (uiModel == null) { throw new Error('UIModel required for getFormFieldsConfig in EntityService'); }

    const fields: Array<FormField> = [];

    const uiProperties = uiModel.Properties;

    for (const uiModelProperty of uiProperties) {

      // No incluir Propiedades de sistema, solo aquella que sea 'Identifier'
      if (uiModelProperty.SystemProperty != null && uiModelProperty.SystemProperty != 'Identifier') { continue; }

      // No incluir Propiedades computadas
      if (uiModelProperty.IsComputed == true) { continue; }

      // No incluir Propiedades de navegacion a muchos
      if (uiModelProperty.IsNavigationPropertyMany == true) { continue; }

      const control = this.getFormFieldDefinition(uiModelProperty);

      if (control != null) {

        fields.push(control);

      }
    }

    return fields;
  }
}
