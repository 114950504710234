// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Ionic Imports
//import { HTTP } from '@ionic-native/http/ngx';
import { Http } from '@capacitor-community/http';
// SFSCommon Imports
import { ParamsRequest } from './models/ParamsRequest';
import { TOKEN_REQUEST_NAME } from '../../configs/system.variables';

import { SystemCore } from '../services/system.service';
import { RequestOptions } from './models/RequestOptions';
import { LoggingService } from './../services/logging/logging.service';
import { UserService } from './../services/user/user.service';
import { EntityWrapper } from './models/EntityWrapper';
import { ApiResponse } from './models/ApiResponse';

@Injectable()
export class HttpService {

  constructor(
    public httpClient: HttpClient, // Para peticiones Web.
    //public httpNative: HttpPlugin , // Para peticiones Nativas.
    public system: SystemCore,
    public userService: UserService, // TODO: Remover UserService
    public loggingService: LoggingService) {
  }

  public get appNameKey() {
    return this.system.appNameKey;
  }

  public generateUrl() {

    // Se recupera el URL.
    let url = this.system.generalUrl;

    // Se valida si es WEB.
    if (this.system.isBrowser() && window.location.host.indexOf('localhost') != -1) {

      url = this.system.localUrl;
    }

    // Se retorna el URL.
    return url;
  }

  public generateFullParamsRequest = async <T = any>(useCredentials: boolean = true): Promise<ParamsRequest<EntityWrapper<T>>> => {

    // Se genera el URL.
    const url = this.generateUrl();

    let headers = null;
    const data: EntityWrapper<T> = {};

    // Se recuperan los URL y GuidCompany.
    let companyId = this.system.guidCompanyId;

    if (companyId == null) {
      companyId = await this.userService.getIdCompanyFromUserData();
    }

    data.CompanyId = companyId;

    // Se van a usar credenciales.
    if (useCredentials == true) {

      // Se recuperan los HeaderOptions.
      headers = await this.getHeaderOptions();

      data.AppKey = this.system.appNameKey;
      let userData = await this.userService.getUserData();
      if (userData != null) {
        data.Username = userData.Email;

        let token = await this.userService.getUserToken();
        if (token != null) {
          data.Token = token;
        }
      }
    }

    const requestParams: ParamsRequest<EntityWrapper<T>> = {
      url: url,
      data: data,
      headers: headers
    };

    return requestParams;
  }

  public getHeaderOptions = async (): Promise<RequestOptions> => {

    // Se declara la variable Headers y se asigna el tipo de petición.
    const headers = {
      'Content-Type': 'application/json'
    };

    let token = null;
    let userData = await this.userService.getUserData();
    if (userData != null && userData.FirebaseID != null) {
      token = await this.userService.getUserToken();
      if (token) {
        headers["Authorization"] = "Bearer " + token;
        headers["IdUser"] = + userData.IdUser;
      }
    } else {
      token = await this.userService.getUserToken();
      if (token) { headers[TOKEN_REQUEST_NAME] = token; }
    }
    // Se valida que tenga valor.                    


    return {
      headers: headers
    };
  }

  // ------------------ POST -----------------

  public postWeb = async (url: string, data: any, options?: RequestOptions) => {

    if (!options) { options = {}; }

    this.loggingService.eventLog('POST Browser - Datos a enviar', url, data, options);

    const response = await this.httpClient.post(url, data, options).toPromise();

    this.loggingService.eventLog('POST Browser - Respuesta', response);

    return response;
  }

  public postNative = async (url: string, data: any, options?: RequestOptions) => {

    if (!options) { options = {}; }

    let headers: any = {};

    if (options && options.headers) {

      headers = options.headers;

      if (headers instanceof (HttpHeaders)) {

        const keys = headers.keys();

        // Existen Headers.
        if (keys) {

          // Se iteran cada Key del Header.
          for (let index = 0; index < keys.length; index++) {

            headers[keys[index]] = keys[index];
          }
        }
      }
    }

    // Set the data serializer which will be used for all future POST and PUT requests
    if (options != null && options.responseType != null) {
      // this.httpNative.
      // this.httpNative.setDataSerializer(options.responseType);

    } else {

      //this.httpNative.setDataSerializer('json');
    }

    data = JSON.parse(JSON.stringify(data));

    if (this.system.isDebug) {
      console.log('POST Native - Request', url, data, JSON.stringify(data), headers);
    }
    
    const response = await Http.request({ method: "POST", url: url, data: data,  headers: headers });

    this.loggingService.eventLog('POST Native - Respuesta', response);

    if (typeof (response.data) == "object") {
      return response.data;
    } else {
      return JSON.parse(response.data);
    }

  }

  public async post(url: string, data: any, options?: RequestOptions): Promise<any> {

    if (this.system.isBrowser()) {

      return this.postWeb(url, data, options);

    } else {

      return this.postNative(url, data, options);
    }
  }

  // ------------------ PUT -----------------

  public putWeb = async (url: string, data: any, options?: RequestOptions) => {

    if (!options) { options = {}; }

    this.loggingService.eventLog('PUT Browser - Datos a enviar', url, data, options);

    const response = await this.httpClient.put(url, data, options).toPromise();

    this.loggingService.eventLog('PUT Browser - Respuesta', response);

    return response;
  }

  public putNative = async (url: string, data: any, options?: RequestOptions): Promise<any> => {

    if (!options) { options = {}; }

    let headers: any = {};

    if (options && options.headers) {

      headers = options.headers;

      if (headers instanceof (HttpHeaders)) {

        const keys = headers.keys();

        // Existen Headers.
        if (keys) {

          // Se iteran cada Key del Header.
          for (let index = 0; index < keys.length; index++) {

            headers[keys[index]] = keys[index];
          }
        }
      }
    }

    if (options != null && options.responseType != null) {

      //this.httpNative.setDataSerializer(options.responseType);

    } else {

      //this.httpNative.setDataSerializer('json');

    }

    this.loggingService.eventLog('PUT Native - Datos a enviar', url, data, options);

    const response = await Http.request({ method: "PUT", url, data, headers });

    this.loggingService.eventLog('PUT Native- Respuesta', response);

    return JSON.parse(response.data);
  }

  public async put(url: string, data: any, options?: RequestOptions): Promise<any> {

    if (this.system.isBrowser()) {

      return this.putWeb(url, data, options);

    } else {

      return this.putNative(url, data, options);
    }
  }

  // ------------------ GET -----------------

  public getWeb = async (url: string, options?: RequestOptions) => {

    if (!options) { options = {}; }

    this.loggingService.eventLog('GET Browser - Datos a enviar', url, options);

    const response = await this.httpClient.get(url, options).toPromise();

    this.loggingService.eventLog('GET Browser - Respuesta', response);

    return response;
  }

  public getNative = async (url: string, options?: RequestOptions) => {

    if (!options) { options = {}; }

    let headers: any = {};

    if (options && options.headers) {

      headers = options.headers;

      if (headers instanceof (HttpHeaders)) {

        const keys = headers.keys();

        // Existen Headers.
        if (keys) {

          // Se iteran cada Key del Header.
          for (let index = 0; index < keys.length; index++) {

            headers[keys[index]] = keys[index];
          }
        }
      }
    }

    if (options != null && options.responseType != null) {

      //this.httpNative.setDataSerializer(options.responseType);

    } else {

      //this.httpNative.setDataSerializer('json');
    }

    this.loggingService.eventLog('GET Native - Datos a enviar', url, options);

    const response = await Http.request({ url: url, method: "GET", data: null, headers: headers });

    this.loggingService.eventLog('GET Native - Respuesta', response);

    return JSON.parse(response.data);
  }

  public async get(url: string, options?: RequestOptions): Promise<any> {

    if (this.system.isBrowser()) {

      return this.getWeb(url, options);

    } else {

      return this.getNative(url, options);
    }
  }

  public async customMethod<TIn = any, TOut = any>(params?: any, appNameKey?: string, setName?: string, actionMethod?: string): Promise<ApiResponse<TOut>> {

    const paramsRequest = await this.generateFullParamsRequest<TIn>();

    if (appNameKey == null) { appNameKey = this.system.appNameKey; }

    const apiWrapper = paramsRequest.data;

    apiWrapper.Params = params;

    const response = await this.post(`${paramsRequest.url}/${appNameKey}/${setName}/${actionMethod}`, paramsRequest.data, paramsRequest.headers);

    return Object.assign(new ApiResponse<TOut>(), response);
  }
}
