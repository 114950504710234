import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpService, DataService, EntityWrapper, ApiResponse, ServiceData } from 'sfscommon';
import { ServiceDataOptions } from './sfs.service';

@Injectable({ providedIn: 'root' })
export class bizAppService {
  url: string;
  constructor(public http: HttpService, public dataService: DataService, public angularAuth: AngularFireAuth) {
    this.url = http.generateUrl();
  }
  
  
  public async  Create(entity: any, setName: any, usemode?:string) {
    
    let result = await this.dataService.create({ EntitySet: setName, RestServiceAppKey: "PSHandyBroker", Item: entity, Usemode: usemode });
    if (result.status == "error" && result.reason == "not-authorized"){
      console.log("old token");
      let user =  await this.angularAuth.currentUser;
       let newToken = user.getIdToken(true);
       console.log("new token: " + newToken);

    }else{

    }
    return result;
  }
  public async  CreateAndReturnKey(entity: any, setName: any, usemode:any) {
    let data = { EntitySet: setName, RestServiceAppKey: "PSHandyBroker", Item: entity, ReturnType: "key", usemode: usemode };
    return this.dataService.create(data);
  }
  public async  Delete(ItemKeys: Array<string>, setName: any) {

    return this.dataService.delete({ EntitySet: setName, RestServiceAppKey: "PSHandyBroker", ItemKeys: ItemKeys });
  }
  public async  Update(entity: any, setName: any, fields?: any, usemode?:string, returnType?:string) {
    Object.entries(entity).map(item => {
      if (item[0].startsWith("Fk")){
        entity[item[0]] = null;
      }
      
    })


    return this.dataService.update({ EntitySet: setName, Usemode:usemode, ReturnType: returnType,  RestServiceAppKey: "PSHandyBroker", Item: entity, Fields: fields });
  }
  public async  GetCount(serviceData: ServiceDataOptions){


    if (serviceData.EntitySet == null) {
      serviceData.EntitySet = serviceData.EntityModel["_EntitySetName"];
    }
    let result = await  this.dataService.getCount(serviceData);
    if (result.status == "error" && result.reason == "not-authorized"){
      console.log("old token");
      let user =  await this.angularAuth.currentUser;
       let newToken = user.getIdToken(true);
       console.log("new token: " + newToken);

    }else{

    }
    return result;
  }
  public async  Get(serviceData: ServiceDataOptions) {

    if (serviceData.Fields == null) {
      serviceData.Fields = Object.getOwnPropertyNames(serviceData.EntityModel["PropertyNames"]).filter(p => !p.startsWith("Fk")).join(",");
    }
    if (serviceData.EntitySet == null) {
      serviceData.EntitySet = serviceData.EntityModel["_EntitySetName"];
    }
    
    let result = await  this.dataService.get(serviceData);
    if (result.status == "error" && result.reason == "not-authorized"){
      console.log("old token");
      let user =  await this.angularAuth.currentUser;
       let newToken = user.getIdToken(true);
       console.log("new token: " + newToken);

    }else{

    }
    return result;
  }

  checkUserComplete(){
    
  }

  public async  GetItem(id: any, setNameOrModel: any, fields?: string, usemode?:string) {
    //console.log("GetItem", setNameOrModel);
    if (fields == null && typeof(setNameOrModel) == "function" ) {

      fields = Object.getOwnPropertyNames(setNameOrModel["PropertyNames"]).filter(p => !p.startsWith("Fk")).join(",");
    }
    if(typeof(setNameOrModel) == "function"){
      setNameOrModel =setNameOrModel["_EntitySetName"];
    } 
    
    let result = await this.dataService.get({ EntitySet: setNameOrModel, AllFields: true, Fields: fields, RestServiceAppKey: "PSHandyBroker", ItemKey: id, Usemode: usemode });
    if (result.isSuccess() == true) {
      if (Array.isArray(result.data) == true && result.data.length == 1) {
        result.data = result.data[0];
      }
    }
    return result;

  }




  public async  CustomMethod(params?: any, setName?: string, actionMethod?: string) {

    const requestParams = await this.http.generateFullParamsRequest();

    requestParams.data.Params = params;

    const response = await this.http.post(`${requestParams.url}/PSHandyBroker/${setName}/${actionMethod}`, requestParams.data, requestParams.headers);

    return response;
  }


    

}