import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { GenericModalComponent } from './sfs/generic/generic-modal/generic-modal.component';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SFSCommonModule, EntityListPageModule } from 'sfscommon';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyWrapperAddons } from './sfs/components/business/components/addons-extension/addons-extension.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicGestureConfig } from './sfs/utils/IonicGestureConfig';
import { PopOverMenuComponent } from './sfs/common/pop-over-menu/pop-over-menu.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MenuProfilePhotoComponent } from './sfs/components/menu-profile-photo/menu-profile-photo.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthFBService } from './sfs/services/auth.service';
import { sfsService } from './sfs/services/sfs.service';
import { ServiceLocator } from './sfs/services/locator.setvice';
import { HTTP } from '@ionic-native/http/ngx';



@NgModule({
  declarations: [AppComponent,
    FormlyWrapperAddons,
    MenuProfilePhotoComponent,
    PopOverMenuComponent
  ],
  entryComponents: [ PopOverMenuComponent,  MenuProfilePhotoComponent],
  exports:[  ],
  imports: [

    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    HammerModule,
    MatExpansionModule,
    MatGridListModule, MatCheckboxModule,
    IonicModule.forRoot(),
   // SelectCompanyPageModule,
    SFSCommonModule.forRoot(environment.sfscommon),
    AppRoutingModule,
    EntityListPageModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {  enabled: environment.production , registrationStrategy: 'registerImmediately' }),
   
  ],
  providers: [
    HTTP,
    AuthFBService,
    sfsService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: IonicGestureConfig
        },
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector){    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
}
}
