import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sfs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class SFSLoginComponent {

  public form: FormGroup;

  @Input() urlLogo: string;
  
  @Input() middleMessage: string;

  @Input() positionLabel: 'fixed' | 'floating' | 'stacked' | undefined = 'floating';

  @Input() userLabelText: string = 'Usuario';
  @Input() iconUser: string = 'person';
  @Input() userPlaceholderText: string;
  
  @Input() passwordLabelText: string = 'Contraseña';
  @Input() iconPassword: string = 'lock';
  @Input() passwordPlaceholderText: string;

  @Input() orText: string = 'o';

  @Input() submitButtonText: string = 'Iniciar sesión';
  @Input() submitButtonDisabled: boolean = false;

  @Input() recoverTextPassword: string = 'Si olvidaste tu contraseña, la puedes recuperar';
  @Input() recoverTextClickPassword: string = 'desde aquí.';

  @Input() allowSocialLogin: boolean = true;
  @Input() allowRecoverPassword : boolean = true;

  @Output() onLoginToSFS: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoginToGoogle: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoginToFacebook: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetPassword: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onCreateAccount = new EventEmitter<any>();
  @Output() public onRecoverPassword = new EventEmitter<any>();
  @Output() public onActivateAccount = new EventEmitter<any>();

  constructor() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  public loginToSFS(formValue) {
    if (this.form.valid == true) {
      this.onLoginToSFS.emit(formValue);
    }
  }

  public loginToFacebook() {
    this.onLoginToFacebook.emit();
  }

  public loginToGoogle() {
    this.onLoginToGoogle.emit();
  }
  
  public resetPassword() {
    this.onResetPassword.emit(this.form.value);
  }
}
